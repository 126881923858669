var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { environment } from '../../environments/environment';
var SideMenuComponent = /** @class */ (function () {
    function SideMenuComponent(contractService) {
        this.contractService = contractService;
        this.onGoTo = new EventEmitter();
        this.hasAsset = false;
        this.isProduction = false;
    }
    SideMenuComponent.prototype.ngOnInit = function () {
        this.isProduction = environment.networkName != 'matic-mumbai';
        this.load();
        this.setFixedSideMenu();
    };
    SideMenuComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var assetIndexPlusOne;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contractService.init()];
                    case 1:
                        _a.sent();
                        assetIndexPlusOne = 0;
                        if (!this.contractService.address) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.contractService.buyerAssetIndexPlusOne(this.contractService.address)];
                    case 2:
                        assetIndexPlusOne = _a.sent();
                        _a.label = 3;
                    case 3:
                        this.hasAsset = assetIndexPlusOne > 0;
                        return [2 /*return*/];
                }
            });
        });
    };
    SideMenuComponent.prototype.goTo = function (key) {
        this.key = key;
        this.onGoTo.emit({
            key: key
        });
    };
    SideMenuComponent.prototype.setFixedSideMenu = function () {
        var oldHtmlWidth = document.documentElement.offsetWidth;
        var elFix = document.getElementById('fixed-sidebar');
        function htmlScroll() {
            var top = document.body.scrollTop || document.documentElement.scrollTop;
            if (elFix['data_top'] < top) {
                elFix.style.position = 'fixed';
                elFix.style.top = '0';
                elFix.style.left = elFix['data_left'];
            }
            else {
                elFix.style.position = 'static';
            }
        }
        function htmlPosition(obj) {
            var o = obj;
            var t = o.offsetTop;
            var l = o.offsetLeft;
            while (o = o.offsetParent) {
                t += o.offsetTop;
                l += o.offsetLeft;
            }
            obj.data_top = t;
            obj.data_left = l;
        }
        window.onresize = function () {
            var newHtmlWidth = document.documentElement.offsetWidth;
            if (oldHtmlWidth == newHtmlWidth) {
                return;
            }
            // @ts-ignore
            oldHtmlWidth = newHtmlWidth;
            elFix.style.position = 'static';
            htmlPosition(elFix);
            htmlScroll();
        };
        window.onscroll = htmlScroll;
        htmlPosition(elFix);
    };
    return SideMenuComponent;
}());
export { SideMenuComponent };
