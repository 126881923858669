import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-cover-status',
  templateUrl: './cover-status.component.html',
  styleUrls: ['./cover-status.component.css']
})
export class CoverStatusComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  allAssets: any = [];
  subscribeType: string = "";
  predepositBalance: string = "";
  weeksBeCovered: string = "";
  sumOfPremium: number = 0;
  assetSymbol: string = "";
  sumOfCovered: string = "";

  currentWeek: number = 0;
  userBuyerInfo: any = {};

  selectedAssetIndex: number = 0;
  seletedPremiumRate: number = 0;

  hasAsset = false;
  assetIndex = -1;
  capitalEfficiency: any;

  constructor(private contractService: ContractService, private apiService: ApiService) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    let assetIndexPlusOne = 0;
    if (this.contractService.address) {
      assetIndexPlusOne = await this.contractService.buyerAssetIndexPlusOne(this.contractService.address);
    }

    this.hasAsset = assetIndexPlusOne > 0;
    this.assetIndex = assetIndexPlusOne - 1;
    let localAllCategories = [];
    let localSumOfCovered = 0;

    const all0 = [ (async () => {
      const categories = await this.apiService.getAllCategories();
      localAllCategories = categories.map(category => {
        return {
          reserve: (category.reserve),
          apr: this.formatRate(category.apr),
        };
      });
    })(), (async () => {
      const data = await this.apiService.getAllAssets();
      this.allAssets = data.map(asset => {
        localSumOfCovered += this.getMinValue(asset.currentSubscription, asset.sellerBalance);
        return {
          isProtocolNeedMoreReserve: asset.sellerBalance < asset.currentSubscription,
          name: asset.name,
          index: asset.index,
          _premiumRate: asset.premiumRate,
          premiumRate: this.formatRate(asset.premiumRate),
          sellerBalance: this.formatBalance(asset.sellerBalance),
          currentSubscription: this.formatBalance(asset.currentSubscription),
          loading: true,
          assetSymbol: asset.symbol
        };
      });
      this.sumOfCovered = this.formatBalance(localSumOfCovered);
    })(), (async () => {
      if (this.contractService.address && this.hasAsset) {
        this.predepositBalance = await this.contractService.getPredepositBalance(
            this.contractService.address);
      };
    })(), (async () => {
      if (this.contractService.address && this.hasAsset) {
        this.currentWeek = await this.contractService.getCurrentWeek();
      }
    })(), (async () => {
      if (this.contractService.address && this.hasAsset) {
        this.userBuyerInfo = await this.contractService.getUserBuyerInfo(this.contractService.address);
      }
    })()];

    await Promise.all(all0);

    this.capitalEfficiency = ((localSumOfCovered / localAllCategories[0].reserve) * 100).toFixed(2) + '%';

    if (this.contractService.address && this.hasAsset) {
      this.sumOfPremium = 0;

      const all1 = this.allAssets.map(async (asset, index) => {
        await Promise.all([(async () => {
          const currentCoveredAmount = await this.contractService.getCurrentSubscription(
              asset.index);
          this.allAssets[index].myCurrentCoveredAmount = this.formatBalance(currentCoveredAmount);
          const myCurrentPremium = parseFloat(currentCoveredAmount) * asset._premiumRate / 1e6;
          this.allAssets[index].myCurrentPremium = this.formatBalance(myCurrentPremium);
          this.sumOfPremium += myCurrentPremium;
        })(), (async () => {
          const futureCoveredAmount = await this.contractService.getFutureSubscription(
              asset.index);
          this.allAssets[index].myFutureCoveredAmount = this.formatBalance(futureCoveredAmount);
          const myFuturePremium = parseFloat(futureCoveredAmount) * asset._premiumRate / 1e6;
          this.allAssets[index].myFuturePremium = this.formatBalance(myFuturePremium);
        })()]);
        this.allAssets[index].loading = false;
      });

      await Promise.all(all1);

      this.weeksBeCovered = this.sumOfPremium ? Math.floor(parseFloat(this.predepositBalance) / this.sumOfPremium).toString() : "0";
    }
  }

  refresh() {
    this.load();
  }

  formatBalance(value) {
    const result = '$' + (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatUSDCBalance(value) {
    const result = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatRate(value) {
    if (isNaN(value)) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  goToPortfolio() {
    this.onGoTo.emit({key: 'portfolio'});
  }

  getMinValue(a, b) {
    return Math.min(a, b)
  }

  toggleShowFloatPopup ($event, status) {
    const floatItem = $event.target.parentNode.querySelector('.float-item');
    floatItem.style.visibility = status;
  }
}
