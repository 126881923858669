/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stake.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./stake.component";
import * as i5 from "../contract.service";
import * as i6 from "../api.service";
import * as i7 from "../transactions.service";
var styles_StakeComponent = [i0.styles];
var RenderType_StakeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StakeComponent, data: {} });
export { RenderType_StakeComponent as RenderType_StakeComponent };
function View_StakeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "icon-spinner"]], null, null, null, null, null))], null, null); }
function View_StakeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "pbutton"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stake() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StakeComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (_co.willDisableButton() ? "grey" : "#002f9b")); _ck(_v, 1, 0, currVal_1); var currVal_3 = _co.isStaking(); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.willDisableButton(); _ck(_v, 0, 0, currVal_0); var currVal_2 = ((_co.getNumber(_co.amount) > _co.getNumber(_co.tidalBalance)) ? "Insufficient Fund" : "Confirm"); _ck(_v, 3, 0, currVal_2); }); }
export function View_StakeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "popup-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "popup-window"], ["style", "text-align: center; background-color: #dfffff; max-width: 380px; height: 260px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "popup-title title"], ["style", "font-size: 18px; padding-left: 32px; background-color: white; border-top-left-radius: 16px; border-top-right-radius: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Stake Tidal "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "icon fr"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(6, 0, null, null, 17, "div", [["style", "padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "deposit-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "input", [["min", "0"], ["oninput", "this.value = !!this.value && Math.abs(this.value) >= 0 ? this.value : null"], ["placeholder", ""], ["type", "number"]], [[8, "value", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.amount = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(10, 16384, null, 0, i3.ɵangular_packages_forms_forms_bd, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i3.DefaultValueAccessor, i3.ɵangular_packages_forms_forms_bd]), i1.ɵdid(12, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(14, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["href", "javascript: void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.max() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Max"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StakeComponent_1)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["style", "width: 100%; display: inline-block; margin-top: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "p", [["class", "title"], ["style", "float: left; font-size: 13px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Tidal Balance:"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "p", [["class", "number"], ["style", "float: right; font-size: 13px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", " Tidal"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.amount; _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.needApproval; _ck(_v, 18, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.amount, ""); var currVal_1 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 14).ngClassValid; var currVal_6 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.formatTokenBalance(_co.tidalBalance); _ck(_v, 23, 0, currVal_10); }); }
export function View_StakeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-stake", [], null, null, null, View_StakeComponent_0, RenderType_StakeComponent)), i1.ɵdid(1, 114688, null, 0, i4.StakeComponent, [i5.ContractService, i6.ApiService, i7.TransactionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StakeComponentNgFactory = i1.ɵccf("app-stake", i4.StakeComponent, View_StakeComponent_Host_0, { category: "category" }, { onClose: "onClose", onRefresh: "onRefresh" }, []);
export { StakeComponentNgFactory as StakeComponentNgFactory };
