<div class="popup-bg">
  <div class="popup-window" style="text-align: center; background-color: #dfffff; max-width: 380px; height: 558px;">
    <div class="popup-title title" style="font-size: 18px; padding-left: 32px; background-color: white; border-top-left-radius: 16px; border-top-right-radius: 16px;">
      Adjust - User Purchase Capacity
      <a class="icon fr" href="javascript:void(0)" (click)="close()">x</a>
    </div>
    <div style="padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff;">
      <div style="display: inline-block;">
        <div style="float: left; margin-right: 6px;"><img src="/assets/images/info.png" style="width: 20px; height: 20px; margin-top: 16px;" /></div>

        <p style="color: #4c6db9; overflow: hidden; text-align: left;">
          “User purchase capacity” is the “total covered amount” minus “Protocol Coverage Amount”
        </p>
      </div>

      <div style="width: 100%; display: inline-block;">
        <p style="color: #002f9b; margin-top: 4px; float: left; margin-bottom: 0px;">Total Covered Amount</p>
      </div>
      <div style="width: 100%; display: inline-block; margin-top: 1px;">
        <p style=" margin-top: 0px; color: #002f9b; float: left;">${{ formatTokenBalance(capacityAll) }}</p>
      </div>

      <div style="width: 100%; display: inline-block;">
        <p style="color: #002f9b; margin-top: 4px; float: left; margin-bottom: 0px;">User Purchase Capacity</p>
      </div>
      <div style="width: 100%; display: inline-block; margin-top: 1px;">
        <p style="margin-top: 0px; color: #002f9b; float: left;">${{ formatTokenBalance(getCapacity(amount)) }}</p>
      </div>

      <div style="width: 100%; display: inline-block;">
        <p style="color: #002f9b; margin-top: 8px; float: left">Protocol Coverage Amount</p>
      </div>
      <div class="deposit-input">
        <input type="number" placeholder="" [(ngModel)]="amount" value="{{amount}}" min="0" />
      </div>
      <button class="pbutton" [ngStyle]="{'background-color': willDisableButton() ? 'grey': '#002f9b'}" (click)="adjust()" [disabled]="willDisableButton()">
        Update <span class="icon-spinner" *ngIf="loading"></span>
      </button>
    </div>
  </div>
</div>
