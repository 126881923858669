import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { TransactionsService } from '../transactions.service';
import { sellerBasketUpdatingTransaction } from '../transactions.descriptions';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-sell-basket',
  templateUrl: './sell-basket.component.html',
  styleUrls: ['./sell-basket.component.css']
})
export class SellBasketComponent implements OnInit {

  @Input() category: number;
  @Input() myCurrentBalance: string;
  @Input() showAssets: any[];
  @Input() indexes: any[];
  @Input() isPending: boolean;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;

  willButtonDisable() {
    return this.transactionsService.processingTransactions[sellerBasketUpdatingTransaction.type];
  }

  constructor(private contractService: ContractService,
              public transactionsService: TransactionsService,
              ) { }

  ngOnInit() {
  }

  toggleAsset(assetIndex: number) {
    // if (this.isPending) return;

    if (this.indexes.indexOf(assetIndex) >= 0) {
      this.indexes = this.indexes.filter(index => index != assetIndex);
    } else {
      this.indexes.push(assetIndex);
    }
  }

  async update() {
    this.transactionsService.updateProcessingTransaction(sellerBasketUpdatingTransaction.type, true);
    console.log('update', this.indexes);
    try {
      await this.contractService.sellerChangeBasket(this.category, this.indexes);

      if (parseFloat(this.myCurrentBalance)) {
        this.showAlert("Change saved", "");
        this.isPending = true;
      } else {
        this.showAlert("Change saved", "");
      }

      this.onRefresh.emit();
    } catch(e) {
    }

    this.transactionsService.updateProcessingTransaction(sellerBasketUpdatingTransaction.type, false);
  }

  close() {
    this.onClose.emit();
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }
}
