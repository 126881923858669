var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { sellerClaimPremiumTransaction, sellerClaimBonusTransaction, sellerClaimMoreBonusTransaction, guarantorClaimPremiumTransaction, guarantorClaimBonusTransaction } from '../transactions.descriptions';
import { TransactionsService } from '../transactions.service';
import { environment } from '../../environments/environment';
var PortfolioComponent = /** @class */ (function () {
    function PortfolioComponent(contractService, apiService, transactionsService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.transactionsService = transactionsService;
        this.sellerInfo = [];
        this.guarantorInfo = [];
        this.moreBonusArray = [];
        this.buyerClaimingBonus = false;
        this.sellerClaimingBonus = false;
        this.sellerClaimingPremium = false;
        this.guarantorClaimingBonus = false;
        this.guarantorClaimingPremium = false;
    }
    PortfolioComponent.prototype.ngOnInit = function () {
        this.load();
    };
    PortfolioComponent.prototype.loadSellerInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.contractService.getUserSellerInfo(this.contractService.address)];
                    case 1:
                        _a.sellerInfo = _b.sent();
                        this.sellerInfo[1] = +(+this.sellerInfo[1] / (Math.pow(10, environment.usdcDecimals))).toFixed(2);
                        this.sellerInfo[2] = +(+this.sellerInfo[2] / (Math.pow(10, environment.tidalDecimals))).toFixed(2);
                        return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.loadGuarantorInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.contractService.getUserGuarantorInfo(this.contractService.address)];
                    case 1:
                        _a.guarantorInfo = _b.sent();
                        this.guarantorInfo[1] = +(+this.guarantorInfo[1] / (Math.pow(10, environment.usdcDecimals))).toFixed(2);
                        this.guarantorInfo[2] = +(+this.guarantorInfo[2] / (Math.pow(10, environment.tidalDecimals))).toFixed(2);
                        return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.loadMoreBonus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pid, info;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        this.moreBonusArray = [];
                        pid = 0;
                        _a.label = 1;
                    case 1:
                        if (!(pid < environment.moreBonusHelperPoolArray.length)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.contractService.moreBonusUserInfo(pid, this.contractService.address)];
                    case 2:
                        info = _a.sent();
                        if (!(+info[1])) {
                            return [3 /*break*/, 3];
                        }
                        this.moreBonusArray.push({
                            pid: pid,
                            symbol: environment.moreBonusHelperPoolArray[pid].symbol.toUpperCase(),
                            amount: +(+info[1] / (Math.pow(10, environment.moreBonusHelperPoolArray[pid].decimals))).toFixed(2)
                        });
                        _a.label = 3;
                    case 3:
                        ++pid;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        this.loading = true;
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.loadSellerInfo()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.loadGuarantorInfo()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.loadMoreBonus()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.refresh = function () {
        this.load();
    };
    PortfolioComponent.prototype.formatTokenBalance = function (value) {
        var result = +(+value).toFixed(2);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    PortfolioComponent.prototype.formatRate = function (value) {
        if (!value) {
            return 'N/A';
        }
        return (value / 10000).toFixed(2) + '%';
    };
    PortfolioComponent.prototype.claimGuarantorPremium = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.guarantorClaimingPremium = true;
                        this.transactionsService.updateProcessingTransaction(guarantorClaimPremiumTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.guarantorClaimPremium()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadGuarantorInfo()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.guarantorClaimingPremium = false;
                        this.transactionsService.updateProcessingTransaction(guarantorClaimPremiumTransaction.type, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.claimSellerPremium = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sellerClaimingPremium = true;
                        this.transactionsService.updateProcessingTransaction(sellerClaimPremiumTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.sellerClaimPremium()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadSellerInfo()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.sellerClaimingPremium = false;
                        this.transactionsService.updateProcessingTransaction(sellerClaimPremiumTransaction.type, false);
                        this.transactionsService.notionRefreshUsdcBalance(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.claimGuarantorBonus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.guarantorClaimingBonus = true;
                        this.transactionsService.updateProcessingTransaction(guarantorClaimBonusTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.guarantorClaimBonus()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadGuarantorInfo()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.guarantorClaimingBonus = false;
                        this.transactionsService.updateProcessingTransaction(guarantorClaimBonusTransaction.type, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.claimSellerBonus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sellerClaimingBonus = true;
                        this.transactionsService.updateProcessingTransaction(sellerClaimBonusTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.sellerClaimBonus()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadSellerInfo()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.sellerClaimingBonus = false;
                        this.transactionsService.updateProcessingTransaction(sellerClaimBonusTransaction.type, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    PortfolioComponent.prototype.claimMoreBonus = function (pid) {
        return __awaiter(this, void 0, void 0, function () {
            var moreBonus, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        moreBonus = this.moreBonusArray.filter(function (e) { return e.pid == pid; })[0];
                        if (!moreBonus)
                            return [2 /*return*/];
                        moreBonus.claiming = true;
                        this.transactionsService.updateProcessingTransaction(sellerClaimMoreBonusTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.sellerClaimMoreBonus(pid)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadMoreBonus()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        moreBonus.claiming = false;
                        this.transactionsService.updateProcessingTransaction(sellerClaimMoreBonusTransaction.type, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    return PortfolioComponent;
}());
export { PortfolioComponent };
