import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  tabIndex = 0;

  myAsset: any = {};
  subscribeType: string = "";
  predepositBalance: string = "";
  weeksBeCovered: string = "";
  sumOfPremium: number = 0;
  assetSymbol: string = "";
  retailPremiumRate: string = "";

  currentWeek: number = 0;
  userBuyerInfo: any = {};
  filteredBuyerHistory = [];
  retailHistory = [];
  filteredRetailHistory = [];
  groupedRetailHistory = [];

  capacityAll = 0;
  capacityAvailable = 0;

  willShowDeposit: boolean = false;
  willShowWithdraw: boolean = false;
  willShowSubscribe: boolean = false;
  willShowUnsubscribe: boolean = false;
  willShowAdjust: boolean = false;

  selectedAssetIndex: number = 0;
  seletedPremiumRate: number = 0;

  hasAsset = false;
  assetIndex = -1;

  approving = false;
  needApproval = true;

  willShowFileAClaim: boolean = false;

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;
  myCurrentPremium: number = 0;

  constructor(private contractService: ContractService, private apiService: ApiService) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    if (!this.contractService.address) {
      return;
    }

    const assetIndexPlusOne = await this.contractService.buyerAssetIndexPlusOne(this.contractService.address);

    this.hasAsset = assetIndexPlusOne > 0;
    this.assetIndex = assetIndexPlusOne - 1;

    if (!this.hasAsset) {
      return;
    }

    let retailHelperAssetInfo;

    const all0 = [(async () => {
      const data = await this.apiService.getAllAssets();
      this.myAsset = data.filter(asset => asset.index == this.assetIndex).map(asset => {
        return {
          name: asset.name,
          index: asset.index,
          _premiumRate: asset.premiumRate,
          premiumRate: this.formatRate(asset.premiumRate),
          sellerBalance: asset.sellerBalance,
          currentSubscription: asset.currentSubscription,
          loading: true,
          assetSymbol: asset.symbol,
          decimals: asset.decimals
        };
      })[0];
    })(), (async () => {
      this.predepositBalance = await this.contractService.getPredepositBalance(
          this.contractService.address);
    })(), (async () => {
      this.currentWeek = await this.contractService.getCurrentWeek();
    })(), (async () => {
      this.userBuyerInfo = await this.contractService.getUserBuyerInfo(this.contractService.address);
    })(), (async () => {
      this.approving = true;
      const allowance = await this.contractService.getAllowance(
          environment.usdcAddress,
          this.contractService.address,
          environment.committeeAddress,
          environment.usdcDecimals);
      this.needApproval = parseFloat(allowance) < 1e9;
      this.approving = false;
    })(), (async () => {
      let data = await this.apiService.getBuyerHistory();
      data = data.filter(r => r.who.toLowerCase() == this.contractService.address.toLowerCase());

      this.filteredBuyerHistory = data.map(r => {
        return {
          date: this.formatDate(r.blockTime),
          subscription: this.formatBalance(r.currentSubscription / (10 ** environment.usdcDecimals), 0),
          premium: this.formatBalance((r.paid) / (10 ** environment.usdcDecimals), 2),
          balance: this.formatBalance(r.balance / (10 ** environment.usdcDecimals), 0),
          refund: this.formatBalance(r.premiumToRefund / (10 ** environment.usdcDecimals), 0),
          assetBalance: this.formatBalance(r.assetBalance / (10 ** environment.usdcDecimals), 0),
        };
      });
      this.filteredBuyerHistory = this.filteredBuyerHistory.reverse();
    })(), (async () => {
      this.retailHistory = await this.apiService.getRetailHistory(this.assetIndex, 1000, 0);
    })(), (async () => {
      retailHelperAssetInfo = await this.contractService.retailHelperAssetInfo(this.assetIndex);
    })(), (async () => {
      this.retailPremiumRate = this.formatRate(await this.contractService.retailHelperPremiumRate(this.assetIndex));
    })()];

    await Promise.all(all0);

    this._processRetailHistory();

    this.sumOfPremium = 0;

    await Promise.all([(async () => {
      const currentCoveredAmount = await this.contractService.getCurrentSubscription(
          this.assetIndex);
      this.myAsset.myCurrentCoveredAmount = this.formatBalance(currentCoveredAmount, 0);
      const myCurrentPremium = parseFloat(currentCoveredAmount) * this.myAsset._premiumRate / (10 ** environment.usdcDecimals);
      this.myAsset.myCurrentPremium = myCurrentPremium;
      this.myCurrentPremium = this.myAsset.myCurrentPremium
      this.sumOfPremium += myCurrentPremium;
    })(), (async () => {
      const futureCoveredAmount = await this.contractService.getFutureSubscription(
          this.assetIndex);
      this.myAsset.myFutureCoveredAmount = this.formatBalance(futureCoveredAmount, 0);
      const myFuturePremium = parseFloat(futureCoveredAmount) * this.myAsset._premiumRate / 1e6;
      this.myAsset.myFuturePremium = myFuturePremium;
    })()]);

    this.capacityAll = Math.min(+this.myAsset.sellerBalance, +this.myAsset.currentSubscription);
    this.capacityAvailable = Math.max(0, this.capacityAll - (+retailHelperAssetInfo.futureCapacityOffset) / (10 ** environment.usdcDecimals));

    this.myAsset.loading = false;

    this.weeksBeCovered = this.myCurrentPremium ? Math.floor(parseFloat(this.predepositBalance) / this.myCurrentPremium).toString() : "0";
  }

  _processRetailHistory() {
    this.filteredRetailHistory = [];
    this.groupedRetailHistory = [];

    let sum = null;
    let week = 0;

    for (let i = 0; i < this.retailHistory.length; ++i) {
      const record = this.retailHistory[i];

      if (week == 0 || week != record.weekUpdated) {
        if (sum) {
          this.filteredRetailHistory.push(sum);
        }

        week = record.weekUpdated;
        sum = {
          date: record.blockTime,
          subscription: (record.currentBase + record.currentAsset) / (10 ** environment.usdcDecimals),
          premiumBase: record.premiumBase / (10 ** environment.usdcDecimals),
          premiumAsset: record.premiumAsset / (10 ** this.myAsset.decimals)
        };

        this.groupedRetailHistory.push([record]);
      } else {
        sum.subscription += (record.currentBase + record.currentAsset) / (10 ** environment.usdcDecimals);
        sum.premiumBase += record.premiumBase / (10 ** environment.usdcDecimals);
        sum.premiumAsset += record.premiumAsset / (10 ** this.myAsset.decimals);
        this.groupedRetailHistory[this.groupedRetailHistory.length - 1].push(record);
      }
    }

    if (sum) {
      this.filteredRetailHistory.push(sum);
    }
  }

  refresh() {
    this.load();
  }

  download(index) {
    const json = this.groupedRetailHistory[index];

    let csv = json.map(record => {
      return [
          record.who,
          this.formatDate(record.blockTime),
          '$' + this.formatTableBalance(record.currentBase / (10 ** environment.usdcDecimals), 0),
          '$' + this.formatTableBalance(record.currentAsset / (10 ** environment.usdcDecimals), 0),
          '$' + this.formatTableBalance(record.futureBase / (10 ** environment.usdcDecimals), 0),
          '$' + this.formatTableBalance(record.futureAsset / (10 ** environment.usdcDecimals), 0),
          '$' + this.formatTableBalance(record.premiumBase / (10 ** environment.usdcDecimals)),
          this.formatTableBalance(record.premiumAsset / (10 ** this.myAsset.decimals))
      ].join(",");
    }).join("\r\n");

    csv = "Address,Date,Actual covered amount (paid by USDC),Actual covered amount (paid by token)," +
        "Target covered amount (paid by USDC),Target covered amount (paid by token),Paid amount (by USDC),Paid amount (by token)\r\n" + csv;

    //this trick will generate a temp "a" tag
    var link = document.createElement("a");
    link.id = "lnkDwnldLnk";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    const blob = new Blob([csv], { type: 'text/csv' });
    var csvUrl = window['webkitURL'].createObjectURL(blob);
    var filename =  ('UserExport_' + this.myAsset.assetSymbol + '_' + index) + '.csv';
    link.setAttribute('download', filename);
    link.setAttribute('href', csvUrl);
    link.click();
    document.body.removeChild(link);
  }

  formatBalance(value, precision=2) {
    const result = '$' + (+value).toFixed(precision);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatTableBalance(value, precision=2) {
    return (+value).toFixed(precision);
  }

  formatTokenBalance(value, precision=2) {
    const result = (+value).toFixed(precision);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return  (date.getMonth() + 1) + " / " + date.getDate() + " / " + date.getFullYear();
  }

  formatRate(value) {
    if (isNaN(+value)) {
      return 'N/A';
    }

    return ((+value) / 10000).toFixed(2) + '%';
  }

  goToPortfolio() {
    this.onGoTo.emit({key: 'portfolio'});
  }

  showOverview() {
    this.tabIndex = 0;
  }

  showRetail() {
    this.tabIndex = 1;
  }

  showDeposit() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowDeposit = true;
  }

  closeDeposit() {
    this.willShowDeposit = false;
  }

  showWithdraw() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowWithdraw = true;
  }

  closeWithdraw() {
    this.willShowWithdraw = false;
  }

  showSubscribe(type: string) {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowSubscribe = true;
    this.selectedAssetIndex = this.assetIndex;
    this.seletedPremiumRate = this.myAsset._premiumRate;
    this.subscribeType = type;
    this.assetSymbol = this.myAsset.assetSymbol;
  }

  closeSubscribe() {
    this.willShowSubscribe = false;
  }

  showUnsubscribe() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowUnsubscribe = true;
    this.selectedAssetIndex = this.assetIndex;
    this.seletedPremiumRate = this.myAsset._premiumRate;
    this.assetSymbol = this.myAsset.assetSymbol;
  }

  closeUnsubscribe() {
    this.willShowUnsubscribe = false;
  }

  async approve() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.approving = true;
    try {
      await this.contractService.approve(environment.usdcAddress, environment.committeeAddress);
      this.needApproval = false;
    } catch(e) {
    }

    this.approving = false;
  }

  showFileAClaim() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowFileAClaim = true;
  }

  closeFileAClaim() {
    this.willShowFileAClaim = false;
  }

  showAdjust() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowAdjust = true;
  }

  closeAdjust() {
    this.willShowAdjust = false;
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }

  showLockedDesc(type: string) {
    if (type === 'totalCoveredAmount') {
      this.showAlert('Total Covered Amount', '“Total Covered Amount” is your current coverage amount, can be adjusted and updated in the “overview” page every week.');
    } else if (type === 'UserPurchaseCapacity') {
      this.showAlert('User Purchase Capacity', '“User Purchase Capacity” is the total coverage end users can buy, can be adjusted every week.');
    } else if (type === 'premiunFeeWeekly') {
      this.showAlert('Premium Fee', '“Premium Fee” is the current price for protocol team (paid by USDC)');
    } else if (type === 'UserPremiumFee') {
      this.showAlert('User Premium Fee', '“User Premium Fee” is the current price for end users');
    } else if (type === 'predeposit') {
      this.showAlert('My Predeposit Balance', 'Weekly coverage cost will be automatically deducted from the pre-deposit balance to save user’s gas. Please keep enough balance to maintain your coverage plan.');
    } else if (type === 'coveragePeriod') {
      this.showAlert('Estimate Coverage Period', 'Estimate coverage period is calculated based on your coverage amount and pre-deposit balance. Keep it for few weeks to have a peace of mind.');
    }
  }
}
