export const approveTransaction = {
  type: 'APPROVE',
  name: 'Approving',
  successNotificationText: '',
  errorNotificationText: '',
};

export const stakingClaimTransaction = {
  type: 'STAKING_CLAIM',
  name: 'Claiming',
  successNotificationText: '',
  errorNotificationText: '',
};

export const stakingDepositTransaction = {
  type: 'STAKING_DEPOSIT',
  name: 'Depositing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const stakingWithdrawTransaction = {
  type: 'STAKING_WITHDRAW',
  name: 'Withdrawing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const sellerDepositTransaction = {
  type: 'SELLER_DEPOSIT',
  name: 'Depositing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const sellerReduceDepositTransaction = {
  type: 'SELLER_REDUCE_DEPOSIT',
  name: 'Reducing deposit',
  successNotificationText: '',
  errorNotificationText: '',
};

export const sellerWithdrawTransaction = {
  type: 'SELLER_DEPOSIT',
  name: 'Withdrawing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const sellerBasketUpdatingTransaction = {
  type: 'SELLER_UPDATE_BASKET',
  name: 'Basket updating',
  successNotificationText: '',
  errorNotificationText: '',
};

export const sellerClaimBonusTransaction = {
  type: 'SELLER_CLAIM_BONUS',
  name: 'Claiming',
  successNotificationText: '',
  errorNotificationText: '',
};

export const sellerClaimPremiumTransaction = {
  type: 'SELLER_CLAIM_PREMIUM',
  name: 'Claiming premium',
  successNotificationText: '',
  errorNotificationText: '',
};

export const sellerClaimMoreBonusTransaction = {
  type: 'SELLER_CLAIM_MORE_BONUS',
  name: 'Claiming more',
  successNotificationText: '',
  errorNotificationText: '',
};

export const buyerClaimBonusTransaction = {
  type: 'BUYER_CLAIM_BONUS',
  name: 'Claim bonus',
  successNotificationText: '',
  errorNotificationText: '',
};

export const buyerDepositTransaction = {
  type: 'BUYER_DEPOSIT',
  name: 'Depositing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const buyerWithdrawTransaction = {
  type: 'BUYER_WITHDRAW',
  name: 'Withdrawing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const buyerSubscribeTransaction = {
  type: 'BUYER_SUBSCRIBE',
  name: 'Subscribing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const buyerUnsubscribeTransaction = {
  type: 'BUYER_UNSUBSCRIBE',
  name: 'Unsubscribing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const guarantorClaimBonusTransaction = {
  type: 'GUARANTOR_CLAIM_BONUS',
  name: 'Claiming bonus',
  successNotificationText: '',
  errorNotificationText: '',
};

export const guarantorClaimPremiumTransaction = {
  type: 'GUARANTOR_CLAIM_PREMIUM',
  name: 'Claiming premium',
  successNotificationText: '',
  errorNotificationText: '',
};

export const guarantorDepositTransaction = {
  type: 'GUARANTOR_DEPOSIT',
  name: 'Depositing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const guarantorReduceDepositTransaction = {
  type: 'GUARANTOR_REDUCE_DEPOSIT',
  name: 'Reducing deposit',
  successNotificationText: '',
  errorNotificationText: '',
};

export const guarantorWithdrawTransaction = {
  type: 'GUARANTOR_WITHDRAW',
  name: 'Withdrawing',
  successNotificationText: '',
  errorNotificationText: '',
};

export const registeringTransaction = {
  type: 'REGISTER',
  name: 'Registering',
  successNotificationText: '',
  errorNotificationText: '',
};
