import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { stakingWithdrawTransaction } from '../transactions.descriptions';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-unstake',
  templateUrl: './unstake.component.html',
  styleUrls: ['./unstake.component.css']
})
export class UnstakeComponent implements OnInit {

  @Input() category: number;
  @Input() withdrawSumAmount: number;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  amount: string = "";
  stakedBalance: number = 0;

  needApproval: boolean = true;
  loading: boolean = false;

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;

  constructor(private contractService: ContractService,
              private apiService: ApiService,
              private transactionService: TransactionsService,
              ) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    if (!this.contractService.address) {
      return;
    }

    this.loading = true;

    const all = [(async () => {
      const userInfo = await this.contractService.getStakingUserInfo(this.contractService.address);
      this.stakedBalance = +(userInfo.amount / (10 ** environment.tidalDecimals)).toFixed(2);
    }) ()];

    await Promise.all(all);

    this.loading = false;
  }

  refresh() {
    this.loadData();
  }

  async unstake() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    const amountNumber = this.getNumber(this.amount);
    if (!amountNumber) {
      return;
    }

    this.transactionService.updateProcessingTransaction(stakingWithdrawTransaction.type, true);

    try {
      await this.contractService.stakingWithdraw(amountNumber);
      this.showAlert("Thanks for unstaking", "Your request is queued now, and it will take 14 days for your TIDAL to arrive.");
      this.amount = "";
      this.loadData();
      this.onRefresh.emit();
    } catch (e) {
    }

    this.transactionService.updateProcessingTransaction(stakingWithdrawTransaction.type, false);
  }

  max() {
    this.amount = (this.stakedBalance-this.withdrawSumAmount).toString();
  }

  getNumber(x) {
    return parseFloat(x);
  }

  close() {
    this.onClose.emit();
  }

  willDisableButton() {
    const buttonDisabled = (this.getNumber(this.amount) !== 0 && !this.amount) ||
      (this.getNumber(this.amount) !== 0 && !this.getNumber(this.amount)) ||
       this.getNumber(this.amount) > this.getNumber(this.stakedBalance);
    return buttonDisabled || this.transactionService.processingTransactions[stakingWithdrawTransaction.type];
  }

  isUnstaking() {
    return this.transactionService.processingTransactions[stakingWithdrawTransaction.type];
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }
}
