import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-buy-cover',
  templateUrl: './buy-cover.component.html',
  styleUrls: ['./buy-cover.component.css']
})
export class BuyCoverComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  category = 0;

  allAssets: any[] = [];
  showAssets: any[] = [];

  retailArray = environment.retailArray;

  isChangingBasket = false;
  isShowProtocolQueue: boolean = false;
  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;

  loading: boolean= false;

  constructor(private contractService: ContractService, private apiService: ApiService) { }

  ngOnInit() {
    this.load();
  }

  formatBalance(value, precision=2) {
    const result = '$' + (+value).toFixed(precision);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatTableBalance(value, precision=2) {
    return (+value).toFixed(precision);
  }

  formatTokenBalance(value, precision=2) {
    const result = (+value).toFixed(precision);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async load() {
    this.loading = true;
    await this.contractService.init();

    const data = await this.apiService.getAllAssets();

    this.allAssets = [];
    const all = data.map(async (asset, index) => {
      this.allAssets[index] = {
        index: asset.index,
        name: asset.name,
        symbol: asset.symbol,
        token: asset.token,
        category: asset.category,
        apr: asset.apr,
        premiumRate: await this.contractService.retailHelperPremiumRate(asset.index),
        sellerBalance: this.formatBalance(asset.sellerBalance),
        sellerBalanceNumber: asset.sellerBalance,
        currentSubscription: asset.currentSubscription,
        assetUtilization: this.formatRate(asset.assetUtilization)
      };
    });

    await Promise.all(all);

    this.showAssets = this.allAssets.filter(asset => asset.category * 1 == this.category);

    this.loading = false;
  }


  formatRate(value) {
    if (isNaN(value)) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  formatSymbol(value) {
    if (!value) return '';
    return value.toUpperCase();
  }

  getProtocolLink (symbol) {
    let linkMap = {
      'FIS': 'https://stafi.tidal.finance',
      'YFI': 'https://tidal-retail-git-yfi-shipunyc.vercel.app',
      'QI': 'https://qi.tidal.finance',
      'RDL': 'https://tidal-retail-git-rdl-shipunyc.vercel.app',
      'ANC': 'https://tidal-retail-git-anc1-shipunyc.vercel.app',
      'UST': 'https://tidal-retail-git-ust-shipunyc.vercel.app',
      'LUSD': 'https://tidal-retail-git-lusd-shipunyc.vercel.app',
      'PERP': 'https://tidal-retail-git-perp-shipunyc.vercel.app',
      'SDL': 'https://tidal-retail-git-sdl-shipunyc.vercel.app',
      'FRAX': 'https://tidal-retail-git-frax-shipunyc.vercel.app',
      'GFI': 'https://tidal-retail-git-gfi-shipunyc.vercel.app',
      'MKR': 'https://tidal-retail-git-mkr-shipunyc.vercel.app',
      'COMP': 'https://tidal-retail-git-comp-shipunyc.vercel.app',
      'SNX': 'https://tidal-retail-git-snx-shipunyc.vercel.app',
      'GMX': 'https://tidal-retail-git-gmx-shipunyc.vercel.app',
      'LDO': 'https://tidal-retail-git-ldo-shipunyc.vercel.app'
    }

    return linkMap[symbol];
  }
}
