import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { stakingClaimTransaction, approveTransaction } from '../transactions.descriptions';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-staking',
  templateUrl: './staking.component.html',
  styleUrls: ['./staking.component.css']
})
export class StakingComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  poolBalance: number = 0;
  walletBalance: number = 0;
  stakedBalance: number = 0;
  earnedBalance: number = 0;
  amount: number = 0;

  withdrawRequests: any[] = [];

  needApproval: boolean = true;
  loading: boolean = false;
  claiming: boolean = false;

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;
  withdrawSumAmount: number = 0;

  apr: number = 0;

  willShowStake: boolean = false;
  willShowUnstake: boolean = false;

  constructor(
    private contractService: ContractService,
    private apiService: ApiService,
    private transactionsService: TransactionsService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  async loadData() {
    if (!this.contractService.address) {
      return;
    }

    this.loading = true;

    const all = [(async () => {
      this.walletBalance = +(await this.contractService.balanceOf(environment.tidalAddress, this.contractService.address, environment.tidalDecimals));
    }) (), (async () => {
      const userInfo = await this.contractService.getStakingUserInfo(this.contractService.address);
      this.stakedBalance = +(userInfo.amount / (10 ** environment.tidalDecimals)).toFixed(2);
    }) (), (async () => {
      const poolInfo = await this.contractService.getStakingPoolInfo();
      this.poolBalance = +(poolInfo.amount / (10 ** environment.tidalDecimals)).toFixed(2);
    }) (), (async () => {
      this.earnedBalance = +(await this.contractService.getStakingPendingReward());
    }) (), (async () => {
      const allowance = await this.contractService.getAllowance(environment.tidalAddress, this.contractService.address, environment.stakingAddress, environment.tidalDecimals);
      this.needApproval = parseFloat(allowance) < 1e6;
    }) (), (async () => {
      this.apr = +((await this.contractService.getStakingApr()) / 100).toFixed(1);
    }) (), (async () => {
      await this.loadWithdrawRequests();
    }) ()];

    await Promise.all(all);

    this.loading = false;
  }

  async loadWithdrawRequests() {
    let now, withdrawRequests;
    const all = [(async () => {
      now = await this.contractService.getNow();
    })(), (async () => {
      withdrawRequests = await this.contractService.getWithdrawRequestBackwards(this.contractService.address, 0, 10);
    })()];

    await Promise.all(all);

    this.withdrawRequests = withdrawRequests.map(request => {
      const amount = +(request.amount / (10 ** environment.tidalDecimals)).toFixed(2);
      let status;
      if (request.executed) {
        status = "Executed";
      } else {
        this.withdrawSumAmount += amount;
        status = Math.max(0, Math.floor(14.5 - (now - request.time) / (3600 * 24))) + " days left";
      }

      return {
        amount: amount,
        status: status
      };
    });
  }

  max() {
    this.amount = this.walletBalance;
  }

  formatBalance(value) {
    const result = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
  isClaimingButtonDisabled() {
    return this.transactionsService.processingTransactions[stakingClaimTransaction.type];
  }

  isApproving() {
    return this.transactionsService.processingTransactions[approveTransaction.type];
  }

  async claim() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.transactionsService.updateProcessingTransaction(stakingClaimTransaction.type, true);
    try {
      await this.contractService.stakingClaim();

    } catch (e) {
    }

    this.transactionsService.updateProcessingTransaction(stakingClaimTransaction.type, false);
    this.refresh();
  }

  async approve() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.transactionsService.updateProcessingTransaction(approveTransaction.type, true);

    try {
      await this.contractService.approve(environment.tidalAddress, environment.stakingAddress);
      this.needApproval = false;
    } catch(e) {
    }

    this.transactionsService.updateProcessingTransaction(approveTransaction.type, false);
  }

  goToPortfolio() {
    this.onGoTo.emit({key: 'portfolio'});
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }

  showStake() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowStake = true;
  }

  closeStake() {
    this.willShowStake = false;
  }

  showUnstake() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.willShowUnstake = true;
  }

  closeUnstake() {
    this.willShowUnstake = false;
  }


}
