<div class="popup-bg">
  <div class="popup-window" style="text-align: center; background-color: #dfffff; max-width: 380px; height: 460px;">
    <div class="popup-title title" style="font-size: 18px; padding-left: 32px; background-color: white; border-top-left-radius: 16px; border-top-right-radius: 16px;">
      Deposit My Asset
      <a class="icon fr" href="javascript:void(0)" (click)="close()">x</a>
    </div>
    <div style="padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff;">
      <div style="display: inline-block;">
      <div style="float: left; margin-right: 6px;"><img src="../../assets/images/info.png" style="width: 20px; height: 20px; margin-top: 16px;" /></div>
      
      <p style="color: #4c6db9; overflow: hidden; text-align: left;">
        Pending deposit becomes effective in next epoch | week.
      </p>
      </div>
    <div style="width: 100%; display: inline-block; margin-top: -8px;">
      <p style="color: #002f9b; margin-top: 20px; float: left; margin-bottom: 0px;">My Reserve:</p>
    </div>
    <div style="width: 100%; display: inline-block; margin-top: 1px;">
      <p *ngIf="!loading" style=" margin-top: 0px; color: #002f9b; float: left;">
        <span *ngIf="!loading">{{ getFloatNumber(amount)? getNumber(myCurrentBalance) + ' + ' + getNumber(amount): getNumber(myCurrentBalance) }}</span>
        <span *ngIf="loading" class="icon-spinner"></span> USDC
      </p>
    </div>
    <div style="width: 100%; display: inline-block;">
      <p style="color: #002f9b; margin-top: 8px; float: left">My Pending Deposit:</p>
    </div>
    <div class="deposit-input">
        <input type="number" placeholder="" [(ngModel)]="amount" value="{{amount}}" min="0" oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? this.value : null" />
        <a href="javascript: void(0)" (click)="max()">Max</a>
    </div>
    <button class="pbutton" *ngIf="needApproval" [ngStyle]="{'background-color': loading? 'grey': '#002f9b'}" (click)="approve()" [disabled]="loading" >Approve <span class="icon-spinner" *ngIf="isTransactionsProcessing()"></span></button>
    <button class="pbutton" *ngIf="!needApproval" [ngStyle]="{'background-color': willDisableButton() ? 'grey': '#002f9b'}" 
      (click)="deposit()" 
      [disabled]="willDisableButton()" >{{ getFloatNumber(this.amount) > getFloatNumber(this.deltaBalance) + getFloatNumber(this.usdcBalance) ? 'Insufficient Fund' : 'Update' }} <span class="icon-spinner" *ngIf="isTransactionsProcessing()"></span></button>
    <div style="width: 100%; display: inline-block; margin-top: 16px;" >
      <p class="title" style="float: left; font-size: 13px;">My Wallet Balance:</p>
      <p  class="number" style="float: right; font-size: 13px;">
        <span *ngIf="!loading">{{ usdcBalance }}</span>
        <span *ngIf="loading" class="icon-spinner"></span> USDC
      </p>
    </div>
  </div>
</div>
</div>
