import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-protocal-info-popup',
  templateUrl: './protocal-info-popup.component.html',
  styleUrls: ['./protocal-info-popup.component.css']
})
export class ProtocalInfoPopupComponent implements OnInit {

  @Input() protocolInfoObj: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.onClose.emit();
  }

  formatBalance(value) {
    const result = '$' + (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatAmount(value) {
    const result  = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
