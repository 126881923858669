<div class="left container sidebar">
  <div id="fixed-sidebar" style="width: 300px;">
    <div class="logo">
      <a href="index.html"><img src="/assets/images/logo.png"></a>
    </div>
    <div class="side-menu">
      <ul>
        <li class="menu-item" [ngClass]="{'actived': key==='sell'}"  (click)="goTo('sell')">
          <img src="/assets/images/menu-01.svg">
          <img src="/assets/images/menu-01-active.svg">
          <a href="javascript:void(0)">
          USDC Reserve</a></li>
        <li class="menu-item" [ngClass]="{'actived': key==='guarantor'}" (click)="goTo('guarantor')">
          <img src="/assets/images/menu-03-active.svg">
          <img src="/assets/images/menu-03.svg">
          <a href="javascript:void(0)" >Guarantor</a></li>
        <li class="menu-item" [ngClass]="{'actived': key==='staking'}" (click)="goTo('staking')">
          <img src="/assets/images/menu-02.svg">
          <img src="/assets/images/menu-02-active.svg">
          <a href="javascript:void(0)">TIDAL Staking</a>
        </li>
        <li class="menu-item" [ngClass]="{'actived': key==='buycover'}" (click)="goTo('buycover')">
          <img src="/assets/images/buycover.svg">
          <img src="/assets/images/buycover-active.svg">
          <a href="javascript:void(0)">Buy Cover</a>
        </li>
        <li *ngIf="hasAsset" class="menu-item" [ngClass]="{'actived': key==='buy'}"  (click)="goTo('buy')">
          <img src="/assets/images/buycover.svg">
          <img src="/assets/images/buycover-active.svg">
          <a href="javascript:void(0)">Cover Account</a></li>
        <li class="menu-item" [ngClass]="{'actived': key==='cover-status'}"  (click)="goTo('cover-status')">
          <img src="/assets/images/menu-05.svg">
          <img src="/assets/images/menu-05-active.svg">
          <a href="javascript:void(0)">Cover Status</a>
        </li>
        <li class="menu-item" [ngClass]="{'actived': key==='claims'}"  (click)="goTo('claims')">
          <img src="/assets/images/menu-04.svg">
          <img src="/assets/images/menu-04-active.svg">
          <a href="javascript:void(0)">Claims</a>
        </li>
        <li class="menu-item" [ngClass]="{'actived': key==='governance'}"  (click)="goTo('governance')">
          <img src="/assets/images/governance.svg">
          <img src="/assets/images/governance-active.svg">
          <a href="javascript:void(0)">Governance</a>
        </li>
      </ul>
    </div>
    <div class="copyright">
      <a href="https://old.tidal.finance" target="_blank">Old Site</a>
      <p>Copyright © Tidal 2021</p>
    </div>
  </div>
</div>
