import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MobileHeadComponent } from './mobile-head/mobile-head.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MainContentComponent } from './main-content/main-content.component';
import { MobileFooterComponent } from './mobile-footer/mobile-footer.component';
import { AccountComponent } from './account/account.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { MarketComponent } from './market/market.component';
import { BuyComponent } from './buy/buy.component';
import { SellComponent } from './sell/sell.component';
import { ClaimsComponent } from './claims/claims.component';
import { StakingComponent } from './staking/staking.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { BuyDepositComponent } from './buy-deposit/buy-deposit.component';
import { BuyWithdrawComponent } from './buy-withdraw/buy-withdraw.component';
import { BuySubscribeComponent } from './buy-subscribe/buy-subscribe.component';
import { BuyUnsubscribeComponent } from './buy-unsubscribe/buy-unsubscribe.component';
import { SellDepositComponent } from './sell-deposit/sell-deposit.component';
import { SellWithdrawComponent } from './sell-withdraw/sell-withdraw.component';
import { GuarantorComponent } from './guarantor/guarantor.component';
import { GuarantorDepositComponent } from './guarantor-deposit/guarantor-deposit.component';
import { GuarantorWithdrawComponent } from './guarantor-withdraw/guarantor-withdraw.component';
import { SellBasketComponent } from './sell-basket/sell-basket.component';
import { StakeComponent } from './stake/stake.component';
import { UnstakeComponent } from './unstake/unstake.component';
import { FileAClaimComponent } from './file-a-claim/file-a-claim.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { TransactionsWindowComponent } from './transactions-window/transactions-window.component';
import { CoverStatusComponent } from './cover-status/cover-status.component';
import { ProtocolQueuedupdateComponent } from './protocol-queuedupdate/protocol-queuedupdate.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProtocalInfoPopupComponent } from './protocal-info-popup/protocal-info-popup.component';
import { SettingsComponent } from './settings/settings.component';
import { BuyAdjustcoverageComponent } from './buy-adjustcoverage/buy-adjustcoverage.component';
import { GovernanceComponent } from './governance/governance.component';
import { BuyCoverComponent } from './buy-cover/buy-cover.component';

@NgModule({
  declarations: [
    AppComponent,
    MobileHeadComponent,
    SideMenuComponent,
    MainContentComponent,
    MobileFooterComponent,
    AccountComponent,
    AlertMessageComponent,
    MarketComponent,
    BuyComponent,
    SellComponent,
    ClaimsComponent,
    StakingComponent,
    PortfolioComponent,
    BuyDepositComponent,
    BuyWithdrawComponent,
    BuySubscribeComponent,
    BuyUnsubscribeComponent,
    SellDepositComponent,
    SellWithdrawComponent,
    GuarantorComponent,
    GuarantorDepositComponent,
    GuarantorWithdrawComponent,
    SellBasketComponent,
    StakeComponent,
    UnstakeComponent,
    FileAClaimComponent,
    NotificationMessageComponent,
    TransactionsWindowComponent,
    CoverStatusComponent,
    ProtocolQueuedupdateComponent,
    ProtocalInfoPopupComponent,
    SettingsComponent,
    BuyAdjustcoverageComponent,
    GovernanceComponent,
    BuyCoverComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
