var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { sellerDepositTransaction, sellerReduceDepositTransaction, approveTransaction } from '../transactions.descriptions';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { environment } from '../../environments/environment';
var SellDepositComponent = /** @class */ (function () {
    function SellDepositComponent(contractService, apiService, transactionsService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.transactionsService = transactionsService;
        this.onClose = new EventEmitter();
        this.onRefresh = new EventEmitter();
        this.amount = "";
        this.usdcBalance = "";
        this.reserveBalance = "";
        this.myCurrentBalance = "";
        this.myFutureBalance = "";
        this.deltaBalance = "";
        this.needApproval = true;
        this.loading = false;
    }
    SellDepositComponent.prototype.ngOnInit = function () {
        this.loadCategory(this.category);
    };
    SellDepositComponent.prototype.loadCategory = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            var all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        if (!(this.contractService.address && this.contractService.usdcBalance)) return [3 /*break*/, 2];
                        this.usdcBalance = this.contractService.usdcBalance;
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getAllSellerBalance(category)];
                                        case 1:
                                            _a.reserveBalance = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getSellerCurrentBalance(this.contractService.address, category)];
                                        case 1:
                                            _a.myCurrentBalance = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getSellerFutureBalance(this.contractService.address, category)];
                                        case 1:
                                            _a.myFutureBalance = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var allowance;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getAllowance(environment.usdcAddress, this.contractService.address, environment.sellerAddress, environment.usdcDecimals)];
                                        case 1:
                                            allowance = _a.sent();
                                            this.needApproval = parseFloat(allowance) < parseFloat(this.usdcBalance);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.deltaBalance = (+Math.max(0, parseFloat(this.myFutureBalance) - parseFloat(this.myCurrentBalance)).toFixed(2)).toString();
                        this.amount = this.deltaBalance;
                        _a.label = 2;
                    case 2:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SellDepositComponent.prototype.max = function () {
        this.amount = (parseFloat(this.usdcBalance) + parseFloat(this.deltaBalance)).toFixed(2).toString();
    };
    SellDepositComponent.prototype.getNumber = function (x) {
        return this.formatTokenBalance(parseFloat(x));
    };
    SellDepositComponent.prototype.getFloatNumber = function (x) {
        return parseFloat(x);
    };
    SellDepositComponent.prototype.approve = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.transactionsService.updateProcessingTransaction(approveTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.approve(environment.usdcAddress, environment.sellerAddress)];
                    case 2:
                        _a.sent();
                        this.needApproval = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.transactionsService.updateProcessingTransaction(approveTransaction.type, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    SellDepositComponent.prototype.deposit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!parseFloat(this.amount) && parseFloat(this.amount) !== 0) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!(+this.amount > +this.deltaBalance)) return [3 /*break*/, 3];
                        this.transactionsService.updateProcessingTransaction(sellerDepositTransaction.type, true);
                        return [4 /*yield*/, this.contractService.sellerDeposit(this.category, +(parseFloat(this.amount) - parseFloat(this.deltaBalance)).toFixed(2))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        this.transactionsService.updateProcessingTransaction(sellerReduceDepositTransaction.type, true);
                        return [4 /*yield*/, this.contractService.sellerReduceDeposit(this.category, +(parseFloat(this.deltaBalance) - parseFloat(this.amount)).toFixed(2))];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, this.loadCategory(this.category)];
                    case 6:
                        _a.sent();
                        this.amount = "";
                        return [3 /*break*/, 8];
                    case 7:
                        e_2 = _a.sent();
                        return [3 /*break*/, 8];
                    case 8:
                        this.transactionsService.updateProcessingTransaction(sellerDepositTransaction.type, false);
                        this.transactionsService.updateProcessingTransaction(sellerReduceDepositTransaction.type, false);
                        this.onRefresh.emit();
                        return [2 /*return*/];
                }
            });
        });
    };
    SellDepositComponent.prototype.isTransactionsProcessing = function () {
        return this.transactionsService.processingTransactions[sellerDepositTransaction.type] ||
            this.transactionsService.processingTransactions[sellerReduceDepositTransaction.type] ||
            this.transactionsService.processingTransactions[approveTransaction.type];
    };
    SellDepositComponent.prototype.willDisableButton = function () {
        var buttonDisabled = this.loading || (this.getFloatNumber(this.amount) !== 0 && !this.amount) ||
            (this.getFloatNumber(this.amount) !== 0 && !this.getFloatNumber(this.amount)) ||
            this.getFloatNumber(this.amount) > this.getFloatNumber(this.deltaBalance) + this.getFloatNumber(this.usdcBalance) || this.isTransactionsProcessing();
        return buttonDisabled;
    };
    SellDepositComponent.prototype.close = function () {
        this.onClose.emit();
    };
    SellDepositComponent.prototype.formatTokenBalance = function (value) {
        var result = (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    return SellDepositComponent;
}());
export { SellDepositComponent };
