/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../transactions-window/transactions-window.component.ngfactory";
import * as i4 from "../transactions-window/transactions-window.component";
import * as i5 from "../transactions.service";
import * as i6 from "../alert-message/alert-message.component.ngfactory";
import * as i7 from "../alert-message/alert-message.component";
import * as i8 from "./account.component";
import * as i9 from "../contract.service";
var styles_AccountComponent = [i0.styles];
var RenderType_AccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountComponent, data: {} });
export { RenderType_AccountComponent as RenderType_AccountComponent };
function View_AccountComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_AccountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "crypto-fund"], ["style", "cursor: pointer; display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "eth mobile-padding-10"], ["style", "align-self: stretch; display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " USDC"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "eth-add"], ["style", "display: flex; align-items: center;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleTransactionsShown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.transactionsService.isTransactionsProcessing; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usdcBalance; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.abbrAddress; _ck(_v, 6, 0, currVal_1); }); }
function View_AccountComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-transactions-window", [["style", "height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"]], [[8, "title", 0]], [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.toggleTransactionsShown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TransactionsWindowComponent_0, i3.RenderType_TransactionsWindowComponent)), i1.ɵdid(1, 49152, null, 0, i4.TransactionsWindowComponent, [i5.TransactionsService], { address: [0, "address"] }, { onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.abbrAddress; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "Transactions"; _ck(_v, 0, 0, currVal_0); }); }
function View_AccountComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_AccountComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "crypto-fund"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "eth-add"], ["style", "display: flex; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Switch to Matic"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 5, 0, currVal_0); }, null); }
function View_AccountComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-message", [["style", "height: 100%; width: 100%; z-index: 1; position: Fixed; top: 0; left: 0; text-align: left;"]], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_AlertMessageComponent_0, i6.RenderType_AlertMessageComponent)), i1.ɵdid(1, 114688, null, 0, i7.AlertMessageComponent, [], { title: [0, "title"], body: [1, "body"] }, { onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertTitle; var currVal_1 = _co.alertBody; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.abbrAddress && _co.isCorrectNetwork); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.transactionsShown; _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.abbrAddress || !_co.isCorrectNetwork); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.willShowAlertMessage; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account", [], null, null, null, View_AccountComponent_0, RenderType_AccountComponent)), i1.ɵdid(1, 114688, null, 0, i8.AccountComponent, [i9.ContractService, i5.TransactionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountComponentNgFactory = i1.ɵccf("app-account", i8.AccountComponent, View_AccountComponent_Host_0, {}, { onRefresh: "onRefresh" }, []);
export { AccountComponentNgFactory as AccountComponentNgFactory };
