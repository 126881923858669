import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { max } from 'rxjs/operators';

import { ApiService } from '../api.service';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.css']
})
export class MarketComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  allCategories: any = [];
  allAssets: any = [];
  sumOfCovered: string = "";

  showAssets: any[] = [];
  category: string = '0';
  myAPRs: any = {};
  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.load();
  }
    
  async load() {
    const all = [(async () => {
      const data0 = await this.apiService.getAllCategories();
      this.allCategories = data0.map(category => {
        return {
          reserve: this.formatBalance(category.reserve),
          apr: this.formatRate(category.apr),
          payout: this.formatBalance(category.payout)
        };
      });
    })(), (async () => {
      const data1 = await this.apiService.getAllAssets();
      let sumOfCovered = 0;
      this.allAssets = data1.map(asset => {
        sumOfCovered += parseFloat(asset.currentSubscription);
        return {
          name: asset.name,
          index: asset.index,
          sellerTidalApr: asset.sellerTidalApr,
          apruse4Max: asset.apr,
          apr: this.formatRate(asset.apr),
          premiumRate: this.formatRate(asset.premiumRate),
          guarantorValue: this.formatBalance(asset.guarantorValue),
          sellerBalance: this.formatBalance(asset.sellerBalance),
          currentSubscription: this.formatBalance(asset.currentSubscription),
          assetUtilization: this.formatRate(asset.assetUtilization),
          oversold: asset.currentSubscription > asset.sellerBalance
        };
      });

      this.sumOfCovered = this.formatBalance(sumOfCovered);
    })()];
    
    await Promise.all(all);
    this.showAssets = this.allAssets;
  }
 
  getMyAPRs() {
    var maxApr = 0;
    var maxPremiumApr = 0;
    var maxTidalApr = 0;

    for (var i = 0; i < this.showAssets.length; i++) {
      let premiumAprNumber = this.allAssets[i].apruse4Max;
      let tidalAprNumber = this.allAssets[i].sellerTidalApr;
      maxPremiumApr += premiumAprNumber;
      maxTidalApr += tidalAprNumber;
    }

    maxApr = maxPremiumApr + maxTidalApr;
    this.myAPRs.maxPremiumApr = maxTidalApr
    this.myAPRs.premiumApr = maxPremiumApr
    return {
      maxApr: maxApr,
      maxPremiumApr: maxPremiumApr,
      maxTidalApr: maxTidalApr,
    }
  }

  formatBalance(value) {
    const result = '$' + (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatRate(value) {
    if (isNaN(value)) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  goToPortfolio() {
    this.onGoTo.emit({key: 'portfolio'});
  }

  goToStake() {
    this.onGoTo.emit({key: 'sell'});
  }

  goToEnroll() {
    this.onGoTo.emit({key: 'buy'});
  }
  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }
  showLockedDesc(type: string) {
    if(type === 'next'){
      this.showAlert("Queued Update(Locked)", "You have a pending queued update to become effective in the next week.");
    }
    else if(type === 'nextNext'){
      this.showAlert("New Update", "New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.");
    }
    else if(type === 'current'){
      return;
      // this.showAlert("New Update", "New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.");
    }
    else if(type ==='myPremium'){
      this.showAlert("My APR", `Premium APR: ${this.formatRate(this.myAPRs.premiumApr)}
      \xa0\xa0\xa0\xa0\xa0\xa0\xa0 \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0
      TIDAL Reward APR: ${this.formatRate(this.myAPRs.tidalApr)}`)
    }
    else if(type ==='maxPremium'){
      this.showAlert("Max APR", `Premium APR: ${this.formatRate(this.myAPRs.maxPremiumApr)}
      \xa0\xa0\xa0\xa0\xa0\xa0\xa0 \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0
      TIDAL Reward APR: ${this.formatRate(this.myAPRs.maxTidalApr)}`)
    }
    return;
  }
}
