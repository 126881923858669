<div class="popup-bg">
  <div class="popup-window" style="text-align: center; background-color: #dfffff; max-width: 380px;">
    <div class="popup-title title" style="font-size: 18px; padding-left: 32px; background-color: white; border-top-left-radius: 16px; border-top-right-radius: 16px;">
      Update Selection
      <a class="icon fr" href="javascript:void(0)" (click)="close()">x</a>
    </div>
    <div style="padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff;">
      <div style="display: inline-block;">
        <div style="float: left; margin-right: 6px;"><img src="../../assets/images/info.png" style="width: 20px; height: 20px; margin-top: 16px;" /></div>

        <p style="color: #4c6db9; overflow: hidden; text-align: left;">
          New update will be locked in the next epoch. If there is no valid claim, update will become effective after the next epoch.
        </p>
      </div>
    </div>

    <div style="width: 100%; display: inline-block; margin-top: 1px;">
      <ng-container *ngFor="let asset of showAssets">
        <span class="asset-box" (click)="toggleAsset(asset.index)"
            [ngClass]="{'active': indexes.indexOf(asset.index) >= 0}">
            {{asset.index}}
          <img src="/assets/images/a{{ asset.index + 1 }}.png">
          <span>{{ asset.symbol }}</span>
        </span>
      </ng-container>
    </div>

    <!-- <div style="width: 100%; display: inline-block; margin-top: 1px;">
      <ng-container *ngFor="let asset of showAssets">
        <span class="asset-box" (click)="toggleAsset(asset.index)"
            [ngClass]="{'active': indexes.indexOf(asset.index) >= 0, 'pending': isPending}">
          <img src="/assets/images/a{{ asset.index + 1 }}.png">
          <span>{{ asset.symbol }}</span>
        </span>
      </ng-container>
    </div> -->

    <button class="pbutton" [disabled]="willButtonDisable()" (click)="update()">
      Update <span class="icon-spinner" *ngIf="willButtonDisable()"></span>
    </button>

    <!-- <button class="pbutton" *ngIf="!isPending" [disabled]="updating" (click)="update()">
      Update <span class="icon-spinner" *ngIf="updating"></span>
    </button> -->

    <!-- <div style="padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff; margin-bottom: 15px;" *ngIf="isPending">
      <div style="display: inline-block;">
        <p style="color: #4c6db9; overflow: hidden; text-align: left;">
          You have a locked pending update become effective in the next epoch. New updates can be made when there is no locked pending updates.
        </p>
      </div>
    </div> -->
  </div>
</div>

<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>
