<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">Buy cover</h2>
  </div>
</div>

<div id="toggleTabBar" class="margin-12-per">
  <div (click)="showOverview()" class="flex-center-item" [ngClass]="{'tab-active': tabIndex==0}"><p>Overview</p></div>
  <div (click)="showRetail()" class="flex-center-item" [ngClass]="{'tab-active': tabIndex==1}"><p>User purchase setting</p></div>
</div>

<ng-container *ngIf="tabIndex==0">
  <div class="row pre-deposit padding-left-32">
    <div class="box-wrap" style="display: flex;gap: 28px;background-color: unset;">
      <div class="column-8 number-box">
        <div class="column-5" style="padding-left: 34px;padding-top: 30px;">
          <p class="title" style="margin: 0;display: flex;">My Predeposit Balance<img (click)="showLockedDesc('predeposit')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;cursor: pointer;" /></p>
          <p class="number font-20-blue" >{{ formatTokenBalance(predepositBalance, 0) }} <span class="font-18-blue">USDC</span>
            <!-- <span class="-suspended" *ngIf="userBuyerInfo.weekEnd < currentWeek">(suspended)</span> -->
          </p>
        </div>
        <div class="column-3 padding-top-60" style="border-width: 0">
            <a href="javascript:void(0)" (click)="showDeposit()" class="button">Deposit</a>
        </div>
        <div class="column-1 padding-top-60" style="border-width: 0">
      </div>
        <div class="column-3 padding-top-60" style="border-width: 0">
          <a href="javascript:void(0)" (click)="showWithdraw()" class="button bgWhite">Withdraw</a>
      </div>
      </div>
      <div class="column-4 number-box" style="display: flex;
      justify-content: center;align-items: center;width: 28%;">
        <div id="coverageBox">
          <p class="title" style="display: flex;">Estimate Coverage Period<img (click)="showLockedDesc('coveragePeriod')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" /></p>
          <p class="number" *ngIf="sumOfPremium">{{ weeksBeCovered }} Weeks</p>
          <p *ngIf="!sumOfPremium">Not covered yet</p>
        </div>
      </div>
    </div>
  </div>

  <div class="crypto-list row padding-left-32 padding-right-32" style="padding-top: 27px;" *ngIf="hasAsset">
    <div class="crypto-list-box">
      <div class="crypto-list-item protocol-box">
        <div class="guarantor-icon">
          <span class="icon"><img [src]="'/assets/images/a' + (assetIndex + 1) + '.png'"></span>
          <strong>{{ myAsset.name }}</strong>
        </div>
        <div class="guarantor-number guarantor-number-1">
          <span>Premium Fee (weekly)</span>
          <span class="number" style="font-size:16px">{{myAsset.premiumRate}}
          </span>
        </div>
        <div class="guarantor-number guarantor-number-2">
          <span>USDC Reserve</span>
          <span class="number">{{formatBalance(myAsset.sellerBalance, 0)}}</span>
        </div>
        <div class="guarantor-number guarantor-number-3">
          <span>My Covered TVL</span>
          <span class="number">
            <span class="icon-spinner" *ngIf="myAsset.loading"></span>
            <span class="number" [ngClass]="{'-inactive': userBuyerInfo.weekEnd < currentWeek}"
              *ngIf="!myAsset.loading&&myAsset.myCurrentCoveredAmount==myAsset.myFutureCoveredAmount">{{ myAsset.myCurrentCoveredAmount }}
            </span>
            <span class="number" [ngClass]="{'-inactive': userBuyerInfo.weekEnd < currentWeek}"
              *ngIf="!myAsset.loading&&myAsset.myCurrentCoveredAmount!=myAsset.myFutureCoveredAmount">{{ myAsset.myCurrentCoveredAmount }} (this week) <br/>
            {{ myAsset.myFutureCoveredAmount }} (next week)
          </span></span>
        </div>
        <div class="guarantor-number guarantor-number-2">
          <span>My Cover Cost (weekly)</span>
          <span class="number">
            <span class="icon-spinner" *ngIf="myAsset.loading"></span>
            <ng-container *ngIf="userBuyerInfo.weekEnd >= currentWeek">
              <span class="number" *ngIf="!myAsset.loading&&myAsset.myCurrentPremium==myAsset.myFuturePremium">{{ formatBalance(myAsset.myCurrentPremium) }}</span>
              <span class="number" *ngIf="!myAsset.loading&&myAsset.myCurrentPremium!=myAsset.myFuturePremium">{{ formatBalance(myAsset.myCurrentPremium) }} (this week) <br/>
                {{ formatBalance(myAsset.myFuturePremium) }} (next week)
              </span>
            </ng-container>
            <ng-container *ngIf="userBuyerInfo.weekEnd < currentWeek">
              <span class="-suspended">Suspended</span>
            </ng-container>
          </span>
        </div>
        <div class="guarantor-operation">
          <span (click)="showSubscribe('increase')">
            <img src="/assets/images/asset-Increase.svg">
          </span>
            <span (click)="showUnsubscribe()">
              <img src="/assets/images/asset-decrease.svg">
            </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="padding: 32px;display: flex;justify-content: end;gap: 24px;">
      <p class="des">Pay 20 USDC to file a claim.</p>
      <div style="display: inline-block;">
        <button class="button" *ngIf="needApproval" [ngStyle]="{'background-color': approving? 'grey': '#002f9b', 'font-weight': 700}"
            (click)="approve()" [disabled]="approving">Approve USDC <span class="icon-spinner" *ngIf="approving"></span></button>
        <button class="button" *ngIf="!needApproval" (click)="showFileAClaim()">File a Claim</button>
      </div>
  </div>

  <div class="left column-6 padding-left-32 padding-right-32">
    <h2 class="title">Payment History</h2>
  </div>

  <div class="crypto-list row padding-left-32 padding-right-32">
    <div class="crypto-list-box" id="paymentBox">
      <table class="crypto-list-tab" style="width: 100%">
        <tr class="crypto-list-title">
          <td><strong>Date</strong></td>
          <td><strong>Covered TVL</strong></td>
          <td><strong>Paid Amount</strong></td>
          <td><strong>USDC Reserve</strong></td>
          <td><strong>Refund</strong></td>
        </tr>
        <ng-container  *ngFor="let record of filteredBuyerHistory">
          <tr class="crypto-list-item">
            <td><span class="number">{{ record.date }}</span></td>
            <td><span class="number">{{ record.subscription }}</span></td>
            <td><span class="number">{{ record.premium }}</span></td>
            <td><span class="number">{{ record.assetBalance }}</span></td>
            <td><span class="number">{{ record.refund }}</span></td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tabIndex==1">
  <div class="tab-content margin-12-per">

    <div class="row pre-deposit">
      <div class="box-wrap padding-left-32" style="display: flex;gap: 28px;background-color: unset;">
        <div class="column-6 number-box">
          <div class="column-5">
            <p class="title" style="margin: 0;display: flex;">Total Covered Amount<img (click)="showLockedDesc('totalCoveredAmount')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" /></p>
            <p class="number font-20-blue" >${{ formatTokenBalance(capacityAll, 0) }} <span class="font-18-blue"></span>
            </p>
          </div>
          <div class="column-5" style="border-width: 0;">
            <p class="title" style="margin: 0;display: flex;">User Purchase Capacity<img (click)="showLockedDesc('UserPurchaseCapacity')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" /></p>
            <p class="number font-20-blue color-green">${{ formatTokenBalance(capacityAvailable, 0) }} <span class="font-18-blue"></span>
            </p>
          </div>
          <div class="column-2" style="border-width: 0">
            <a href="javascript:void(0)" (click)="showAdjust()" class="button adjust-button">Adjust</a>
          </div>
        </div>
        <div class="column-6 number-box">
          <div class="column-5">
            <p class="title" style="margin: 0;display: flex;">Premium Fee (Weekly)<img (click)="showLockedDesc('premiunFeeWeekly')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" /></p>
            <p class="number font-20-blue" >${{ myAsset.premiumRate }} <span class="font-18-blue"></span>
            </p>
          </div>
          <div class="column-5" style="border-width: 0;">
            <p class="title" style="margin: 0;display: flex;">User Premium Fee (Weekly)<img (click)="showLockedDesc('UserPremiumFee')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" /></p>
            <p class="number font-20-blue color-yellow" >${{ retailPremiumRate }} <span class="font-18-blue"></span>
            </p>
          </div>
          <div class="column-2" style="border-width: 0">
          </div>
        </div>
      </div>
    </div>

    <div class="left column-6 padding-left-32 padding-right-32">
      <h2 class="title">User Purchase History</h2>
    </div>

    <div class="crypto-list row padding-left-32 padding-right-32">
      <div class="crypto-list-box" id="paymentBox">
        <table class="crypto-list-tab" style="width: 100%">
          <tr class="crypto-list-title">
            <td><strong>Date</strong></td>
            <td><strong>User Purchased Amount</strong></td>
            <td><strong>User Paid Amount (USDC)</strong></td>
            <td><strong>User Paid Amount ({{ myAsset.assetSymbol }})</strong></td>
            <td><strong></strong></td>
          </tr>
          <ng-container *ngFor="let record of filteredRetailHistory; index as i">
            <tr class="crypto-list-item">
              <td><span class="number">{{ formatDate(record.date) }}</span></td>
              <td><span class="number">${{ formatTokenBalance(record.subscription, 0) }}</span></td>
              <td><span class="number">${{ formatTokenBalance(record.premiumBase, 2) }}</span></td>
              <td><span class="number">{{ formatTokenBalance(record.premiumAsset, 2) }} {{myAsset.assetSymbol}}</span></td>
              <td><span class="exportBox" (click)="download(i)">
                <img src="/assets/images/export.svg">
                <span>Export
                </span>
              </span></td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>

  </div>
</ng-container>

<app-file-a-claim
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowFileAClaim"
  (onClose)="closeFileAClaim()"
  >
</app-file-a-claim>

<app-buy-deposit
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowDeposit"
  [premiumSum]=sumOfPremium
  (onClose)="closeDeposit()"
  (onRefresh)="refresh()">
</app-buy-deposit>

<app-buy-withdraw
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowWithdraw"
  (onClose)="closeWithdraw()"
  (onRefresh)="refresh()">
</app-buy-withdraw>

<app-buy-subscribe
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowSubscribe"
  [assetIndex]=selectedAssetIndex
  [premiumRate]=seletedPremiumRate
  [subscribeType]=subscribeType
  [assetSymbol]=assetSymbol
  (onClose)="closeSubscribe()"
  (onRefresh)="refresh()">
</app-buy-subscribe>

<app-buy-unsubscribe
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowUnsubscribe"
  [assetIndex]=selectedAssetIndex
  [premiumRate]=seletedPremiumRate
  [assetSymbol]=assetSymbol
  (onClose)="closeUnsubscribe()"
  (onRefresh)="refresh()">
</app-buy-unsubscribe>

<app-buy-adjustcoverage
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowAdjust"
  [capacityAll]=capacityAll
  [assetIndex]=assetIndex
  [assetSymbol]=assetSymbol
  (onClose)="closeAdjust()"
  (onRefresh)="refresh()">
</app-buy-adjustcoverage>

<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>
