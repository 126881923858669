var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { stakingClaimTransaction, approveTransaction } from '../transactions.descriptions';
import { environment } from '../../environments/environment';
var StakingComponent = /** @class */ (function () {
    function StakingComponent(contractService, apiService, transactionsService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.transactionsService = transactionsService;
        this.onGoTo = new EventEmitter();
        this.poolBalance = 0;
        this.walletBalance = 0;
        this.stakedBalance = 0;
        this.earnedBalance = 0;
        this.amount = 0;
        this.withdrawRequests = [];
        this.needApproval = true;
        this.loading = false;
        this.claiming = false;
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
        this.withdrawSumAmount = 0;
        this.apr = 0;
        this.willShowStake = false;
        this.willShowUnstake = false;
    }
    StakingComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    StakingComponent.prototype.refresh = function () {
        this.loadData();
    };
    StakingComponent.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        this.loading = true;
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.balanceOf(environment.tidalAddress, this.contractService.address, environment.tidalDecimals)];
                                        case 1:
                                            _a.walletBalance = +(_b.sent());
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var userInfo;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getStakingUserInfo(this.contractService.address)];
                                        case 1:
                                            userInfo = _a.sent();
                                            this.stakedBalance = +(userInfo.amount / (Math.pow(10, environment.tidalDecimals))).toFixed(2);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var poolInfo;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getStakingPoolInfo()];
                                        case 1:
                                            poolInfo = _a.sent();
                                            this.poolBalance = +(poolInfo.amount / (Math.pow(10, environment.tidalDecimals))).toFixed(2);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getStakingPendingReward()];
                                        case 1:
                                            _a.earnedBalance = +(_b.sent());
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var allowance;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getAllowance(environment.tidalAddress, this.contractService.address, environment.stakingAddress, environment.tidalDecimals)];
                                        case 1:
                                            allowance = _a.sent();
                                            this.needApproval = parseFloat(allowance) < 1e6;
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getStakingApr()];
                                        case 1:
                                            _a.apr = +((_b.sent()) / 100).toFixed(1);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.loadWithdrawRequests()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    StakingComponent.prototype.loadWithdrawRequests = function () {
        return __awaiter(this, void 0, void 0, function () {
            var now, withdrawRequests, all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getNow()];
                                        case 1:
                                            now = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getWithdrawRequestBackwards(this.contractService.address, 0, 10)];
                                        case 1:
                                            withdrawRequests = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.withdrawRequests = withdrawRequests.map(function (request) {
                            var amount = +(request.amount / (Math.pow(10, environment.tidalDecimals))).toFixed(2);
                            var status;
                            if (request.executed) {
                                status = "Executed";
                            }
                            else {
                                _this.withdrawSumAmount += amount;
                                status = Math.max(0, Math.floor(14.5 - (now - request.time) / (3600 * 24))) + " days left";
                            }
                            return {
                                amount: amount,
                                status: status
                            };
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    StakingComponent.prototype.max = function () {
        this.amount = this.walletBalance;
    };
    StakingComponent.prototype.formatBalance = function (value) {
        var result = (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    StakingComponent.prototype.isClaimingButtonDisabled = function () {
        return this.transactionsService.processingTransactions[stakingClaimTransaction.type];
    };
    StakingComponent.prototype.isApproving = function () {
        return this.transactionsService.processingTransactions[approveTransaction.type];
    };
    StakingComponent.prototype.claim = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            this.showAlert("Please connect to MetaMask", "");
                            return [2 /*return*/];
                        }
                        this.transactionsService.updateProcessingTransaction(stakingClaimTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.stakingClaim()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.transactionsService.updateProcessingTransaction(stakingClaimTransaction.type, false);
                        this.refresh();
                        return [2 /*return*/];
                }
            });
        });
    };
    StakingComponent.prototype.approve = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            this.showAlert("Please connect to MetaMask", "");
                            return [2 /*return*/];
                        }
                        this.transactionsService.updateProcessingTransaction(approveTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.approve(environment.tidalAddress, environment.stakingAddress)];
                    case 2:
                        _a.sent();
                        this.needApproval = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.transactionsService.updateProcessingTransaction(approveTransaction.type, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    StakingComponent.prototype.goToPortfolio = function () {
        this.onGoTo.emit({ key: 'portfolio' });
    };
    StakingComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    StakingComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    StakingComponent.prototype.showStake = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowStake = true;
    };
    StakingComponent.prototype.closeStake = function () {
        this.willShowStake = false;
    };
    StakingComponent.prototype.showUnstake = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowUnstake = true;
    };
    StakingComponent.prototype.closeUnstake = function () {
        this.willShowUnstake = false;
    };
    return StakingComponent;
}());
export { StakingComponent };
