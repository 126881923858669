<div class="top-part top-info  padding-left-32 padding-top-32 padding-right-32 mobile-top-part">
  <app-account class="hide-box"></app-account>
  <div class="potfolio-btn fr pc-potfolio-btn">
    <a class="button" href="javascript:void(0)" (click)="goToPortfolio()">My Reward</a>
  </div>
</div>
<app-market *ngIf="key=='market'" (onGoTo)="goTo($event)"></app-market>
<app-buy *ngIf="key=='buy'" (onGoTo)="goTo($event)"></app-buy>
<app-sell *ngIf="key=='sell'" (onGoTo)="goTo($event)"></app-sell>
<app-guarantor *ngIf="key=='guarantor'" (onGoTo)="goTo($event)"></app-guarantor>
<app-staking *ngIf="key=='staking'" (onGoTo)="goTo($event)"></app-staking>
<app-cover-status *ngIf="key=='cover-status'" (onGoTo)="goTo($event)"></app-cover-status>
<app-claims *ngIf="key=='claims'" (onGoTo)="goTo($event)"></app-claims>
<app-governance *ngIf="key=='governance'" (onGoTo)="goTo($event)"></app-governance>
<app-portfolio *ngIf="key=='portfolio'"></app-portfolio>
<app-settings
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowSettings"
  (onClose)="closeSettings()"
  >
</app-settings>
<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>
<app-notification-message></app-notification-message>
<app-buy-cover *ngIf="key=='buycover'" (onGoTo)="goTo($event)"></app-buy-cover>
