<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">Governance</h2>
    <p class="des font-16-blue">Vote for proposals.</p>
  </div>
</div>
<div class="mobile-flex-column row padding-left-32 padding-right-32 sec-staking-info" style="margin-top: 32px;">
  <div class="delegate-row">
    <button class="button bgWhite font-weight-700" [disabled]="delegating" (click)="delegateToMe()">Delegate to Me</button>
    <button class="button bgWhite font-weight-700" [disabled]="delegating" (click)="delegateToDev()">Delegate to Dev</button>
  </div>
  <div class="info-row">
    My votes (after delegation): {{myVotes}}
  </div>
  <div class="content-row">
    <p>
      Proposal:
    </p>
    <p>
85,000 USDC will be deducted from the UST reserve pool.
    </p>
    <p>
The current UST’s valid coverage is 85,000 USDC. With detailed accounts below:
    </p>
    <p>
0x04C18CbF703c72591F1800b52d801a948f55e00C: 10,000 USDC<br/>
0x35EFcCEe720aEC52837240Eb87347eAB80fe25f0: 5,000 USDC<br/>
0xAAED29b230319D9e4208684c2FDC452Ec78F5048: 50,000 USDC<br/>
0xecA882093a6d319C8DaE679cD7610196a26e4777: 20,000 USDC
    </p>
    <p>
7,727,273 TIDAL tokens will be deducted from the current staking pool.
    </p>
    <p>
TIDAL staking pool will reimburse 8,500 USD (10% of the payout amount) in TIDAL tokens to the USDC reserve providers after payout. The reimbursed TIDAL will reflect the average price the day before the de-peg on May.9th. at a price of $0.0011. This is a -1.4% deduction from the total pool.
    </p>
    <p>
Final payout requires policyholders to prove the ownership of UST at the time of cover purchase, as well as transferring the UST in exchange of USDC. We propose a 20 days period from today for policyholders to do so - deadline by June 7th. Failed claims will be returned to the USDC depositor.
    </p>
    <p>
Approved covered accounts transaction details:
    </p>
    <p>
0x04C18CbF703c72591F1800b52d801a948f55e00C: 10,000<br/>
Deposit tx: https://polygonscan.com/tx/0x6de7f6d24a384c193ffa5558688d5003d6a60c00a0bd5ce8d54e48374cce4aaa<br/>
Covered amount subscription:<br/>
https://polygonscan.com/tx/0x4f83e7110d571a92d9eabc8dff24e9110ce6495b2fcc9b6a990b40cb66dd3616<br/>
    </p>
    <p>
0x35EFcCEe720aEC52837240Eb87347eAB80fe25f0: 5,000<br/>
Deposit tx:<br/>
https://polygonscan.com/tx/0x24f2c5d9b000950562ac109e8b4932bac7957b90fc6b4f47e2747b8b8308f1f2<br/>
Covered amount subscription:<br/>
0x76a87833cdcab77f0093d67e9f334c839daa44c3c897f9ac52b31696c12c8051<br/>
    </p>
    <p>
0xAAED29b230319D9e4208684c2FDC452Ec78F5048: 50,000<br/>
Deposit tx: https://polygonscan.com/tx/0x12f0a073ebc6851644af6083342efeffb43968a74842d53b94bfa44e9f159f7a<br/>
Covered amount subscription:<br/>
https://polygonscan.com/tx/0x8a76e7f553b9b0c91d72eebcb52c7f872eb7aa559f0ac0a3f41efc951bf5c8d4<br/>
    </p>
    <p>
0xecA882093a6d319C8DaE679cD7610196a26e4777: 20,000<br/>
Deposit tx: https://polygonscan.com/tx/0x62994cab6dd1f1fdf592b2933f06505643490bf2ef6868e5cb2654824c78be97<br/>
Covered amount subscription:<br/>
https://polygonscan.com/tx/0x8594dd7758352998fe2f3869be5779a65d9f892f043072f7f785385178d05edf
    </p>
  </div>
  <div class="info-row">Received {{ formatVotes(proposal.forVotes) }} for votes, and {{ formatVotes(proposal.againstVotes) }} against votes.</div>
  <div class="vote-row">
    <button class="button font-weight-700" [disabled]="casting" (click)="castVote(true)">Support</button>
    <button class="button font-weight-700" [disabled]="casting" (click)="castVote(false)">Reject</button>
  </div>
</div>
