<div class="content row top-info top-title padding-left-32 ">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">USDC Reserve</h2>
    <p class="des font-16-blue">Customize your pool to your preferences</p>
  </div>
</div>
<div class="padding-left-32 padding-right-32">
  <div class="content row top-info mobile-top-info" style="margin: 28px 0 0;position: relative;">
    <div class="left column-6 mobile-padding-0 mobile-gap-20" style="display: flex;">
      <p class="reserve-size des text-left" style="margin-right: 32px;display: flex;flex-direction: column;">
        <span class="font-14-gray">
          Reserve Size
        </span>
        <span class="size-box">
          {{ allCategories[0] ? (allCategories[0].reserve) : '-' }} USDC
        </span></p>
      <p class="reserve-size des text-left" style="display: flex;flex-direction: column;">
        <span class="font-14-gray"> Pending Deposits</span>
        <span class="size-box" style="border-color:  #FFC700;">
          {{ formatTokenBalance(pendingDepositAmount) }} USDC
        </span>
      </p>
    </div>
    <div class="right fr column-6">
    </div>
    <p class="duration des font-14-gray" style="text-align: right;position: absolute;right: 0;bottom: 0;">
      Current epoch ending in
      <span *ngIf="loading" class="icon-spinner"></span>
      <span *ngIf="!loading" style="color: #002F9B;font-weight: bold;">{{durationDays}}</span>
       days
       <span *ngIf="loading" class="icon-spinner"></span>
       <span *ngIf="!loading" style="color: #002F9B;font-weight: bold;">{{durationHours}} </span>
       hours</p>
  </div>
  <div class="row liquid">
    <div class="box-wrap mobile-flex-column" style="display: flex;">
      <div class="column-8 border-right mobile-flex-column" style="display: flex;justify-content: space-between;">
        <div style="display: inline-block; margin: 0 20px;">
          <p style="display: flex; align-items: center;" class="title font-16-gray">My APR
            <img (click)="showLockedDesc('myPremium')" src="/assets/images/info.png" style="cursor: pointer; width: 20px; height: 20px; margin-left: 4px;" />
          </p >
          <p *ngIf="!loading" class="number fontsize-32-bold" style="margin: 0;">{{ formatRate(getMyAPRs().myApr) }}</p >
          <p *ngIf="loading"><span class="icon-spinner"></span></p>
        </div>
        <div style="display: inline-block; margin: 0 20px;">
          <p style="display: flex; align-items: center;" class="title font-16-gray">Max APR<img (click)="showLockedDesc('maxPremium')" src="/assets/images/info.png" style="cursor: pointer; width: 20px; height: 20px; margin-left: 4px;" /></p >
          <p *ngIf="!loading" class="number fontsize-32-bold color-green" style="margin: 0;">{{ formatRate(getMyAPRs().maxApr) }}</p >
          <p *ngIf="loading"><span class="icon-spinner"></span></p>
        </div>
        <div style="display: inline-block; margin: 0 20px;">
          <p style="display: flex; align-items: center;" class="title font-16-gray">Estimated APR<img (click)="showLockedDesc('estimatedApr')" src="/assets/images/info.png" style="cursor: pointer; width: 20px; height: 20px; margin-left: 4px;" /></p >
          <p *ngIf="!loading" class="number fontsize-32-bold color-yellow" style="margin: 0;">{{ formatRate(getEstimatedApr()) }}</p >
          <p *ngIf="loading"><span class="icon-spinner"></span></p>
        </div>
      </div>
      <div class="column-4" style="display:flex;text-align: center;padding-top: 12px;justify-content: space-evenly;">
        <div class="title" style="display: inline-block; margin: 0 12px;">
          <div style="padding-left: 5px;text-align: center; margin-bottom: 16px; display: flex;flex-direction: column">
            <p style="display: flex;justify-content: space-between;align-items: center;">
              <span class="font-16-gray">My Reserve:</span>
              <span class="fontsize-18-bold">
                <span *ngIf="!loading" style="margin-right: 4px;">{{ formatTokenBalance(myCurrentBalance) }} </span>
                <span *ngIf="loading" class="icon-spinner" style="margin-right: 4px;"></span>
                <span style="font-size: 12px">USDC</span></span>
                <button class="proto-status" [disabled]="loading" style="height: 30px;font-size: 12px;
                font-weight: bold;" (click)="showPendingActions()">
                  <img src="/assets/images/pendingStatus.png"
                  style="cursor: pointer;">
                  Status
                  <span class="icon-spinner" *ngIf="loading"></span>
              </button>
            </p>
            <div class="button-list">
              <span class="button button-deposit"
                                (click)="showDeposit()">Deposit</span>
              <span class="button button-withdraw bgWhite" (click)="showWithdraw()">Withdraw</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content row top-info">
    <div class="left column-6 mobile-left-padding-0">
      <h2 class="title" style="margin: 17px 0 0 0;display: flex;align-items: center;">
        <span *ngIf="!isEditMode">Current Basket</span>
        <span *ngIf="isEditMode&&!isSetNewStakeProtocol">New Update</span>
        <span *ngIf="isEditMode&&isSetNewStakeProtocol">Current Basket</span>
        <p class="des">
          <span>
            <span class="button button-edit" *ngIf="!isEditMode&&!isSetNewStakeProtocol" (click)="toggleEditStakeProto()">Edit</span>
            <span class="button button-edit btn-white" *ngIf="isEditMode" (click)="resetProtoStatusList()">Back</span>
            <button class="button font-weight-700 btn-blue" *ngIf="isEditMode" [disabled]="isComfirm()" (click)="updateProtoStatus()">
              Confirm
              <span class="icon-spinner" *ngIf="isComfirm() || loading"></span>
            </button>
          </span>
        </p>
        <button *ngIf="!isEditMode&&!isSetNewStakeProtocol"
        (click)="this.isShowProtocolQueue = !this.isShowProtocolQueue" class="proto-status" [disabled]="loading">
          <img src="/assets/images/pendingStatus.png"
          style="cursor: pointer;">
          Status
          <span class="icon-spinner" *ngIf="loading"></span>
      </button>
      </h2>
    </div>
  </div>
  <div class="protocol-container" *ngIf="!isEditMode">
    <div class="row liquid ">
      <ng-container *ngFor="let asset of showAssets">
        <div class="protocol-item" *ngIf="currentIndexes.indexOf(asset.index) >= 0">
          <p>
            <span style="cursor: default;"
              [ngClass]="active">
              <img src="/assets/images/a{{ asset.index + 1 }}.png" style="width: 40px;height: 40px;">
              <span style="font-size: 15px;">{{ asset.symbol }}</span>
            </span>
            <!-- <img src="/assets/images/proto-info-icon.svg" style="width: 14px;height: 14px;" (click)="showProtocolsInfoPopup(asset)"> -->
            <span class="text-green text-right text-apr-loc" style="cursor: pointer;font-size: 24px;" (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')">
              {{ formatRate(asset.apr + asset.sellerTidalApr) }}
            </span>
          </p>
          <p>
            <span>USDC Reserve </span>
            <span>{{ asset.sellerBalance }}</span>
          </p>
          <p>
            <span>Guarantor Reserve</span>
            <span>{{ asset.guarantorValue }} in {{ asset.symbol }}</span>
          </p>
          <p>
            <span>Capital Utilization</span>
            <span>{{ asset.assetUtilization }}</span>
          </p>
          <div *ngIf="isEditMode" class="flex-center-between" style="gap: 20px;">
            <button *ngIf="isEditMode" (click)="toggleProtoStatus(asset, 'unstake')" class="btn-unstake" style="flex: 8;">Unstake</button>
            <span style="flex: 2;" class="proto-info-box" (click)="showProtocolsInfoPopup(asset)"
            [ngStyle]="{cursor: isDetailCanClick(asset) ? 'pointer' : 'disabled'}"
            >
              <img src="/assets/images/proto-info-active.svg" style="width: 14px;height: 14px;" *ngIf="isDetailCanClick(asset)">
              <img src="/assets/images/proto-info-icon.svg" style="width: 14px;height: 14px;" *ngIf="!isDetailCanClick(asset)">
            </span>
          </div>
          <div *ngIf="!isEditMode">
            <button  (click)="showProtocolsInfoPopup(asset)"
            [ngClass]="isDetailCanClick(asset) ? 'btn-detail' : 'btn-detail-disabled'"
            >Details</button>
          </div>
          <div class="float-item">
            <p>
              <span>USDC Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.apr) }}</span>
            </p>
            <p>
              <span>Tidal Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.sellerTidalApr) }}</span>
            </p>
            <p *ngIf="asset.moreApr">
              <span>{{ formatSymbol(asset.moreAprSymbol) }} Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.moreApr) }}</span>
            </p>
          </div>
      </div>
      </ng-container>
      <div style="background: white;" class="blink new-user protocol-item flex-column-center" *ngIf="isSetNewStakeProtocol" (click)="toggleEditStakeProto()">
        <img src="/assets/images/proto-add.svg">
        <p class="font-18-blue" style="margin: 0;">Please select your preferred protocol to earn yield. </p>
      </div>
      <div style="background: white;" class="new-user protocol-item flex-column-center" *ngIf="!isSetNewStakeProtocol&&currentIndexes.length === 0">
        <div style="text-align: center;" *ngIf="!loading">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
        <p *ngIf="!loading" class="font-18-blue" style="margin: 0;">Your current basket is empty</p>
        <p *ngIf="loading"><span class="icon-spinner"></span></p>
      </div>
    </div>
    <p class="des">
      <span class="des-title">Unstaked Protocol</span>
    </p>
    <div class="row liquid">
      <ng-container *ngFor="let asset of showAssets">
        <div class="protocol-item protocol-item-unstake" *ngIf="currentIndexes.indexOf(asset.index) < 0">
          <p>
            <span style="cursor: default;"
              [ngClass]="active">
              <img src="/assets/images/a{{ asset.index + 1 }}.png" style="width: 40px;height: 40px;">
              <span style="font-size: 15px;">{{ asset.symbol }}</span>
            </span>
            <span class="text-green text-right text-apr-loc" style="cursor: pointer;font-size: 24px;" (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')">
              {{ formatRate(asset.apr + asset.sellerTidalApr + asset.moreApr) }}
            </span>
          </p>
          <p>
            <span>USDC Reserve </span>
            <span>{{ asset.sellerBalance }}</span>
          </p>
          <p>
            <span>Guarantor Reserve</span>
            <span>{{ asset.guarantorValue }} in {{ asset.symbol }}</span>
          </p>
          <p>
            <span>Capital Utilization</span>
            <span>{{ asset.assetUtilization }}</span>
          </p>
          <div *ngIf="isEditMode" class="flex-center-between" style="gap: 20px;">
            <button *ngIf="isEditMode" (click)="toggleProtoStatus(asset, 'stake')" class="btn-unstake" style="flex: 8;
            background: #4C6DB9;color: white;
            border: 2px solid #00D2FE;">Stake</button>
            <span style="flex: 2;" class="proto-info-box" (click)="showProtocolsInfoPopup(asset)"
            [ngStyle]="{cursor: isDetailCanClick(asset) ? 'pointer' : 'disabled'}"
            >
              <img src="/assets/images/proto-info-active.svg" style="width: 14px;height: 14px;" *ngIf="isDetailCanClick(asset)">
              <img src="/assets/images/proto-info-icon.svg" style="width: 14px;height: 14px;" *ngIf="!isDetailCanClick(asset)">            </span>
          </div>
          <div *ngIf="!isEditMode">
            <button  (click)="showProtocolsInfoPopup(asset)"             [ngClass]="isDetailCanClick(asset) ? 'btn-detail' : 'btn-detail-disabled'">Details</button>
          </div>
          <div class="float-item">
            <p>
              <span>USDC Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.apr) }}</span>
            </p>
            <p>
              <span>Tidal Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.sellerTidalApr) }}</span>
            </p>
            <p *ngIf="asset.moreApr">
              <span>{{ formatSymbol(asset.moreAprSymbol) }} Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.moreApr) }}</span>
            </p>
          </div>
          <span style="position: absolute; left: 0; top: 0; z-index: 1;">
            <img src="/assets/images/r-1.png"  *ngIf="asset.symbol == 'MONOX'" style="width: 69px; height: 69px;">
            <img src="/assets/images/r-2.png"  *ngIf="asset.symbol == 'MONOX'" style="position: absolute;left: 5px;top: 5px;">
          </span>
          <img src="/assets/images/label-new.svg" style="position: absolute; right: 0; top: 0; z-index: 1;" *ngIf="asset.index == 27">
    </div>
      </ng-container>
  </div>
  </div>
  <!-- New Update -->
  <div class="protocol-container" *ngIf="isEditMode">
    <div class="row liquid ">
      <ng-container *ngFor="let asset of showAssets">
        <div *ngIf="nextNextWeekIndexes.indexOf(asset.index) >= 0"  class="protocol-item protocol-item-stake">
        <p>
          <span style="cursor: default;"
            [ngClass]="active">
            <img src="/assets/images/a{{ asset.index + 1 }}.png" style="width: 40px;height: 40px;">
            <span style="font-size: 15px;">{{ asset.symbol }}</span>
          </span>
          <!-- <img src="/assets/images/proto-info-icon.svg" style="width: 14px;height: 14px;" (click)="showProtocolsInfoPopup(asset)"> -->
          <span class="text-green text-right text-apr-loc" style="cursor: pointer;font-size: 24px;" (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')">
            {{ formatRate(asset.apr + asset.sellerTidalApr) }}
          </span>
        </p>
        <p>
          <span>USDC Reserve </span>
          <span>{{ asset.sellerBalance }}</span>
        </p>
        <p>
          <span>Guarantor Reserve</span>
          <span>{{ asset.guarantorValue }} in {{ asset.symbol }}</span>
        </p>
        <p>
          <span>Capital Utilization</span>
          <span>{{ asset.assetUtilization }}</span>
        </p>
        <div *ngIf="isEditMode" class="flex-center-between" style="gap: 20px;">
          <button *ngIf="isEditMode" (click)="toggleProtoStatus(asset, 'unstake')" class="btn-unstake" style="flex: 8;">Unstake</button>
          <span style="flex: 2;" class="proto-info-box" (click)="showProtocolsInfoPopup(asset)"
          [ngStyle]="{cursor: isDetailCanClick(asset) ? 'pointer' : 'disabled'}"
          >
            <img src="/assets/images/proto-info-active.svg" style="width: 14px;height: 14px;" *ngIf="isDetailCanClick(asset)">
            <img src="/assets/images/proto-info-icon.svg" style="width: 14px;height: 14px;" *ngIf="!isDetailCanClick(asset)">          </span>
        </div>
        <div *ngIf="!isEditMode">
          <button  (click)="showProtocolsInfoPopup(asset)"             [ngClass]="isDetailCanClick(asset) ? 'btn-detail' : 'btn-detail-disabled'">Details</button>
        </div>
        <div class="float-item">
          <p>
            <span>USDC Earning (APR)</span>
            <span class="text-green text-right">{{ formatRate(asset.apr) }}</span>
          </p>
          <p>
            <span>Tidal Earning (APR)</span>
            <span class="text-green text-right">{{ formatRate(asset.sellerTidalApr) }}</span>
          </p>
          <p *ngIf="asset.moreApr">
            <span>{{ formatSymbol(asset.moreAprSymbol) }} Earning (APR)</span>
            <span class="text-green text-right">{{ formatRate(asset.moreApr) }}</span>
          </p>
        </div>
      </div>
      </ng-container>
      <div style="background: white;" class="new-user protocol-item flex-column-center" *ngIf="nextNextWeekIndexes.length === 0">
        <div style="text-align: center;">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
        <p class="font-18-blue" style="margin: 0;" *ngIf="isSetNewStakeProtocol">Your current basket is empty. Edit your basket by staking protocols below. </p>
        <p class="font-18-blue" style="margin: 0;" *ngIf="!isSetNewStakeProtocol">Your new update is empty. Edit your basket by staking protocols below. </p>
      </div>
    </div>
    <p class="des">
      <span class="des-title">Unstaked Protocol</span>
    </p>
    <div class="row liquid">
      <ng-container *ngFor="let asset of showAssets">
        <div *ngIf="nextNextWeekIndexes.indexOf(asset.index) < 0" class="protocol-item protocol-item-unstake">
          <p>
            <span style="cursor: default;"
              [ngClass]="active">
              <img src="/assets/images/a{{ asset.index + 1 }}.png" style="width: 40px;height: 40px;">
              <span style="font-size: 15px;">{{ asset.symbol }}</span>
            </span>
            <span class="text-green text-right text-apr-loc" style="cursor: pointer;font-size: 24px;" (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')">
              {{ formatRate(asset.apr + asset.sellerTidalApr + asset.moreApr) }}
            </span>
          </p>
          <p>
            <span>USDC Reserve </span>
            <span>{{ asset.sellerBalance }}</span>
          </p>
          <p>
            <span>Guarantor Reserve</span>
            <span>{{ asset.guarantorValue }} in {{ asset.symbol }}</span>
          </p>
          <p>
            <span>Capital Utilization</span>
            <span>{{ asset.assetUtilization }}</span>
          </p>
          <div *ngIf="isEditMode" class="flex-center-between" style="gap: 20px;">
            <button *ngIf="isEditMode" (click)="toggleProtoStatus(asset, 'stake')" class="btn-unstake" style="flex: 8;
            background: #4C6DB9;color: white;
            border: 2px solid #00D2FE;">Stake</button>
            <span style="flex: 2;" class="proto-info-box" (click)="showProtocolsInfoPopup(asset)"
            [ngStyle]="{cursor: isDetailCanClick(asset) ? 'pointer' : 'disabled'}"
            >
              <img src="/assets/images/proto-info-active.svg" style="width: 14px;height: 14px;" *ngIf="isDetailCanClick(asset)">
              <img src="/assets/images/proto-info-icon.svg" style="width: 14px;height: 14px;" *ngIf="!isDetailCanClick(asset)">            </span>
          </div>
          <div *ngIf="!isEditMode">
            <button  (click)="showProtocolsInfoPopup(asset)" [ngClass]="isDetailCanClick(asset) ? 'btn-detail' : 'btn-detail-disabled'">Details</button>
          </div>
          <div class="float-item">
            <p>
              <span>USDC Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.apr) }}</span>
            </p>
            <p>
              <span>Tidal Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.sellerTidalApr) }}</span>
            </p>
            <p *ngIf="asset.moreApr">
              <span>{{ formatSymbol(asset.moreAprSymbol) }} Earning (APR)</span>
              <span class="text-green text-right">{{ formatRate(asset.moreApr) }}</span>
            </p>
          </div>
          <img src="/assets/images/label-new.svg" style="position: absolute; right: 0; top: 0; z-index: 1;" *ngIf="asset.index == 27">
        </div>
      </ng-container>
  </div>
  </div>
</div>

<app-sell-deposit
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowDeposit"
  [category]=category
  (onClose)="closeDeposit()"
  (onRefresh)="refresh()">
</app-sell-deposit>

<app-sell-withdraw
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowWithdraw"
  [category]=category
  (onClose)="closeWithdraw()"
  (onRefresh)="refresh()">
</app-sell-withdraw>

<app-sell-basket
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowBasket"
  [category]=category
  [myCurrentBalance]=myCurrentBalance
  [showAssets]=showAssets
  [indexes]="pendingIndexes"
  [isPending]=hasPending
  (onClose)="closeBasket()"
  (onRefresh)="refresh()">
</app-sell-basket>

<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>

<app-protocol-queuedupdate
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="isShowProtocolQueue"
  [showAssets]='showAssets'
  [currentIndexes]="currentIndexes"
  [nextWeekIndexes]='nextWeekIndexes'
  [nextNextWeekIndexes]='nextNextWeekIndexes'
  (onClose)="closeProtoStatus()">
</app-protocol-queuedupdate>

<app-protocal-info-popup
style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;overflow: auto;"
*ngIf="willShowProtocolPopup"
[protocolInfoObj]='protocolInfoObj'
(onClose)="closeProtocolPopup()"
>
</app-protocal-info-popup>
