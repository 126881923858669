<div class="popup-bg" (click)="close()">
</div>
<div class="popup-window" id="protocol-popup-window" [ngClass]="{'small-window': !isShowPendingUpdate}">
  <div class="popup-title title" style="position: relative;">
    <!-- <span class="icon"><img [src]="'/assets/images/a' + (protocolInfoObj.index + 1) + '.png'" style="width: 24px;height: 24px;"></span>
    <span>    {{ protocolInfoObj.title }}
    </span> -->
        <a class="icon fr" href="javascript:void(0)" (click)="close()" style="position: fixed;
        right: 13%;">x</a></div>
  <div class="deposit-row protocol-container proto-status-container" *ngIf="isShowPendingUpdate">
    <div class="protocol-item" *ngIf="!arraysEqual(nextWeekIndexes, currentIndexes)">
      <h1>Locked Update</h1>
      <p class="des">Locked update becomes effective next week.</p>
      <div class="flex-column">
        <div class="row liquid mobile-space-between" style="display: flex;
        gap: 20px;
        flex-wrap: wrap;">
          <ng-container *ngFor="let asset of showAssets" class="protocol-item">
            <div *ngIf="nextWeekIndexes.indexOf(asset.index) >= 0" class="protocol-item-stake">
              <p>
                <span style="cursor: default;display: flex;align-items: center;
                "
                class="text-blue-12"
                  >
                <img src="/assets/images/a{{ asset.index + 1 }}.png" style="width: 24px;height: 24px;">
                <span style="margin-left: 12px;font-size: 14px;" class="text-blue-12">{{ asset.symbol }}</span>
                </span>
              </p>
              <p>
                <span class="text-blue-12">Total APR</span>
                <span class="text-green">{{ formatRate(asset.apr + asset.sellerTidalApr) }}</span>
              </p>
              <!-- <p>
                <span>Premium Earning (APR)</span>
                <span class="text-green">{{ formatRate(asset.apr) }}</span>
              </p>
              <p>
                <span>Tidal Earning (APR)</span>
                <span>{{ formatRate(asset.sellerTidalApr) }}</span>
              </p> -->
              <p>
                <span class="text-blue-12">USDC Reserve</span>
                <span class="text-green">{{ asset.sellerBalance }}</span>
              </p>
            </div>
          </ng-container>
          <div class="new-user protocol-item flex-column-center protocol-item-stake" *ngIf="nextWeekIndexes.length === 0">
            <img src="/assets/images/pending-status.svg">
            <p class="text-blue-12" style="margin: 0;">Your locked update is empty. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="divide-line protocol-item" *ngIf="!arraysEqual(nextWeekIndexes, currentIndexes) && !arraysEqual(nextWeekIndexes, nextNextWeekIndexes)"></div>
    <div class="protocol-item" *ngIf="!arraysEqual(nextWeekIndexes, nextNextWeekIndexes)">
      <h1>New Update</h1>
      <p class="des">New update will be locked next week, and becomes effective the week after.</p>
      <div class="flex-column">
        <div class="row liquid mobile-space-between" style="display: flex;
        gap: 20px;
        flex-wrap: wrap;">
          <ng-container *ngFor="let asset of showAssets" class="protocol-item">
            <div *ngIf="nextNextWeekIndexes.indexOf(asset.index) >= 0" class="protocol-item-stake">
              <p>
                <span style="cursor: default;display: flex;align-items: center;
                "
                  >
                <img src="/assets/images/a{{ asset.index + 1 }}.png" style="width: 24px;height: 24px;">
                <span style="margin-left: 12px;font-size: 14px;" class="text-blue-12">{{ asset.symbol }}</span>
                </span>
              </p>
              <p>
                <span class="text-blue-12">Total APR</span>
                <span class="text-green">{{ formatRate(asset.apr + asset.sellerTidalApr) }}</span>
              </p>
              <!-- <p>
                <span>Premium Earning (APR)</span>
                <span class="text-green">{{ formatRate(asset.apr) }}</span>
              </p>
              <p>
                <span>Tidal Earning (APR)</span>
                <span>{{ formatRate(asset.sellerTidalApr) }}</span>
              </p> -->
              <p>
                <span class="text-blue-12">USDC Reserve</span>
                <span class="text-green">{{ asset.sellerBalance }}</span>
              </p>
            </div>
          </ng-container>
          <div class="new-user protocol-item flex-column-center protocol-item-stake" *ngIf="nextNextWeekIndexes.length === 0">
            <img src="/assets/images/pending-status.svg">
            <p class="text-blue-12" style="margin: 0;">Your new update is empty </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isShowPendingUpdate" class="flex-column-center box-width-100" >
    <img src="/assets/images/pending-status.svg" style="margin-bottom: 28px;">
    <p class="font-18-blue">No Pending Update</p>
  </div>
</div>
