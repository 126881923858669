import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-guarantor',
  templateUrl: './guarantor.component.html',
  styleUrls: ['./guarantor.component.css']
})
export class GuarantorComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  category: number = 0;
  showAssets: any = [];
  myAssets: any = [];

  willShowDeposit = false;
  willShowWithdraw = false;
  assetToShow: any = null;

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;

  bonusPerAssetOfG: number = 0;
  tidalPrice: number = 0;
  duration: number = 0;
  willShowProtocolPopup: boolean;
  protocolInfoObj: {
    guarantorCoverTvl: string;
    guarantorCollateralExpression: string;
    tidalCollateralExpression: string;
    chains: any;
    inceptionDate: number;
    key: string,
    tokenSymbol: string,
    name: string,
    tvl: number,
    guarantorCollateral: number,
    tidalCollateral: number,
    dateofMainnet: string,
    premiumPricing: string,
    features: string,
    auditsfirmsList: any[]
  }
  poolBalance: number;

  constructor(private contractService: ContractService, private apiService: ApiService) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    const data = await this.apiService.getAllAssets();
    const showAssets = data.filter(asset => asset.category == this.category &&
        asset.token != "0x0000000000000000000000000000000000000000").map(asset => {
      return {
        index: asset.index,
        name: asset.name,
        symbol: asset.symbol,
        decimals: asset.decimals,
        token: asset.token,
        category: asset.category,
        guarantorValueForProto: asset.guarantorValue,
        guarantorApr: (asset.guarantorApr),
        guarantorTidalApr: (asset.guarantorTidalApr),
        guarantorBalance: this.formatTokenBalance(asset.guarantorBalance),
        guarantorValue: this.formatTokenBalance(asset.guarantorValue),
        guarantorValueNumber: asset.guarantorValue,
        loading: true,
        premiumRate: asset.premiumRate,
        currentSubscription: asset.currentSubscription,
        myBalance: 0,
        myFutureBalance: 0,
        myPendingDeposit: 0,
        myPendingWithdraw: 0
      };
    });

    this.showAssets = showAssets;

    if (!this.contractService.address) {
      return;
    }

    await this.loadAssets(showAssets);
    this.showAssets = this.showAssets.sort(function (a, b) {
      return b.myBalance - a.myBalance || b.myPendingDeposit - a.myPendingDeposit;
    });
    Promise.all([
      (async () => await this.contractService.getNow())(),
      (async () => await this.contractService.getCurrentWeek())(),
    ]).then((values)=> {
      let secs = (values[1] + 1) * 3600 * 24 * 7 - values[0];
      this.duration = Math.floor(secs / (3600 * 24) - 4);  // 4 is the offset.
    })
    this.myAssets = showAssets.filter(asset => asset.myBalance > 0);
  }

  async loadAssets(showAssets) {
    const all = showAssets.map(async (asset, index) => {
      const obj = await this.contractService.getGuarantorUserBalance(
          this.contractService.address, asset.index, asset.decimals);

      showAssets[index].myBalance = +(+obj.currentBalance).toFixed(2);
      showAssets[index].myFutureBalance = +(+obj.futureBalance).toFixed(2);
      showAssets[index].myPendingDeposit = +((+obj.futureBalance) - (+obj.currentBalance)).toFixed(2);

      const nextWeekWithdraw = await this.contractService.getGuarantorNextWeekWithdraw(
          this.contractService.address, asset.index, asset.decimals);
      const nextNextWeekWithdraw = await this.contractService.getGuarantorNextNextWeekWithdraw(
          this.contractService.address, asset.index, asset.decimals);
      showAssets[index].myPendingWithdraw = nextWeekWithdraw + nextNextWeekWithdraw;

      showAssets[index].loading = false;
    });

    await Promise.all(all)
  }

  refresh() {
    this.load();
  }

  formatBalance(value) {
    const result = '$' + (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatTokenBalance(value) {
    const result = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatRate(value) {
    if (isNaN(value)) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  goToCategory(category) {
    this.category = category;
    this.load();
  }

  goToPortfolio() {
    this.onGoTo.emit({key: 'portfolio'});
  }

  showDeposit(asset) {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.assetToShow = asset;
    this.willShowDeposit = true;
  }

  closeDeposit() {
    this.willShowDeposit = false;
  }

  closeProtocolPopup() {
    this.willShowProtocolPopup = false;
  }

  isDetailCanClick (asset) {
    const protoAsset = environment.protocolList.find(proto => proto.key == asset.name);
    return protoAsset;
  }

  async showProtocolsInfoPopup (asset) {
    if (!this.isDetailCanClick(asset)) {
      return;
    }

    this.willShowProtocolPopup = true;
    this.protocolInfoObj = asset;
    this.protocolInfoObj = Object.assign(asset, {
      avatar: '/assets/images/a' + (asset.index + 1) + '.png',
      title: asset.name
    }, this.isDetailCanClick(asset));

    const tidalUSDprice = await this.getTokenPrice('tidal-finance');
    // tslint:disable-next-line:radix
    console.log('proto', asset.guarantorValueForProto)
    this.protocolInfoObj.inceptionDate = parseInt(String(+(new Date().getTime() - new Date(this.protocolInfoObj.dateofMainnet).getTime()) / 1000 / 3600 / 24));
    this.protocolInfoObj.chains = this.protocolInfoObj.chains.join(',');
    this.protocolInfoObj.guarantorCoverTvl = `${this.formatRate(+asset.guarantorValueForProto / asset.currentSubscription)}`;
    this.protocolInfoObj.guarantorCollateralExpression =  `${+asset.guarantorValueForProto.toFixed(2)}  /  ${asset.guarantorBalance} (${asset.symbol})`;
    this.protocolInfoObj.tidalCollateralExpression =  this.getTidalCollateral(tidalUSDprice);
    this.protocolInfoObj.premiumPricing = this.formatRate(asset.premiumRate) + ' Weekly';
  }

  toggleShowFloatPopup ($event, status) {
    const floatItem = $event.target.querySelector('.float-item');
    this.adjustFloatItemPos(floatItem, status);
  }

  showPendingActions(asset) {
    this.showAlert('Pending Status', `Pending Deposit:
      ${this.formatTokenBalance(asset.myPendingDeposit)} ${asset.symbol.toUpperCase()}
      \xa0\xa0\xa0\xa0\xa0\xa0\xa0 \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0
      Pending Withdraw:
      ${this.formatTokenBalance(asset.myPendingWithdraw)} ${asset.symbol.toUpperCase()}`);
  }

  adjustFloatItemPos (elem, status) {
    elem.style.visibility = status;
  }

  async getTokenPrice(token) {
    const url = `https://api.coingecko.com/api/v3/simple/price?ids=${token}&vs_currencies=usd`;
    const data = await this.apiService.get(url, 'token');
    return data && data[token] && data[token]['usd'] ? data[token]['usd'] : 0;
  }

  getTidalCollateral (tidalUSDprice) {
    return this.poolBalance < 1000000 ? `${this.formatBalance((tidalUSDprice * this.poolBalance))} USD \ ${this.poolBalance} TIDAL` : `100,000 USD in TIDAL`;
  }

  showWithdraw(asset) {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    this.assetToShow = asset;
    this.willShowWithdraw = true;
  }

  closeWithdraw() {
    this.willShowWithdraw = false;
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }
}
