import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
  
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { guarantorWithdrawTransaction } from '../transactions.descriptions';

@Component({
  selector: 'app-guarantor-withdraw',
  templateUrl: './guarantor-withdraw.component.html',
  styleUrls: ['./guarantor-withdraw.component.css']
})
export class GuarantorWithdrawComponent implements OnInit {

  @Input() asset: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  amount: string = "";
  assetBalance: string = "";

  nextWeekWithdrawAmount: number = 0;
  nextNextWeekWithdrawAmount: number = 0;

  loading: boolean = false;

  constructor(private contractService: ContractService,
              private apiService: ApiService,
              private transactionsService: TransactionsService,
              ) { }

  ngOnInit() {
    this.loading = true;
    this.loadAsset();
    this.loading = false;
  }

  async loadAsset() {
    if (this.contractService.address) {
      this.assetBalance = await this.contractService.balanceOf(this.asset.token, this.contractService.address, this.asset.decimals);

      const all = [(async () => {
        this.nextWeekWithdrawAmount = await this.contractService.getGuarantorNextWeekWithdraw(
            this.contractService.address, this.asset.index, this.asset.decimals);
      }) (), (async () => {
        this.nextNextWeekWithdrawAmount = await this.contractService.getGuarantorNextNextWeekWithdraw(
            this.contractService.address, this.asset.index, this.asset.decimals);
      }) ()];

      await Promise.all(all);
      this.amount = (this.nextNextWeekWithdrawAmount).toString();
    }
  }

  async updateAsset() {
    this.nextNextWeekWithdrawAmount = await this.contractService.getGuarantorNextNextWeekWithdraw(
        this.contractService.address, this.asset.index, this.asset.decimals);
  }

  max() {
    this.amount = (+(this.asset.myBalance) - this.nextWeekWithdrawAmount).toFixed(2).toString();
  }

  getNumber(x) {
    return parseFloat(x);
  }

  async withdraw() {
    this.loading = true;
    try {
      this.transactionsService.updateProcessingTransaction(guarantorWithdrawTransaction.type, true);
      await this.contractService.guarantorWithdraw(this.asset.index, +this.amount, this.asset.decimals);
      await this.updateAsset();
    } catch(e) {
    }

    this.loading = false;
    this.transactionsService.updateProcessingTransaction(guarantorWithdrawTransaction.type, false);
    this.onRefresh.emit();
  }

  willDisableButton() {
    const buttonDisabled = this.loading || (this.getNumber(this.amount) !== 0 && !this.amount) ||
      (this.getNumber(this.amount) !== 0 && !this.getNumber(this.amount)) ||
      this.getNumber(this.amount) > this.getNumber(this.asset.myBalance) ||
      this.transactionsService.processingTransactions[guarantorWithdrawTransaction.type];
    return buttonDisabled;
  }

  close() {
    this.onClose.emit();
  }
}
