<div class="content row top-info top-title">
  <div class="column-6 left total-asset">
    <p>
      <strong class="title sim-title font-48-blue margin-top--20">Total asset under coverage: </strong>
      <span class="number">{{ sumOfCovered }}</span>
    </p>
  </div>
</div>
<div class="row dashbroad risk-pool padding-left-32">
  <div class="pool-box column-12">
    <div class="row">
      <div class="row pool-top">
        <div class="left pool-content column-6">
          <div class="pool-item title-row" style="margin-top: 10px"><strong>Pool</strong></div>
        </div>
        <div class="right column-6">
          <div class="icon"><img src="/assets/images/a1.png"></div>
          <div class="icon"><img src="/assets/images/a2.png"></div>
          <div class="icon"><img src="/assets/images/a3.png"></div>
          <div class="icon"><img src="/assets/images/a4.png"></div>
          <div class="icon"><img src="/assets/images/a5.png"></div>
          <div class="icon"><img src="/assets/images/a6.png"></div>
        </div>
        <div class="right pool-item"></div>
      </div>
      <div class="row pool-bottom">
        <div class="item column-6">
          <div class="item-title light-title">
            <p>Reserve Size</p>
          </div>
          <div class="item-detil">
            <strong>{{ allCategories[0] ? allCategories[0].reserve : '-' }}</strong>
          </div>
        </div>
        <div class="item column-6">
          <!-- <div class="item-title light-title">
            <p>Reserve Returns</p>
          </div>
          <div class="item-detil">
            <strong>{{ allCategories[0] ? allCategories[0].apr : '-' }} APR</strong>
          </div> -->
          <div class="column-2">
            <p style="display: flex; align-items: center;" class="title">Max APR<img (click)="showLockedDesc('maxPremium')" src="../../assets/images/info.png" style="cursor: pointer; width: 20px; height: 20px; margin-left: 4px;" /></p >
            <p class="number">{{ formatRate(getMyAPRs().maxApr) }}</p >
          </div>
        </div>
        <div class="item column-6">
          <div class="item-title light-title">
            <p>Payouts Till Today</p>
          </div>
          <div class="item-detil column-6">
            <strong>{{ allCategories[0] ? allCategories[0].payout : '-' }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="crypto-list row padding-left-32">
  <div class="crypto-list-box" style="">
    <table class="crypto-list-tab">
      <tr class="crypto-list-title">
        <td class="crypto-list-search-td">
          <p class="serach-bar" style="background-image: url('/assets/images/icon-search.png')">
            <span class="light-title">Search</span>
          </p>
        </td>
        <td><strong>Stake Earning</strong><p class="light-title">(APR)</p></td>
        <td><strong>Premium price</strong><p class="light-title">(Weekly)</p></td>
        <td><strong>Guarantor reserve</strong></td>
        <td><strong>Capital reserve</strong><p class="light-title"></p></td>
        <td><strong>Cover Sold</strong></td>
        <td><strong>Capital Utilization</strong><p class="light-title"></p></td>
        <td width="160px"></td>
      </tr>
      <tr class="crypto-list-item" *ngFor="let asset of allAssets">
        <td class="crypto-list-td-first">
          <span class="icon"><img [src]="'/assets/images/a' + (asset.index + 1) + '.png'"></span>
          <strong>{{asset.name}}</strong>
        </td>
        <td><span class="number">{{ asset.apr }}</span></td>
        <td><span class="number">{{ asset.premiumRate }}</span></td>
        <td><span class="number">{{ asset.guarantorValue }}</span></td>
        <td><span class="number">{{ asset.sellerBalance }}</span></td>
        <td><span class="number" [ngClass]="{'oversold': asset.oversold}">{{ asset.currentSubscription }}</span></td>
        <td><span class="number" [ngClass]="{'oversold': asset.oversold}">{{ asset.assetUtilization }}</span></td>
        <td>
          <a class="button btn-add-padding" href="javascript:void(0)" (click)="goToStake()">Stake</a>
          <!-- <a class="button light-button" href="javascript:void(0)" (click)="goToEnroll()">Enroll</a> -->
        </td>
      </tr>
    </table>
  </div>
</div>
<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>
