import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-buy-adjustcoverage',
  templateUrl: './buy-adjustcoverage.component.html',
  styleUrls: ['./buy-adjustcoverage.component.css']
})
export class BuyAdjustcoverageComponent implements OnInit {

  @Input() capacityAll: number;
  @Input() assetIndex: number;
  @Input() assetSymbol: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  amount: string = "";
  futureCapacityOffset: number;
  loading: boolean = false;

  constructor(private contractService: ContractService, private apiService: ApiService) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    this.loading = true;
    const retailHelperAssetInfo = await this.contractService.retailHelperAssetInfo(this.assetIndex);
    this.futureCapacityOffset = +((+retailHelperAssetInfo.futureCapacityOffset) / (10 ** environment.usdcDecimals)).toFixed(2);
    this.loading = false;
  }

  getCapacity(amount: string) {
    const amountNumber = +amount;
    if (!amount || isNaN(amountNumber)) {
      return this.capacityAll - this.futureCapacityOffset;
    }

    return Math.max(0, this.capacityAll - amountNumber);
  }

  getNumber(x) {
    return parseFloat(x);
  }

  formatTokenBalance(value) {
    const result = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async adjust() {
    this.loading = true;
    try {
      await this.contractService.retailHelperChangeCapacityOffset(this.assetIndex, +this.amount);
      await this.load();
    } catch(e) {
    }

    this.loading = false;
    this.onRefresh.emit();
  }

  willDisableButton() {
    const buttonDisabled = this.loading || !this.amount || !this.getNumber(this.amount);
    return buttonDisabled;
  }

  close() {
    this.onClose.emit();
  }
}
