var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { environment } from '../../environments/environment';
import { sellerBasketUpdatingTransaction } from '../transactions.descriptions';
var SellComponent = /** @class */ (function () {
    function SellComponent(contractService, apiService, transactionsService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.transactionsService = transactionsService;
        this.onGoTo = new EventEmitter();
        this.category = 0;
        this.allAssets = [];
        this.myAPRs = {};
        this.showAssets = [];
        this.hasNextWeek = false;
        this.nextWeekIndexes = [];
        this.recordClickWeekIndexes = [];
        this.recordClickNextWeekIndexes = [];
        this.hasNextNextWeek = false;
        this.nextNextWeekIndexes = [];
        this.tidalMined = 0;
        this.currentIndexes = [];
        this.pendingIndexes = [];
        this.hasPending = false;
        this.isEditMode = false;
        this.myCurrentBalance = "";
        this.myFutureBalance = "";
        this.nextWeekWithdrawAmount = 0;
        this.nextNextWeekWithdrawAmount = 0;
        this.deltaBalance = "0";
        this.willShowDeposit = false;
        this.willShowWithdraw = false;
        this.willShowBasket = false;
        this.willShowReminderIcon = false;
        this.willShowNextWeekNoneText = true;
        this.willShowNextNextWeekNoneText = true;
        this.basketChanged = false;
        this.isChangingBasket = false;
        this.isShowProtocolQueue = false;
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
        this.allCategories = [];
        this.durationDays = 0;
        this.durationHours = 0;
        this.newEpochStartsDays = 0;
        this.newEpochStartsHours = 0;
        this.pendingDepositAmount = 0;
        this.pendingWithdrawReserve = environment.pendingWithdrawReserve;
        this.loading = false;
        this.operationBtnClickSum = 0;
        this.isShowFloatPopup = false;
        this.currentProtoObj = {
            stakeList: [],
            unstakeList: [],
            prevStakeList: [],
            prevUnStakeList: []
        };
        this.isSetNewStakeProtocol = false;
    }
    SellComponent.prototype.ngOnInit = function () {
        this.goToCategory(0);
        this.loadPendingDeposit();
    };
    SellComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var all, all1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.contractService.init()];
                    case 1:
                        _a.sent();
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var data1;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getAllAssets()];
                                        case 1:
                                            data1 = _a.sent();
                                            this.allAssets = data1.map(function (asset) {
                                                return {
                                                    index: asset.index,
                                                    name: asset.name,
                                                    symbol: asset.symbol,
                                                    token: asset.token,
                                                    category: asset.category,
                                                    apr: asset.apr,
                                                    premiumRate: asset.premiumRate,
                                                    sellerTidalApr: asset.sellerTidalApr,
                                                    moreApr: asset.moreApr || 0,
                                                    moreAprSymbol: asset.moreAprSymbol || '',
                                                    guarantorBalance: _this.formatTokenBalance(asset.guarantorBalance),
                                                    guarantorValue: _this.formatBalance(asset.guarantorValue),
                                                    guarantorValueForProto: asset.guarantorValue,
                                                    sellerBalance: _this.formatBalance(asset.sellerBalance),
                                                    sellerBalanceNumber: asset.sellerBalance,
                                                    currentSubscription: asset.currentSubscription,
                                                    assetUtilization: _this.formatRate(asset.assetUtilization),
                                                    oversold: asset.currentSubscription > asset.sellerBalance
                                                };
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.loadSellerInfo()];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getSellerCurrentBalance(this.contractService.address, this.category)];
                                        case 1:
                                            _a.myCurrentBalance = _b.sent();
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getSellerFutureBalance(this.contractService.address, this.category)];
                                        case 1:
                                            _a.myFutureBalance = _b.sent();
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.contractService.getNextWeekBasket(this.contractService.address, this.category)];
                                        case 1:
                                            data = _a.sent();
                                            this.hasNextWeek = data.has;
                                            this.nextWeekIndexes = data.indexes.map(function (index) { return parseInt(index); });
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.contractService.getNextNextWeekBasket(this.contractService.address, this.category)];
                                        case 1:
                                            data = _a.sent();
                                            this.hasNextNextWeek = data.has;
                                            this.nextNextWeekIndexes = data.indexes.map(function (index) { return parseInt(index); });
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.contractService.getPendingBasket(this.contractService.address, this.category)];
                                        case 1:
                                            data = _a.sent();
                                            this.hasPending = data.has;
                                            this.pendingIndexes = data.indexes.map(function (index) { return parseInt(index); });
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getSellerNextWeekWithdraw(this.contractService.address, this.category)];
                                        case 1:
                                            _a.nextWeekWithdrawAmount = _b.sent();
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!this.contractService.address) return [3 /*break*/, 2];
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getSellerNextNextWeekWithdraw(this.contractService.address, this.category)];
                                        case 1:
                                            _a.nextNextWeekWithdrawAmount = _b.sent();
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_a) {
                                    if (!this.contractService.address) {
                                        return [2 /*return*/];
                                    }
                                    Promise.all([
                                        this.contractService.getNow(),
                                        this.contractService.getCurrentWeek(),
                                    ]).then(function (values) {
                                        var secs = (values[1] + 1) * 3600 * 24 * 7 - values[0];
                                        var days = secs / (3600 * 24) - 4;
                                        _this.durationDays = Math.floor(days); // 4 is the offset.
                                        _this.durationHours = Math.floor((days - _this.durationDays) * 24);
                                    });
                                    return [2 /*return*/];
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var categories;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getAllCategories()];
                                        case 1:
                                            categories = _a.sent();
                                            this.allCategories = categories.map(function (category) {
                                                return {
                                                    reserve: _this.formatTokenBalance(category.reserve),
                                                    apr: _this.formatRate(category.apr),
                                                    payout: _this.formatBalance(category.payout)
                                                };
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all)];
                    case 2:
                        _a.sent();
                        if (this.contractService.address) {
                            this.deltaBalance = (+Math.max(0, parseFloat(this.myFutureBalance) - parseFloat(this.myCurrentBalance)).toFixed(2)).toString();
                        }
                        this.showAssets = this.allAssets.filter(function (asset) { return asset.category * 1 == _this.category; });
                        if (!this.contractService.address) return [3 /*break*/, 4];
                        this.currentIndexes = [];
                        all1 = this.showAssets.map(function (asset, i) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.contractService.sellerUserBasket(this.contractService.address, asset.index)];
                                    case 1:
                                        if (_a.sent()) {
                                            this.currentIndexes.push(asset.index);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(all1)];
                    case 3:
                        _a.sent();
                        this.myAPRs = this.getMyAPRs();
                        if (!this.hasNextWeek) {
                            this.nextWeekIndexes = this.currentIndexes.slice();
                        }
                        if (!this.hasNextNextWeek) {
                            this.nextNextWeekIndexes = this.currentIndexes.slice();
                        }
                        if (!this.hasPending) {
                            this.pendingIndexes = this.currentIndexes.slice();
                        }
                        this.setCurrentProtoList();
                        _a.label = 4;
                    case 4:
                        this.loading = false;
                        this.willShowNextWeekNoneText = this.willShowNoneText(this.nextWeekIndexes, this.showAssets);
                        this.willShowNextNextWeekNoneText = this.willShowNoneText(this.nextNextWeekIndexes, this.showAssets);
                        this.willShowReminderIcon = (this.willShowNextWeekNoneText && this.willShowNextNextWeekNoneText && this.willShowNoneText(this.currentIndexes, this.showAssets));
                        this.setNewStakeProtocol();
                        return [2 /*return*/];
                }
            });
        });
    };
    SellComponent.prototype.loadPendingDeposit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getPendingDepositAmount()];
                    case 1:
                        data = _a.sent();
                        this.pendingDepositAmount = data.pendingBalance / (Math.pow(10, environment.usdcDecimals));
                        return [2 /*return*/];
                }
            });
        });
    };
    SellComponent.prototype.setNewStakeProtocol = function () {
        if (this.currentIndexes.length === this.nextNextWeekIndexes.length && this.currentIndexes.length === this.nextWeekIndexes.length &&
            this.currentIndexes.length === 0) {
            this.isSetNewStakeProtocol = true;
        }
    };
    SellComponent.prototype.setCurrentProtoList = function () {
        var _this = this;
        this.showAssets.forEach(function (asset) {
            if (_this.pendingIndexes.indexOf(asset.index) >= 0) {
                _this.currentProtoObj.stakeList.push(asset);
            }
            else {
                _this.currentProtoObj.unstakeList.push(asset);
            }
        });
    };
    SellComponent.prototype.willShowNoneText = function (indexes, assets) {
        for (var _i = 0, assets_1 = assets; _i < assets_1.length; _i++) {
            var asset = assets_1[_i];
            if (indexes.indexOf(asset.index) >= 0) {
                return false;
            }
        }
        return true;
    };
    SellComponent.prototype.changeBasket = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isChangingBasket = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.sellerChangeBasket(this.category, this.currentIndexes)];
                    case 2:
                        _a.sent();
                        if (parseFloat(this.myCurrentBalance)) {
                            this.showAlert('Basket change saved', 'Please wait 1 to 2 weeks for the change to take effective');
                        }
                        else {
                            this.showAlert('Basket change saved', 'Now it\'s effective');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.isChangingBasket = false;
                        this.refresh();
                        return [2 /*return*/];
                }
            });
        });
    };
    SellComponent.prototype.refresh = function () {
        this.load();
    };
    SellComponent.prototype.loadSellerInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sellerInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contractService.getUserSellerInfo(this.contractService.address)];
                    case 1:
                        sellerInfo = _a.sent();
                        this.tidalMined = +(+sellerInfo[2] / (Math.pow(10, environment.tidalDecimals))).toFixed(2);
                        return [2 /*return*/];
                }
            });
        });
    };
    SellComponent.prototype.formatBalance = function (value) {
        var result = '$' + (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    SellComponent.prototype.formatTokenBalance = function (value) {
        if (!value || value.toString() == '0')
            return '0';
        var result = (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    SellComponent.prototype.formatRate = function (value) {
        if (isNaN(value)) {
            return 'N/A';
        }
        return (value / 10000).toFixed(2) + '%';
    };
    SellComponent.prototype.formatSymbol = function (value) {
        if (!value)
            return '';
        return value.toUpperCase();
    };
    SellComponent.prototype.goToCategory = function (category) {
        this.category = category;
        this.load();
    };
    SellComponent.prototype.goToPortfolio = function () {
        this.onGoTo.emit({ key: 'portfolio' });
    };
    SellComponent.prototype.showDeposit = function () {
        if (!this.contractService.address) {
            this.showAlert('Please connect to MetaMask', '');
            return;
        }
        this.willShowDeposit = true;
    };
    SellComponent.prototype.closeDeposit = function () {
        this.willShowDeposit = false;
    };
    SellComponent.prototype.showWithdraw = function () {
        if (!this.contractService.address) {
            this.showAlert('Please connect to MetaMask', '');
            return;
        }
        this.willShowWithdraw = true;
    };
    SellComponent.prototype.closeWithdraw = function () {
        this.willShowWithdraw = false;
    };
    SellComponent.prototype.showBasket = function () {
        if (!this.contractService.address) {
            this.showAlert('Please connect to MetaMask', '');
            return;
        }
        this.willShowBasket = true;
    };
    SellComponent.prototype.showLockedDesc = function (type) {
        if (type === 'next') {
            this.showAlert('Queued Update(Locked)', 'You have a pending queued update to become effective in the next week.');
        }
        else if (type === 'nextNext') {
            this.showAlert('New Update', 'New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.');
        }
        else if (type === 'current') {
            return;
            // this.showAlert("New Update", "New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.");
        }
        else if (type === 'myPremium') {
            this.showAlert('My APR', "USDC APR: " + this.formatRate(this.myAPRs.premiumApr) + "\n      \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\n      TIDAL Reward APR: " + this.formatRate(this.myAPRs.tidalApr));
        }
        else if (type === 'maxPremium') {
            this.showAlert('Max APR', "USDC APR: " + this.formatRate(this.myAPRs.maxPremiumApr) + "\n      \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\n      TIDAL Reward APR: " + this.formatRate(this.myAPRs.maxTidalApr));
        }
        else if (type === 'estimatedApr') {
            this.showAlert('Estimated APR', 'Estimated APR is next week\'s estimated APR, calculated based on the current pending deposits.');
        }
        return;
    };
    SellComponent.prototype.showPendingActions = function () {
        this.showAlert('Pending Status', "Pending Deposit:\n      " + this.formatTokenBalance(this.deltaBalance) + " USDC\n      \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\n      Pending Withdraw:\n      " + this.formatTokenBalance(this.nextWeekWithdrawAmount + this.nextNextWeekWithdrawAmount) + " USDC");
    };
    SellComponent.prototype.closeBasket = function () {
        this.willShowBasket = false;
    };
    SellComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    SellComponent.prototype.toggleShowFloatPopup = function ($event, status) {
        var floatItem = $event.target.parentNode.parentNode.querySelector('.float-item');
        this.adjustFloatItemPos(floatItem, status);
    };
    SellComponent.prototype.isDetailCanClick = function (asset) {
        var protoAsset = environment.protocolList.find(function (proto) { return proto.key == asset.name; });
        return protoAsset;
    };
    SellComponent.prototype.showProtocolsInfoPopup = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            var tidalUSDprice;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isDetailCanClick(asset)) {
                            return [2 /*return*/];
                        }
                        this.willShowProtocolPopup = true;
                        this.protocolInfoObj = asset;
                        this.protocolInfoObj = Object.assign(asset, {
                            avatar: '/assets/images/a' + (asset.index + 1) + '.png',
                            title: asset.name
                        }, this.isDetailCanClick(asset));
                        return [4 /*yield*/, this.getTokenPrice('tidal-finance')];
                    case 1:
                        tidalUSDprice = _a.sent();
                        // tslint:disable-next-line:radix
                        this.protocolInfoObj.chains = this.protocolInfoObj.chains.join(',');
                        this.protocolInfoObj.guarantorCoverTvl = "" + this.formatRate(+asset.guarantorValueForProto / asset.currentSubscription);
                        this.protocolInfoObj.guarantorCollateralExpression = +asset.guarantorValueForProto.toFixed(2) + "  /  " + asset.guarantorBalance + " (" + asset.symbol + ")";
                        this.protocolInfoObj.tidalCollateralExpression = this.getTidalCollateral(tidalUSDprice);
                        this.protocolInfoObj.premiumPricing = this.formatRate(asset.premiumRate) + ' Weekly';
                        return [2 /*return*/];
                }
            });
        });
    };
    SellComponent.prototype.getTokenPrice = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            var url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "https://api.coingecko.com/api/v3/simple/price?ids=" + token + "&vs_currencies=usd";
                        return [4 /*yield*/, this.apiService.get(url, 'token')];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data && data[token] && data[token]['usd'] ? data[token]['usd'] : 0];
                }
            });
        });
    };
    SellComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    SellComponent.prototype.closeProtoStatus = function () {
        this.isShowProtocolQueue = false;
    };
    SellComponent.prototype.closeProtocolPopup = function () {
        this.willShowProtocolPopup = false;
    };
    SellComponent.prototype.getNumber = function (x) {
        return parseFloat(x);
    };
    SellComponent.prototype.getEstimatedApr = function () {
        if (this.allCategories.length === 0)
            return 0;
        var currentReserve = parseFloat(this.allCategories[0].reserve.split(',').join(''));
        var estimatedBalanceForSeller = currentReserve +
            this.pendingDepositAmount - this.pendingWithdrawReserve;
        return this.getMyAPRs().maxApr / estimatedBalanceForSeller * currentReserve;
    };
    SellComponent.prototype.toggleEditStakeProto = function () {
        this.isEditMode = !this.isEditMode;
        this.operationBtnClickSum++;
        if (this.operationBtnClickSum === 1) {
            this.recordClickNextWeekIndexes = this.nextNextWeekIndexes.concat();
        }
    };
    SellComponent.prototype.toggleProtoStatus = function (asset, operation) {
        var _this = this;
        if (operation === 'stake') {
            this.nextNextWeekIndexes.push(asset.index);
        }
        else {
            this.nextNextWeekIndexes.forEach(function (item, index) {
                if (item === asset.index)
                    _this.nextNextWeekIndexes.splice(index, 1);
            });
        }
    };
    SellComponent.prototype.resetProtoStatusList = function () {
        if (this.operationBtnClickSum > 0) {
            this.operationBtnClickSum = 0;
            this.nextNextWeekIndexes = this.recordClickNextWeekIndexes;
        }
        this.isEditMode = false;
    };
    SellComponent.prototype.getTidalCollateral = function (tidalUSDprice) {
        return this.poolBalance < 1000000 ? this.formatBalance((tidalUSDprice * this.poolBalance)) + " USD  " + this.poolBalance + " TIDAL" : "100,000 USD in TIDAL";
    };
    SellComponent.prototype.getMyAPRs = function () {
        var myApr = 0;
        var premiumApr = 0;
        var tidalApr = 0;
        var maxApr = 0;
        var maxPremiumApr = 0;
        var maxTidalApr = 0;
        for (var i = 0; i < this.currentIndexes.length; i++) {
            var premiumAprNumber = this.allAssets[this.currentIndexes[i]].apr;
            var tidalAprNumber = this.allAssets[this.currentIndexes[i]].sellerTidalApr;
            premiumApr += premiumAprNumber;
            tidalApr += tidalAprNumber;
        }
        myApr = premiumApr + tidalApr;
        if (parseFloat(this.myCurrentBalance) == 0) {
            myApr = 0;
        }
        for (var i = 0; i < this.showAssets.length; i++) {
            var premiumAprNumber = this.allAssets[i].apr;
            var tidalAprNumber = this.allAssets[i].sellerTidalApr;
            maxPremiumApr += premiumAprNumber;
            maxTidalApr += tidalAprNumber;
        }
        maxApr = maxPremiumApr + maxTidalApr;
        return {
            premiumApr: premiumApr,
            tidalApr: tidalApr,
            myApr: myApr,
            maxApr: maxApr,
            maxPremiumApr: maxPremiumApr,
            maxTidalApr: maxTidalApr
        };
    };
    SellComponent.prototype.updateProtoStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.transactionsService.updateProcessingTransaction(sellerBasketUpdatingTransaction.type, true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.sellerChangeBasket(this.category, this.nextNextWeekIndexes)];
                    case 2:
                        _a.sent();
                        if (parseFloat(this.myCurrentBalance)) {
                            this.showAlert("Change saved", "");
                        }
                        else {
                            this.showAlert("Change saved", "");
                        }
                        this.isUpdateProtoComplete = true;
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.currentProtoObj.stakeList = [];
                        this.currentProtoObj.unstakeList = [];
                        this.transactionsService.updateProcessingTransaction(sellerBasketUpdatingTransaction.type, false);
                        this.isEditMode = false;
                        return [4 /*yield*/, this.load()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SellComponent.prototype.isComfirm = function () {
        return this.transactionsService.processingTransactions[sellerBasketUpdatingTransaction.type];
    };
    SellComponent.prototype.adjustFloatItemPos = function (elem, status) {
        function getPosition(element) {
            var xPosition = 0;
            var yPosition = 0;
            while (element) {
                xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
                yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
                element = element.offsetParent;
            }
            return { x: xPosition, y: yPosition };
        }
        if ((window.innerWidth - +getPosition(elem).x) < 256) {
            elem.style.right = '0%';
            elem.style.top = '30%';
        }
        elem.style.visibility = status;
    };
    return SellComponent;
}());
export { SellComponent };
