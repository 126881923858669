var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
var MarketComponent = /** @class */ (function () {
    function MarketComponent(apiService) {
        this.apiService = apiService;
        this.onGoTo = new EventEmitter();
        this.allCategories = [];
        this.allAssets = [];
        this.sumOfCovered = "";
        this.showAssets = [];
        this.category = '0';
        this.myAPRs = {};
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
    }
    MarketComponent.prototype.ngOnInit = function () {
        this.load();
    };
    MarketComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var data0;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getAllCategories()];
                                        case 1:
                                            data0 = _a.sent();
                                            this.allCategories = data0.map(function (category) {
                                                return {
                                                    reserve: _this.formatBalance(category.reserve),
                                                    apr: _this.formatRate(category.apr),
                                                    payout: _this.formatBalance(category.payout)
                                                };
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var data1, sumOfCovered;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getAllAssets()];
                                        case 1:
                                            data1 = _a.sent();
                                            sumOfCovered = 0;
                                            this.allAssets = data1.map(function (asset) {
                                                sumOfCovered += parseFloat(asset.currentSubscription);
                                                return {
                                                    name: asset.name,
                                                    index: asset.index,
                                                    sellerTidalApr: asset.sellerTidalApr,
                                                    apruse4Max: asset.apr,
                                                    apr: _this.formatRate(asset.apr),
                                                    premiumRate: _this.formatRate(asset.premiumRate),
                                                    guarantorValue: _this.formatBalance(asset.guarantorValue),
                                                    sellerBalance: _this.formatBalance(asset.sellerBalance),
                                                    currentSubscription: _this.formatBalance(asset.currentSubscription),
                                                    assetUtilization: _this.formatRate(asset.assetUtilization),
                                                    oversold: asset.currentSubscription > asset.sellerBalance
                                                };
                                            });
                                            this.sumOfCovered = this.formatBalance(sumOfCovered);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.showAssets = this.allAssets;
                        return [2 /*return*/];
                }
            });
        });
    };
    MarketComponent.prototype.getMyAPRs = function () {
        var maxApr = 0;
        var maxPremiumApr = 0;
        var maxTidalApr = 0;
        for (var i = 0; i < this.showAssets.length; i++) {
            var premiumAprNumber = this.allAssets[i].apruse4Max;
            var tidalAprNumber = this.allAssets[i].sellerTidalApr;
            maxPremiumApr += premiumAprNumber;
            maxTidalApr += tidalAprNumber;
        }
        maxApr = maxPremiumApr + maxTidalApr;
        this.myAPRs.maxPremiumApr = maxTidalApr;
        this.myAPRs.premiumApr = maxPremiumApr;
        return {
            maxApr: maxApr,
            maxPremiumApr: maxPremiumApr,
            maxTidalApr: maxTidalApr,
        };
    };
    MarketComponent.prototype.formatBalance = function (value) {
        var result = '$' + (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    MarketComponent.prototype.formatRate = function (value) {
        if (isNaN(value)) {
            return 'N/A';
        }
        return (value / 10000).toFixed(2) + '%';
    };
    MarketComponent.prototype.goToPortfolio = function () {
        this.onGoTo.emit({ key: 'portfolio' });
    };
    MarketComponent.prototype.goToStake = function () {
        this.onGoTo.emit({ key: 'sell' });
    };
    MarketComponent.prototype.goToEnroll = function () {
        this.onGoTo.emit({ key: 'buy' });
    };
    MarketComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    MarketComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    MarketComponent.prototype.showLockedDesc = function (type) {
        if (type === 'next') {
            this.showAlert("Queued Update(Locked)", "You have a pending queued update to become effective in the next week.");
        }
        else if (type === 'nextNext') {
            this.showAlert("New Update", "New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.");
        }
        else if (type === 'current') {
            return;
            // this.showAlert("New Update", "New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.");
        }
        else if (type === 'myPremium') {
            this.showAlert("My APR", "Premium APR: " + this.formatRate(this.myAPRs.premiumApr) + "\n      \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\n      TIDAL Reward APR: " + this.formatRate(this.myAPRs.tidalApr));
        }
        else if (type === 'maxPremium') {
            this.showAlert("Max APR", "Premium APR: " + this.formatRate(this.myAPRs.maxPremiumApr) + "\n      \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\n      TIDAL Reward APR: " + this.formatRate(this.myAPRs.maxTidalApr));
        }
        return;
    };
    return MarketComponent;
}());
export { MarketComponent };
