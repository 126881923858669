import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css']
})
export class ClaimsComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  payoutRequests = [];
  filteredRequests = [];
  key = "all";

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;

  constructor(private contractService: ContractService, private apiService: ApiService) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    this.payoutRequests = await this.apiService.getPayoutRequests();
    this.payoutRequests.forEach(r => {
      r.status = this.getStatus(r);
    });

    this.showRequests(this.key);
  }

  refresh() {
    this.load();
  }

  showRequests(key) {
    if (key == "all") {
      this.filteredRequests = this.payoutRequests;
    } else if (key == "pending") {
      this.filteredRequests = this.payoutRequests.filter(r => r.status == "Pending");
    } else if (key == "accepted") {
      this.filteredRequests = this.payoutRequests.filter(r => r.status == "Accepted");
    } else if (key == "expired") {
      this.filteredRequests = this.payoutRequests.filter(r => r.status == "Expired");
    }

    this.key = key;
  }

  formatTime(time) {
    const date = new Date(time * 1000);
    return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
  }

  getAbbr(line) {
    const len = line.length;
    if (len < 10) return len;
    return line.substr(0, 6) + '...' + line.substr(len - 4, len);
  }

  getStatus(request) {
    if (request.executed) return "Accepted";  // Waiting for payment.

    const now = Math.floor(new Date().getTime() / 1000);
    if (now - request.time > 3 * 3600 * 24) {
      return "Expired";
    } else {
      return "Pending";
    }
  }

  goToPortfolio() {
    this.onGoTo.emit({key: 'portfolio'});
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }
}
