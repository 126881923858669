<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">Buy cover</h2>
  </div>
</div>

<div class="protocol-container padding-left-32 padding-right-32" style="margin-top: 100px;display: flex;flex-wrap: wrap;">
<ng-container *ngFor="let asset of showAssets" >

  <div class="protocol-item" *ngIf="retailArray.indexOf(asset.index) >= 0">
    <p>
      <span style="cursor: default;"
        >
        <img src="/assets/images/a{{ asset.index + 1 }}.png" style="width: 40px;height: 40px;">
        <span style="font-size: 15px;">{{ asset.symbol }}</span>
      </span>
      <span class="text-green text-right text-apr-loc" style="cursor: pointer;font-size: 24px;" >
        {{ formatRate(asset.premiumRate) }}
      </span>
    </p>
    <p>
      <span>Capacity </span>
      <span>{{ asset.sellerBalance }}</span>
    </p>
    <p>
      <span>Capital Utilization</span>
      <span>{{ asset.assetUtilization }}</span>
    </p>
    <a [href]="getProtocolLink(asset.symbol)" target="_blank"><button class="getCover">Get Cover</button></a>
  </div>
</ng-container>
</div>
