import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { stakingDepositTransaction } from '../transactions.descriptions';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-stake',
  templateUrl: './stake.component.html',
  styleUrls: ['./stake.component.css']
})
export class StakeComponent implements OnInit {

  @Input() category: number;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  amount: string = "";
  tidalBalance: number = 0;

  needApproval: boolean = true;
  loading: boolean = false;

  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;

  constructor(private contractService: ContractService,
              private apiService: ApiService,
              private transactionService: TransactionsService,
              ) { }

  ngOnInit() {
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  async loadData() {
    if (!this.contractService.address) {
      return;
    }

    this.loading = true;

    const all = [(async () => {
      this.tidalBalance = +(await this.contractService.balanceOf(environment.tidalAddress, this.contractService.address, environment.tidalDecimals));
    }) ()];

    await Promise.all(all);

    this.loading = false;
  }

  async stake() {
    if (!this.contractService.address) {
      this.showAlert("Please connect to MetaMask", "");
      return;
    }

    const amountNumber = this.getNumber(this.amount);
    if (!amountNumber) {
      return;
    }

    this.transactionService.updateProcessingTransaction(stakingDepositTransaction.type, true);

    try {
      await this.contractService.stakingDeposit(amountNumber);
      this.showAlert("Thanks for staking", "Your staked balance will be updated soon.");
      this.amount = "";
      this.loadData();
      this.onRefresh.emit();
    } catch (e) {
    }

    this.transactionService.updateProcessingTransaction(stakingDepositTransaction.type, false);
  }

  max() {
    this.amount = this.tidalBalance.toString();
  }

  getNumber(x) {
    return parseFloat(x);
  }

  close() {
    this.onClose.emit();
  }

  isStaking () {
    return this.transactionService.processingTransactions[stakingDepositTransaction.type];
  }

  willDisableButton() {
    const buttonDisabled = (this.getNumber(this.amount) !== 0 && !this.amount) ||
        (this.getNumber(this.amount) !== 0 && !this.getNumber(this.amount)) ||
        this.getNumber(this.amount) > this.getNumber(this.tidalBalance);
    return buttonDisabled || this.isStaking();
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  formatTokenBalance(value) {
    const result = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
