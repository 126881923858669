var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
var CoverStatusComponent = /** @class */ (function () {
    function CoverStatusComponent(contractService, apiService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.onGoTo = new EventEmitter();
        this.allAssets = [];
        this.subscribeType = "";
        this.predepositBalance = "";
        this.weeksBeCovered = "";
        this.sumOfPremium = 0;
        this.assetSymbol = "";
        this.sumOfCovered = "";
        this.currentWeek = 0;
        this.userBuyerInfo = {};
        this.selectedAssetIndex = 0;
        this.seletedPremiumRate = 0;
        this.hasAsset = false;
        this.assetIndex = -1;
    }
    CoverStatusComponent.prototype.ngOnInit = function () {
        this.load();
    };
    CoverStatusComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var assetIndexPlusOne, localAllCategories, localSumOfCovered, all0, all1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        assetIndexPlusOne = 0;
                        if (!this.contractService.address) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contractService.buyerAssetIndexPlusOne(this.contractService.address)];
                    case 1:
                        assetIndexPlusOne = _a.sent();
                        _a.label = 2;
                    case 2:
                        this.hasAsset = assetIndexPlusOne > 0;
                        this.assetIndex = assetIndexPlusOne - 1;
                        localAllCategories = [];
                        localSumOfCovered = 0;
                        all0 = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var categories;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getAllCategories()];
                                        case 1:
                                            categories = _a.sent();
                                            localAllCategories = categories.map(function (category) {
                                                return {
                                                    reserve: (category.reserve),
                                                    apr: _this.formatRate(category.apr),
                                                };
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getAllAssets()];
                                        case 1:
                                            data = _a.sent();
                                            this.allAssets = data.map(function (asset) {
                                                localSumOfCovered += _this.getMinValue(asset.currentSubscription, asset.sellerBalance);
                                                return {
                                                    isProtocolNeedMoreReserve: asset.sellerBalance < asset.currentSubscription,
                                                    name: asset.name,
                                                    index: asset.index,
                                                    _premiumRate: asset.premiumRate,
                                                    premiumRate: _this.formatRate(asset.premiumRate),
                                                    sellerBalance: _this.formatBalance(asset.sellerBalance),
                                                    currentSubscription: _this.formatBalance(asset.currentSubscription),
                                                    loading: true,
                                                    assetSymbol: asset.symbol
                                                };
                                            });
                                            this.sumOfCovered = this.formatBalance(localSumOfCovered);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!(this.contractService.address && this.hasAsset)) return [3 /*break*/, 2];
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getPredepositBalance(this.contractService.address)];
                                        case 1:
                                            _a.predepositBalance = _b.sent();
                                            _b.label = 2;
                                        case 2:
                                            ;
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!(this.contractService.address && this.hasAsset)) return [3 /*break*/, 2];
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getCurrentWeek()];
                                        case 1:
                                            _a.currentWeek = _b.sent();
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!(this.contractService.address && this.hasAsset)) return [3 /*break*/, 2];
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getUserBuyerInfo(this.contractService.address)];
                                        case 1:
                                            _a.userBuyerInfo = _b.sent();
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all0)];
                    case 3:
                        _a.sent();
                        this.capitalEfficiency = ((localSumOfCovered / localAllCategories[0].reserve) * 100).toFixed(2) + '%';
                        if (!(this.contractService.address && this.hasAsset)) return [3 /*break*/, 5];
                        this.sumOfPremium = 0;
                        all1 = this.allAssets.map(function (asset, index) { return __awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, Promise.all([(function () { return __awaiter(_this, void 0, void 0, function () {
                                                var currentCoveredAmount, myCurrentPremium;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, this.contractService.getCurrentSubscription(asset.index)];
                                                        case 1:
                                                            currentCoveredAmount = _a.sent();
                                                            this.allAssets[index].myCurrentCoveredAmount = this.formatBalance(currentCoveredAmount);
                                                            myCurrentPremium = parseFloat(currentCoveredAmount) * asset._premiumRate / 1e6;
                                                            this.allAssets[index].myCurrentPremium = this.formatBalance(myCurrentPremium);
                                                            this.sumOfPremium += myCurrentPremium;
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                                var futureCoveredAmount, myFuturePremium;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, this.contractService.getFutureSubscription(asset.index)];
                                                        case 1:
                                                            futureCoveredAmount = _a.sent();
                                                            this.allAssets[index].myFutureCoveredAmount = this.formatBalance(futureCoveredAmount);
                                                            myFuturePremium = parseFloat(futureCoveredAmount) * asset._premiumRate / 1e6;
                                                            this.allAssets[index].myFuturePremium = this.formatBalance(myFuturePremium);
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })()])];
                                    case 1:
                                        _a.sent();
                                        this.allAssets[index].loading = false;
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(all1)];
                    case 4:
                        _a.sent();
                        this.weeksBeCovered = this.sumOfPremium ? Math.floor(parseFloat(this.predepositBalance) / this.sumOfPremium).toString() : "0";
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CoverStatusComponent.prototype.refresh = function () {
        this.load();
    };
    CoverStatusComponent.prototype.formatBalance = function (value) {
        var result = '$' + (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    CoverStatusComponent.prototype.formatUSDCBalance = function (value) {
        var result = (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    CoverStatusComponent.prototype.formatRate = function (value) {
        if (isNaN(value)) {
            return 'N/A';
        }
        return (value / 10000).toFixed(2) + '%';
    };
    CoverStatusComponent.prototype.goToPortfolio = function () {
        this.onGoTo.emit({ key: 'portfolio' });
    };
    CoverStatusComponent.prototype.getMinValue = function (a, b) {
        return Math.min(a, b);
    };
    CoverStatusComponent.prototype.toggleShowFloatPopup = function ($event, status) {
        var floatItem = $event.target.parentNode.querySelector('.float-item');
        floatItem.style.visibility = status;
    };
    return CoverStatusComponent;
}());
export { CoverStatusComponent };
