<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">TIDAL Staking</h2>
    <p class="des font-16-blue">Staking rewards starts at block 40679000 and ends at block 41819000.</p>
  </div>
</div>
<div class="mobile-flex-column row padding-left-32 padding-right-32 sec-staking-info" style="display: flex;margin-top: 32px;">
  <div style="display: flex;
  justify-content: center;
  align-items: center;" class="staking-bg" id="stakingAprBox">
    <div class="san-row flex-column" style="margin-top: 0;">
      <div class="pes-box">
        <p class="title" style="font-weight: 500;
        font-size: 14px;
        color: #666666;">APR</p>
        <p class="title " style="font-weight: bold;
        margin: 5px 0;
        font-size: 40px;
        "><strong class="pes mobile-fontsize-24" style="color: #2BAE6F;">{{apr}}%</strong></p>
      </div>
      <ng-container *ngIf="!needApproval">
        <div>
          <p style="display: flex;">
            <button class="button" style="width: 88px;font-weight: bold;
            height: 38px;" [disabled]="loading" (click)="showStake()">Stake <span class="icon-spinner" *ngIf="loading"></span></button>
            <button class="button" style="width: 88px;background: #FFFFFF;
            color: #002F9B;
            border: 1px solid #002F9B;
            margin-left: 20px;
            font-weight: bold;
            height: 38px;" [disabled]="loading" (click)="showUnstake()">Unstake<span class="icon-spinner" *ngIf="loading"></span></button>
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="needApproval">
        <div>
          <p>
            <button class="button font-weight-700" [disabled]="isApproving()" (click)="approve()">Approve TIDAL <span class="icon-spinner" *ngIf="isApproving() || loading"></span></button>
          </p>
        </div>
      </ng-container>
    </div>
  </div>
  <div style="width: inherit;margin-top: 0;margin-left: 32px;display: flex;align-items: center;"
  class="staking-bg first-row mobile-padding-15 mobile-stakingnumber-right">
    <div id="stakingNumberBox" style="margin-left: 1.5%;">
      <div>
        <div style="margin-bottom: 30px;">
          <p class="title">Pool Balance</p>
          <p *ngIf="!loading"><strong>{{formatBalance(poolBalance)}} </strong>
            <span style="color: #002F9B;font-weight:bold;">TIDAL</span>
          </p>
          <p *ngIf="loading"><span class="icon-spinner"></span></p>
        </div>
        <div>
          <p class="title">Staked in Pool</p>
          <p *ngIf="!loading"><strong>{{formatBalance(stakedBalance)}} </strong>
            <span style="color: #002F9B;font-weight:bold;">TIDAL</span>
          </p>
          <p *ngIf="loading"><span class="icon-spinner"></span></p>
        </div>
      </div>
      <div>
        <div style="margin-bottom: 30px;">
          <p class="title">Wallet Balance</p>
          <p *ngIf="!loading"><strong>{{formatBalance(walletBalance)}} </strong>
            <span style="color: #002F9B;font-weight:bold;">TIDAL</span>
          </p>
          <p *ngIf="loading"><span class="icon-spinner"></span></p>
        </div>
        <div>
          <p class="title">Earned Bonus</p>
          <p *ngIf="!loading"><strong>{{formatBalance(earnedBalance)}} </strong>
            <span style="color: #002F9B;font-weight:bold;">TIDAL</span>
          </p>
          <p *ngIf="loading"><span class="icon-spinner"></span></p>
        </div>
      </div>
    </div>

    <div id="staking-claim" style="display: flex;
    padding: 0;
    height: 71%;
    flex-direction: column-reverse;
    vertical-align: unset;">
      <p>
        <button style="width: 88px;
        height: 38px;font-weight: bold;" class="button" [disabled]="isClaimingButtonDisabled()" (click)="claim()">
          Claim
          <span class="icon-spinner" *ngIf="isClaimingButtonDisabled()"></span>
        </button>
      </p>
    </div>
  </div>
</div>


<div class="crypto-list row padding-left-32 padding-right-32 mobile-witdh-100">
  <div class="crypto-list-box" style="background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 47, 155, 0.1);
  border-radius: 20px;">
    <table class="crypto-list-tab" style="width: 100%">
      <tr class="crypto-list-title">
        <td><strong>Withdraw amount</strong></td>
        <td><strong>Withdraw status</strong></td>
      </tr>
      <tr class="crypto-list-item" *ngFor="let request of withdrawRequests">
        <td><span class="number">{{ request.amount }} TIDAL</span></td>
        <td><span class="number">{{ request.status }}</span></td>
      </tr>
    </table>
  </div>
</div>

<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>

<app-stake
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowStake"
  [category]=category
  (onClose)="closeStake()"
  (onRefresh)="refresh()">
</app-stake>

<app-unstake
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowUnstake"
  [category]=category
  [withdrawSumAmount]=withdrawSumAmount
  (onClose)="closeUnstake()"
  (onRefresh)="refresh()">
</app-unstake>
