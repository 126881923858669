var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
var GovernanceComponent = /** @class */ (function () {
    function GovernanceComponent(contractService) {
        this.contractService = contractService;
        this.delegating = false;
        this.casting = false;
        this.loading = false;
        this.proposalId = 3;
        this.myVotes = 0;
        this.proposal = {};
    }
    GovernanceComponent.prototype.ngOnInit = function () {
        this.load();
    };
    GovernanceComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.governanceGetCurrentVotes(this.contractService.address)];
                                        case 1:
                                            _a.myVotes = +((+(_b.sent())) / 1e18).toFixed(12);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.governanceGetProposal(this.proposalId)];
                                        case 1:
                                            _a.proposal = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        this.loading = true;
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    GovernanceComponent.prototype.formatVotes = function (amount) {
        return +(amount / 1e18).toFixed(12);
    };
    GovernanceComponent.prototype.delegateToMe = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        this.delegating = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.governanceDelegate(this.contractService.address)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.load()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.delegating = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    GovernanceComponent.prototype.delegateToDev = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        this.delegating = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.governanceDelegate("0x5c99f63937FA74205dea9b308e9A8e16F66d26CC")];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.load()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.delegating = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    GovernanceComponent.prototype.castVote = function (willSupport) {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        this.casting = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.contractService.governanceVote(this.proposalId, willSupport ? 1 : 0)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.load()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.casting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return GovernanceComponent;
}());
export { GovernanceComponent };
