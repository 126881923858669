<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">Cover Status</h2>
    <p class="font-16-blue">
      <strong class="title sim-title font-16-blue">Total asset under coverage</strong>
    </p>
  </div>
</div>
<div class="crypto-list row padding-left-32 padding-right-32">
  <div  id="coverStatusNumberBox">
    <div>
      <p>Total Assets Under Coverage</p>
      <div class="size-box" style="max-width: 200px;text-align: center;font-weight: bold;">
        {{ sumOfCovered }}
      </div>
    </div>
    <div>
      <p>Capital Efficiency</p>
      <div class="size-box" style="max-width: 200px;text-align: center;font-weight: bold;">
        {{ capitalEfficiency }}
      </div>    </div>
  </div>
  <div class="crypto-list-box mobile-maxwidth-scroll" style="">
    <table class="crypto-list-tab" style="width: 100%">
      <tr class="crypto-list-title">
        <td class="crypto-list-search-td">
        </td>
        <td><strong>Premium Fee</strong><p class="light-title">(weekly)</p></td>
        <td><strong>USDC Reserve</strong></td>
        <td><strong>Covered TVL</strong><p class="light-title">(target)</p></td>
      </tr>
      <ng-container  *ngFor="let asset of allAssets">
        <tr class="crypto-list-item" >
          <td class="crypto-list-td-first">
            <span class="icon"><img [src]="'/assets/images/a' + (asset.index + 1) + '.png'"></span>
            <strong>{{ asset.name }}</strong>
          </td>
          <td><span class="number">{{ asset.premiumRate }}</span></td>
          <td><span class="number" style="display: flex;
            justify-content: center;
            position: relative;
            align-items: center;">
            <span style="margin-top: 3px;">{{ asset.sellerBalance }}</span>
            <i
            *ngIf="!asset.isProtocolNeedMoreReserve" src="/assets/images/yellow-icon.svg" style="cursor: pointer; width: 20px; height: 20px; margin-left: 8px;visibility: hidden;"></i>
            <img (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')"
             *ngIf="asset.isProtocolNeedMoreReserve" src="/assets/images/yellow-icon.svg" style="cursor: pointer; width: 20px; height: 20px; margin-left: 8px;">
            <div class="float-item">
              <p>
                <span>Need more reserve</span>
              </p>
            </div>
          </span></td>
          <td><span class="number">{{ asset.currentSubscription }}</span></td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
