/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-a-claim.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../alert-message/alert-message.component.ngfactory";
import * as i4 from "../alert-message/alert-message.component";
import * as i5 from "./file-a-claim.component";
import * as i6 from "../contract.service";
import * as i7 from "../api.service";
var styles_FileAClaimComponent = [i0.styles];
var RenderType_FileAClaimComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileAClaimComponent, data: {} });
export { RenderType_FileAClaimComponent as RenderType_FileAClaimComponent };
function View_FileAClaimComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [["class", "asset-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAsset(_v.context.$implicit.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "asset-box"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.index == _co.assetIndex)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "/assets/images/a", (_v.context.$implicit.index + 1), ".png"); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.symbol; _ck(_v, 6, 0, currVal_3); }); }
function View_FileAClaimComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "icon-spinner"]], null, null, null, null, null))], null, null); }
function View_FileAClaimComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-message", [["style", "height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"]], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertMessageComponent_0, i3.RenderType_AlertMessageComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlertMessageComponent, [], { title: [0, "title"], body: [1, "body"] }, { onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertTitle; var currVal_1 = _co.alertBody; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FileAClaimComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "popup-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "popup-window"], ["style", "text-align: center; background-color: #dfffff; max-width: 380px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "popup-title title"], ["style", "font-size: 18px; padding-left: 32px; background-color: white; border-top-left-radius: 16px; border-top-right-radius: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" File a Claim "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "icon fr"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["style", "padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["style", "display: inline-block;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["style", "float: left; margin-right: 6px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["src", "../../assets/images/info.png"], ["style", "width: 20px; height: 20px; margin-top: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [["style", "color: #4c6db9; overflow: hidden; text-align: left;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select the asset that you think had an accident and pay 20 USDC to file a claim. "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["style", "width: 100%; display: inline-block; margin-top: 1px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileAClaimComponent_1)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "pbutton"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.claim() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Claim "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileAClaimComponent_2)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileAClaimComponent_3)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allAssets; _ck(_v, 14, 0, currVal_0); var currVal_2 = _co.claiming; _ck(_v, 18, 0, currVal_2); var currVal_3 = _co.willShowAlertMessage; _ck(_v, 20, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.claiming; _ck(_v, 15, 0, currVal_1); }); }
export function View_FileAClaimComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-a-claim", [], null, null, null, View_FileAClaimComponent_0, RenderType_FileAClaimComponent)), i1.ɵdid(1, 114688, null, 0, i5.FileAClaimComponent, [i6.ContractService, i7.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileAClaimComponentNgFactory = i1.ɵccf("app-file-a-claim", i5.FileAClaimComponent, View_FileAClaimComponent_Host_0, {}, { onClose: "onClose", onRefresh: "onRefresh" }, []);
export { FileAClaimComponentNgFactory as FileAClaimComponentNgFactory };
