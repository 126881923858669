import { Component, OnInit } from '@angular/core';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { sellerClaimPremiumTransaction,
  sellerClaimBonusTransaction,
  sellerClaimMoreBonusTransaction,
  guarantorClaimPremiumTransaction,
  guarantorClaimBonusTransaction} from '../transactions.descriptions';
import { TransactionsService } from '../transactions.service';


import { environment } from '../../environments/environment';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  sellerInfo: any[] = [];
  guarantorInfo: any[] = [];

  moreBonusArray: any[] = [];

  buyerClaimingBonus = false;
  sellerClaimingBonus = false;
  sellerClaimingPremium = false;
  guarantorClaimingBonus = false;
  guarantorClaimingPremium = false;
  loading: boolean;

  constructor(private contractService: ContractService,
              private apiService: ApiService,
              public transactionsService: TransactionsService
  ) { }

  ngOnInit() {
    this.load();
  }

  async loadSellerInfo(){
    if (!this.contractService.address) {
      return;
    }
    this.sellerInfo = await this.contractService.getUserSellerInfo(this.contractService.address);
    this.sellerInfo[1] = +(+this.sellerInfo[1] / (10 ** environment.usdcDecimals)).toFixed(2);
    this.sellerInfo[2] = +(+this.sellerInfo[2] / (10 ** environment.tidalDecimals)).toFixed(2);
  }

  async loadGuarantorInfo() {
    if (!this.contractService.address) {
      return;
    }

    this.guarantorInfo = await this.contractService.getUserGuarantorInfo(this.contractService.address);
    this.guarantorInfo[1] = +(+this.guarantorInfo[1] / (10 ** environment.usdcDecimals)).toFixed(2);
    this.guarantorInfo[2] = +(+this.guarantorInfo[2] / (10 ** environment.tidalDecimals)).toFixed(2);
  }

  async loadMoreBonus() {
    if (!this.contractService.address) {
      return;
    }

    this.moreBonusArray = [];

    for (let pid = 0; pid < environment.moreBonusHelperPoolArray.length; ++pid) {
      const info = await this.contractService.moreBonusUserInfo(pid, this.contractService.address);
      if (!(+info[1])) {
        continue;
      }

      this.moreBonusArray.push({
        pid: pid,
        symbol: environment.moreBonusHelperPoolArray[pid].symbol.toUpperCase(),
        amount: +(+info[1] / (10 ** environment.moreBonusHelperPoolArray[pid].decimals)).toFixed(2)
      });
    }
  }

  async load() {
    if (!this.contractService.address) {
      return;
    }
    this.loading = true;
    const all = [(async () => {
      await this.loadSellerInfo();
    })(), (async () => {
      await this.loadGuarantorInfo();
    })(), (async () => {
      await this.loadMoreBonus();
    })()];

    await Promise.all(all);
    this.loading = false;
  }

  refresh() {
    this.load();
  }

  formatTokenBalance(value) {
    const result = +(+value).toFixed(2);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatRate(value) {
    if (!value) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  async claimGuarantorPremium() {
    this.guarantorClaimingPremium = true;
    this.transactionsService.updateProcessingTransaction(guarantorClaimPremiumTransaction.type, true);
    try {
      await this.contractService.guarantorClaimPremium();
      await this.loadGuarantorInfo();
    } catch(e) {
    }
    this.guarantorClaimingPremium = false;
    this.transactionsService.updateProcessingTransaction(guarantorClaimPremiumTransaction.type, false);
  }

  async claimSellerPremium() {
    this.sellerClaimingPremium = true;
    this.transactionsService.updateProcessingTransaction(sellerClaimPremiumTransaction.type, true);
    try {
      await this.contractService.sellerClaimPremium();
      await this.loadSellerInfo();
    } catch(e) {
    }
    this.sellerClaimingPremium = false;
    this.transactionsService.updateProcessingTransaction(sellerClaimPremiumTransaction.type, false);
    this.transactionsService.notionRefreshUsdcBalance(true);
  }

  async claimGuarantorBonus() {
    this.guarantorClaimingBonus = true;
    this.transactionsService.updateProcessingTransaction(guarantorClaimBonusTransaction.type, true);
    try {
      await this.contractService.guarantorClaimBonus();
      await this.loadGuarantorInfo();
    } catch(e) {
    }
    this.guarantorClaimingBonus = false;
    this.transactionsService.updateProcessingTransaction(guarantorClaimBonusTransaction.type, false);
  }

  async claimSellerBonus() {
    this.sellerClaimingBonus = true;
    this.transactionsService.updateProcessingTransaction(sellerClaimBonusTransaction.type, true);
    try {
      await this.contractService.sellerClaimBonus();
      await this.loadSellerInfo();
    } catch(e) {
    }
    this.sellerClaimingBonus = false;
    this.transactionsService.updateProcessingTransaction(sellerClaimBonusTransaction.type, false);
  }

  async claimMoreBonus(pid) {
    const moreBonus = this.moreBonusArray.filter(e => e.pid == pid)[0];
    if (!moreBonus) return;

    moreBonus.claiming = true;
    this.transactionsService.updateProcessingTransaction(sellerClaimMoreBonusTransaction.type, true);
    try {
      await this.contractService.sellerClaimMoreBonus(pid);
      await this.loadMoreBonus();
    } catch(e) {
    }

    moreBonus.claiming = false;
    this.transactionsService.updateProcessingTransaction(sellerClaimMoreBonusTransaction.type, false);
  }
}
