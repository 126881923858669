<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">Earning Summary</h2>
    <!-- <p class="des">Stake your TIDAL to earn additional rewards.</p> -->
  </div>
</div>
<div class="row wallet padding-left-32">
  <div><h3>Reserve Pool</h3></div>
  <div class="first-row linear-box-lr mov-left-20">
    <div class="wrap-box box2 border-right">
      <div class="min-width-160">
        <p class="title">Premium Earning</p>
        <p *ngIf="!loading"><strong>{{ formatTokenBalance(sellerInfo[1]) }} USDC</strong></p>
        <p *ngIf="loading"><span class="icon-spinner"></span></p>
      </div>
      <div>
        <p>
          <button style="font-weight: bold; width: 80px;" class="button" (click)="claimSellerPremium()" [disabled]="sellerClaimingPremium">Claim <span class="icon-spinner" *ngIf="sellerClaimingPremium"></span></button>
        </p>
      </div>
    </div>
    <div class="wrap-box box2 border-right">
      <div class="min-width-160">
        <p class="title">Tidal Earning</p>
        <p *ngIf="!loading"><strong>{{ formatTokenBalance(sellerInfo[2]) }} TIDAL</strong></p>
        <p *ngIf="loading"><span class="icon-spinner"></span></p>
      </div>
      <div>
        <p>
          <button style="font-weight: bold; width: 80px;" class="button" (click)="claimSellerBonus()" [disabled]="sellerClaimingBonus">Claim <span class="icon-spinner" *ngIf="sellerClaimingBonus"></span></button>
        </p>
      </div>
    </div>
  </div>

  <div class="first-row linear-box-lr mov-left-20" *ngIf="moreBonusArray.length">

    <div class="wrap-box box2 " *ngFor="let moreBonus of moreBonusArray"
    [ngClass]="moreBonusArray.length > 1 ? 'border-right' : ''"
    >
      <div class="min-width-160">
        <p class="title">{{ moreBonus.symbol }} Earning</p>
        <p *ngIf="!loading"><strong>{{ formatTokenBalance( moreBonus.amount ) }} {{ moreBonus.symbol }}</strong></p>
        <p *ngIf="loading"><span class="icon-spinner"></span></p>
      </div>
      <div>
        <p>
          <button style="font-weight: bold; width: 80px;" class="button" (click)="claimMoreBonus(moreBonus.pid)" [disabled]="moreBonus.claiming">Claim <span class="icon-spinner" *ngIf="moreBonus.claiming"></span></button>
        </p>
      </div>
    </div>

  </div>

  <div class="column-6 gra-line-bottom mov-left-20i invis-mobile"></div>
</div>
<div class="row wallet summary padding-left-32">
  <div><h3>Guarantor Pool</h3></div>

  <div class="first-row linear-box-lr mov-left-20">

    <div class="wrap-box box3 border-right">
      <div class="min-width-160">
        <p class="title">Premium Earning</p>
        <p *ngIf="!loading"><strong>{{ formatTokenBalance(guarantorInfo[1]) }} USDC</strong></p>
        <p *ngIf="loading"><span class="icon-spinner"></span></p>
      </div>
      <div>
        <p>
          <button style="font-weight: bold; width: 80px;" class="button" (click)="claimGuarantorPremium()" [disabled]="guarantorClaimingPremium">Claim <span class="icon-spinner" *ngIf="guarantorClaimingPremium"></span></button>
        </p>
      </div>
    </div>
    <div class="wrap-box box3 border-right">
      <div class="min-width-160">
        <p class="title">Tidal Earning</p>
        <p *ngIf="!loading"><strong>{{ formatTokenBalance(guarantorInfo[2]) }} TIDAL</strong></p>
        <p *ngIf="loading"><span class="icon-spinner"></span></p>
      </div>
      <div>
        <p>
          <button style="font-weight: bold; width: 80px;" class="button" (click)="claimGuarantorBonus()" [disabled]="guarantorClaimingBonus">Claim <span class="icon-spinner" *ngIf="guarantorClaimingBonus"></span></button>
        </p>
      </div>
    </div>
  </div>
</div>
