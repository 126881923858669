import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
  
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { guarantorDepositTransaction, guarantorReduceDepositTransaction } from '../transactions.descriptions';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-guarantor-deposit',
  templateUrl: './guarantor-deposit.component.html',
  styleUrls: ['./guarantor-deposit.component.css']
})
export class GuarantorDepositComponent implements OnInit {

  @Input() asset: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  amount: string = "";
  assetSymbol: string = "";
  assetBalance: string = "";
  reserveBalance: string = "";
  myCurrentBalance: string = "";
  myFutureBalance: string = "";
  deltaBalance: string = "";

  needApproval: boolean = true;
  loading: boolean = false;

  constructor(private contractService: ContractService,
              private apiService: ApiService,
              private transactionsService: TransactionsService,
              ) { }

  ngOnInit() {
    this.loading = true;
    this.loadAsset();
    this.loading = false;
  }

  async loadAsset() {
    if (this.contractService.address) {
      const all = [(async () => {
        this.assetBalance = await this.contractService.balanceOf(this.asset.token, this.contractService.address, this.asset.decimals);
      }) (), (async () => {
        const allowance = await this.contractService.getAllowance(this.asset.token, this.contractService.address, environment.guarantorAddress, this.asset.decimals);
        this.needApproval = parseFloat(allowance) < 1e6;
      }) ()];

      await Promise.all(all);
      this.deltaBalance = (+Math.max(0, parseFloat(this.asset.myFutureBalance) - parseFloat(this.asset.myBalance)).toFixed(2)).toString();
      this.amount = this.deltaBalance;
    }
  }

  async updateAsset() {
    if (this.contractService.address) {
      const obj = await this.contractService.getGuarantorUserBalance(
          this.contractService.address, this.asset.index, this.asset.decimals);
      this.asset.myFutureBalance = parseInt(obj.futureBalance);

      this.deltaBalance = (+Math.max(0, parseFloat(this.asset.myBalance) - parseFloat(this.asset.myBalance)).toFixed(2)).toString();
    }
  }

  max() {
    this.amount = this.assetBalance;
  }

  getNumber(x) {
    return parseFloat(x);
  }

  async approve() {
    this.loading = true;
    try {
      await this.contractService.approveWithoutBiconomy(this.asset.token, environment.guarantorAddress);
      this.needApproval = false;
    } catch(e) {
    }

    this.loading = false;
  }

  async deposit() {
    if (!parseFloat(this.amount) && parseFloat(this.amount)!== 0) {
      return;
    }

    try {
      if (+this.amount > +this.deltaBalance) {
        this.transactionsService.updateProcessingTransaction(guarantorDepositTransaction.type, true);
        await this.contractService.guarantorDeposit(this.asset.index, +(parseFloat(this.amount) - parseFloat(this.deltaBalance)).toFixed(2), this.asset.decimals);
      } else {
        this.transactionsService.updateProcessingTransaction(guarantorReduceDepositTransaction.type, true);
        await this.contractService.guarantorReduceDeposit(this.asset.index, +(parseFloat(this.deltaBalance) - parseFloat(this.amount)).toFixed(2), this.asset.decimals);
      }

      await this.updateAsset();
      this.amount = "";
    } catch(e) {
    }

    this.transactionsService.updateProcessingTransaction(guarantorDepositTransaction.type, false);
    this.transactionsService.updateProcessingTransaction(guarantorReduceDepositTransaction.type, false);
    this.onRefresh.emit();
  }

  willDisableButton() {
    const buttonDisabled = this.loading ||
      isNaN(+this.amount) ||
      this.getNumber(this.amount) > this.getNumber(this.assetBalance) + this.getNumber(this.asset.myBalance) ||
      this.transactionsService.processingTransactions[guarantorDepositTransaction.type] ||
      this.transactionsService.processingTransactions[guarantorReduceDepositTransaction.type];
    return buttonDisabled;
  }

  close() {
    this.onClose.emit();
  }
}
