var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
var ClaimsComponent = /** @class */ (function () {
    function ClaimsComponent(contractService, apiService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.onGoTo = new EventEmitter();
        this.payoutRequests = [];
        this.filteredRequests = [];
        this.key = "all";
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
    }
    ClaimsComponent.prototype.ngOnInit = function () {
        this.load();
    };
    ClaimsComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.apiService.getPayoutRequests()];
                    case 1:
                        _a.payoutRequests = _b.sent();
                        this.payoutRequests.forEach(function (r) {
                            r.status = _this.getStatus(r);
                        });
                        this.showRequests(this.key);
                        return [2 /*return*/];
                }
            });
        });
    };
    ClaimsComponent.prototype.refresh = function () {
        this.load();
    };
    ClaimsComponent.prototype.showRequests = function (key) {
        if (key == "all") {
            this.filteredRequests = this.payoutRequests;
        }
        else if (key == "pending") {
            this.filteredRequests = this.payoutRequests.filter(function (r) { return r.status == "Pending"; });
        }
        else if (key == "accepted") {
            this.filteredRequests = this.payoutRequests.filter(function (r) { return r.status == "Accepted"; });
        }
        else if (key == "expired") {
            this.filteredRequests = this.payoutRequests.filter(function (r) { return r.status == "Expired"; });
        }
        this.key = key;
    };
    ClaimsComponent.prototype.formatTime = function (time) {
        var date = new Date(time * 1000);
        return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
    };
    ClaimsComponent.prototype.getAbbr = function (line) {
        var len = line.length;
        if (len < 10)
            return len;
        return line.substr(0, 6) + '...' + line.substr(len - 4, len);
    };
    ClaimsComponent.prototype.getStatus = function (request) {
        if (request.executed)
            return "Accepted"; // Waiting for payment.
        var now = Math.floor(new Date().getTime() / 1000);
        if (now - request.time > 3 * 3600 * 24) {
            return "Expired";
        }
        else {
            return "Pending";
        }
    };
    ClaimsComponent.prototype.goToPortfolio = function () {
        this.onGoTo.emit({ key: 'portfolio' });
    };
    ClaimsComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    ClaimsComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    return ClaimsComponent;
}());
export { ClaimsComponent };
