var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
var BuyComponent = /** @class */ (function () {
    function BuyComponent(contractService, apiService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.onGoTo = new EventEmitter();
        this.tabIndex = 0;
        this.myAsset = {};
        this.subscribeType = "";
        this.predepositBalance = "";
        this.weeksBeCovered = "";
        this.sumOfPremium = 0;
        this.assetSymbol = "";
        this.retailPremiumRate = "";
        this.currentWeek = 0;
        this.userBuyerInfo = {};
        this.filteredBuyerHistory = [];
        this.retailHistory = [];
        this.filteredRetailHistory = [];
        this.groupedRetailHistory = [];
        this.capacityAll = 0;
        this.capacityAvailable = 0;
        this.willShowDeposit = false;
        this.willShowWithdraw = false;
        this.willShowSubscribe = false;
        this.willShowUnsubscribe = false;
        this.willShowAdjust = false;
        this.selectedAssetIndex = 0;
        this.seletedPremiumRate = 0;
        this.hasAsset = false;
        this.assetIndex = -1;
        this.approving = false;
        this.needApproval = true;
        this.willShowFileAClaim = false;
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
        this.myCurrentPremium = 0;
    }
    BuyComponent.prototype.ngOnInit = function () {
        this.load();
    };
    BuyComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var assetIndexPlusOne, retailHelperAssetInfo, all0;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.contractService.buyerAssetIndexPlusOne(this.contractService.address)];
                    case 1:
                        assetIndexPlusOne = _a.sent();
                        this.hasAsset = assetIndexPlusOne > 0;
                        this.assetIndex = assetIndexPlusOne - 1;
                        if (!this.hasAsset) {
                            return [2 /*return*/];
                        }
                        all0 = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getAllAssets()];
                                        case 1:
                                            data = _a.sent();
                                            this.myAsset = data.filter(function (asset) { return asset.index == _this.assetIndex; }).map(function (asset) {
                                                return {
                                                    name: asset.name,
                                                    index: asset.index,
                                                    _premiumRate: asset.premiumRate,
                                                    premiumRate: _this.formatRate(asset.premiumRate),
                                                    sellerBalance: asset.sellerBalance,
                                                    currentSubscription: asset.currentSubscription,
                                                    loading: true,
                                                    assetSymbol: asset.symbol,
                                                    decimals: asset.decimals
                                                };
                                            })[0];
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getPredepositBalance(this.contractService.address)];
                                        case 1:
                                            _a.predepositBalance = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getCurrentWeek()];
                                        case 1:
                                            _a.currentWeek = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.getUserBuyerInfo(this.contractService.address)];
                                        case 1:
                                            _a.userBuyerInfo = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var allowance;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.approving = true;
                                            return [4 /*yield*/, this.contractService.getAllowance(environment.usdcAddress, this.contractService.address, environment.committeeAddress, environment.usdcDecimals)];
                                        case 1:
                                            allowance = _a.sent();
                                            this.needApproval = parseFloat(allowance) < 1e9;
                                            this.approving = false;
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.apiService.getBuyerHistory()];
                                        case 1:
                                            data = _a.sent();
                                            data = data.filter(function (r) { return r.who.toLowerCase() == _this.contractService.address.toLowerCase(); });
                                            this.filteredBuyerHistory = data.map(function (r) {
                                                return {
                                                    date: _this.formatDate(r.blockTime),
                                                    subscription: _this.formatBalance(r.currentSubscription / (Math.pow(10, environment.usdcDecimals)), 0),
                                                    premium: _this.formatBalance((r.paid) / (Math.pow(10, environment.usdcDecimals)), 2),
                                                    balance: _this.formatBalance(r.balance / (Math.pow(10, environment.usdcDecimals)), 0),
                                                    refund: _this.formatBalance(r.premiumToRefund / (Math.pow(10, environment.usdcDecimals)), 0),
                                                    assetBalance: _this.formatBalance(r.assetBalance / (Math.pow(10, environment.usdcDecimals)), 0),
                                                };
                                            });
                                            this.filteredBuyerHistory = this.filteredBuyerHistory.reverse();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.apiService.getRetailHistory(this.assetIndex, 1000, 0)];
                                        case 1:
                                            _a.retailHistory = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.retailHelperAssetInfo(this.assetIndex)];
                                        case 1:
                                            retailHelperAssetInfo = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a, _b;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _a = this;
                                            _b = this.formatRate;
                                            return [4 /*yield*/, this.contractService.retailHelperPremiumRate(this.assetIndex)];
                                        case 1:
                                            _a.retailPremiumRate = _b.apply(this, [_c.sent()]);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all0)];
                    case 2:
                        _a.sent();
                        this._processRetailHistory();
                        this.sumOfPremium = 0;
                        return [4 /*yield*/, Promise.all([(function () { return __awaiter(_this, void 0, void 0, function () {
                                    var currentCoveredAmount, myCurrentPremium;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.contractService.getCurrentSubscription(this.assetIndex)];
                                            case 1:
                                                currentCoveredAmount = _a.sent();
                                                this.myAsset.myCurrentCoveredAmount = this.formatBalance(currentCoveredAmount, 0);
                                                myCurrentPremium = parseFloat(currentCoveredAmount) * this.myAsset._premiumRate / (Math.pow(10, environment.usdcDecimals));
                                                this.myAsset.myCurrentPremium = myCurrentPremium;
                                                this.myCurrentPremium = this.myAsset.myCurrentPremium;
                                                this.sumOfPremium += myCurrentPremium;
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                    var futureCoveredAmount, myFuturePremium;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.contractService.getFutureSubscription(this.assetIndex)];
                                            case 1:
                                                futureCoveredAmount = _a.sent();
                                                this.myAsset.myFutureCoveredAmount = this.formatBalance(futureCoveredAmount, 0);
                                                myFuturePremium = parseFloat(futureCoveredAmount) * this.myAsset._premiumRate / 1e6;
                                                this.myAsset.myFuturePremium = myFuturePremium;
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })()])];
                    case 3:
                        _a.sent();
                        this.capacityAll = Math.min(+this.myAsset.sellerBalance, +this.myAsset.currentSubscription);
                        this.capacityAvailable = Math.max(0, this.capacityAll - (+retailHelperAssetInfo.futureCapacityOffset) / (Math.pow(10, environment.usdcDecimals)));
                        this.myAsset.loading = false;
                        this.weeksBeCovered = this.myCurrentPremium ? Math.floor(parseFloat(this.predepositBalance) / this.myCurrentPremium).toString() : "0";
                        return [2 /*return*/];
                }
            });
        });
    };
    BuyComponent.prototype._processRetailHistory = function () {
        this.filteredRetailHistory = [];
        this.groupedRetailHistory = [];
        var sum = null;
        var week = 0;
        for (var i = 0; i < this.retailHistory.length; ++i) {
            var record = this.retailHistory[i];
            if (week == 0 || week != record.weekUpdated) {
                if (sum) {
                    this.filteredRetailHistory.push(sum);
                }
                week = record.weekUpdated;
                sum = {
                    date: record.blockTime,
                    subscription: (record.currentBase + record.currentAsset) / (Math.pow(10, environment.usdcDecimals)),
                    premiumBase: record.premiumBase / (Math.pow(10, environment.usdcDecimals)),
                    premiumAsset: record.premiumAsset / (Math.pow(10, this.myAsset.decimals))
                };
                this.groupedRetailHistory.push([record]);
            }
            else {
                sum.subscription += (record.currentBase + record.currentAsset) / (Math.pow(10, environment.usdcDecimals));
                sum.premiumBase += record.premiumBase / (Math.pow(10, environment.usdcDecimals));
                sum.premiumAsset += record.premiumAsset / (Math.pow(10, this.myAsset.decimals));
                this.groupedRetailHistory[this.groupedRetailHistory.length - 1].push(record);
            }
        }
        if (sum) {
            this.filteredRetailHistory.push(sum);
        }
    };
    BuyComponent.prototype.refresh = function () {
        this.load();
    };
    BuyComponent.prototype.download = function (index) {
        var _this = this;
        var json = this.groupedRetailHistory[index];
        var csv = json.map(function (record) {
            return [
                record.who,
                _this.formatDate(record.blockTime),
                '$' + _this.formatTableBalance(record.currentBase / (Math.pow(10, environment.usdcDecimals)), 0),
                '$' + _this.formatTableBalance(record.currentAsset / (Math.pow(10, environment.usdcDecimals)), 0),
                '$' + _this.formatTableBalance(record.futureBase / (Math.pow(10, environment.usdcDecimals)), 0),
                '$' + _this.formatTableBalance(record.futureAsset / (Math.pow(10, environment.usdcDecimals)), 0),
                '$' + _this.formatTableBalance(record.premiumBase / (Math.pow(10, environment.usdcDecimals))),
                _this.formatTableBalance(record.premiumAsset / (Math.pow(10, _this.myAsset.decimals)))
            ].join(",");
        }).join("\r\n");
        csv = "Address,Date,Actual covered amount (paid by USDC),Actual covered amount (paid by token)," +
            "Target covered amount (paid by USDC),Target covered amount (paid by token),Paid amount (by USDC),Paid amount (by token)\r\n" + csv;
        //this trick will generate a temp "a" tag
        var link = document.createElement("a");
        link.id = "lnkDwnldLnk";
        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        var blob = new Blob([csv], { type: 'text/csv' });
        var csvUrl = window['webkitURL'].createObjectURL(blob);
        var filename = ('UserExport_' + this.myAsset.assetSymbol + '_' + index) + '.csv';
        link.setAttribute('download', filename);
        link.setAttribute('href', csvUrl);
        link.click();
        document.body.removeChild(link);
    };
    BuyComponent.prototype.formatBalance = function (value, precision) {
        if (precision === void 0) { precision = 2; }
        var result = '$' + (+value).toFixed(precision);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    BuyComponent.prototype.formatTableBalance = function (value, precision) {
        if (precision === void 0) { precision = 2; }
        return (+value).toFixed(precision);
    };
    BuyComponent.prototype.formatTokenBalance = function (value, precision) {
        if (precision === void 0) { precision = 2; }
        var result = (+value).toFixed(precision);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    BuyComponent.prototype.formatDate = function (timestamp) {
        var date = new Date(timestamp * 1000);
        return (date.getMonth() + 1) + " / " + date.getDate() + " / " + date.getFullYear();
    };
    BuyComponent.prototype.formatRate = function (value) {
        if (isNaN(+value)) {
            return 'N/A';
        }
        return ((+value) / 10000).toFixed(2) + '%';
    };
    BuyComponent.prototype.goToPortfolio = function () {
        this.onGoTo.emit({ key: 'portfolio' });
    };
    BuyComponent.prototype.showOverview = function () {
        this.tabIndex = 0;
    };
    BuyComponent.prototype.showRetail = function () {
        this.tabIndex = 1;
    };
    BuyComponent.prototype.showDeposit = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowDeposit = true;
    };
    BuyComponent.prototype.closeDeposit = function () {
        this.willShowDeposit = false;
    };
    BuyComponent.prototype.showWithdraw = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowWithdraw = true;
    };
    BuyComponent.prototype.closeWithdraw = function () {
        this.willShowWithdraw = false;
    };
    BuyComponent.prototype.showSubscribe = function (type) {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowSubscribe = true;
        this.selectedAssetIndex = this.assetIndex;
        this.seletedPremiumRate = this.myAsset._premiumRate;
        this.subscribeType = type;
        this.assetSymbol = this.myAsset.assetSymbol;
    };
    BuyComponent.prototype.closeSubscribe = function () {
        this.willShowSubscribe = false;
    };
    BuyComponent.prototype.showUnsubscribe = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowUnsubscribe = true;
        this.selectedAssetIndex = this.assetIndex;
        this.seletedPremiumRate = this.myAsset._premiumRate;
        this.assetSymbol = this.myAsset.assetSymbol;
    };
    BuyComponent.prototype.closeUnsubscribe = function () {
        this.willShowUnsubscribe = false;
    };
    BuyComponent.prototype.approve = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) {
                            this.showAlert("Please connect to MetaMask", "");
                            return [2 /*return*/];
                        }
                        this.approving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.approve(environment.usdcAddress, environment.committeeAddress)];
                    case 2:
                        _a.sent();
                        this.needApproval = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.approving = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    BuyComponent.prototype.showFileAClaim = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowFileAClaim = true;
    };
    BuyComponent.prototype.closeFileAClaim = function () {
        this.willShowFileAClaim = false;
    };
    BuyComponent.prototype.showAdjust = function () {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.willShowAdjust = true;
    };
    BuyComponent.prototype.closeAdjust = function () {
        this.willShowAdjust = false;
    };
    BuyComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    BuyComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    BuyComponent.prototype.showLockedDesc = function (type) {
        if (type === 'totalCoveredAmount') {
            this.showAlert('Total Covered Amount', '“Total Covered Amount” is your current coverage amount, can be adjusted and updated in the “overview” page every week.');
        }
        else if (type === 'UserPurchaseCapacity') {
            this.showAlert('User Purchase Capacity', '“User Purchase Capacity” is the total coverage end users can buy, can be adjusted every week.');
        }
        else if (type === 'premiunFeeWeekly') {
            this.showAlert('Premium Fee', '“Premium Fee” is the current price for protocol team (paid by USDC)');
        }
        else if (type === 'UserPremiumFee') {
            this.showAlert('User Premium Fee', '“User Premium Fee” is the current price for end users');
        }
        else if (type === 'predeposit') {
            this.showAlert('My Predeposit Balance', 'Weekly coverage cost will be automatically deducted from the pre-deposit balance to save user’s gas. Please keep enough balance to maintain your coverage plan.');
        }
        else if (type === 'coveragePeriod') {
            this.showAlert('Estimate Coverage Period', 'Estimate coverage period is calculated based on your coverage amount and pre-deposit balance. Keep it for few weeks to have a peace of mind.');
        }
    };
    return BuyComponent;
}());
export { BuyComponent };
