<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">Claims</h2>
    <p class="des font-16-blue">Filed by insurance buyers.</p>
  </div>
</div>
<div class="row button-row btn-right padding-left-32 padding-right-32">
  <div class="box-wrap">
    <a href="javascript:void(0)" (click)="showRequests('all')" class="button" [ngClass]="{'actived': key==='all'}">All</a>
    <a href="javascript:void(0)" (click)="showRequests('pending')" class="button" [ngClass]="{'actived': key==='pending'}">Pending</a>
    <a href="javascript:void(0)" (click)="showRequests('accepted')" class="button" [ngClass]="{'actived': key==='accepted'}">Accepted</a>
    <a href="javascript:void(0)" (click)="showRequests('expired')" class="button" [ngClass]="{'actived': key==='expired'}">Expired</a>
  </div>
</div>
<div class="crypto-list row padding-left-32 padding-right-32">
  <div class="crypto-list-box" style="">
    <table class="crypto-list-tab" style="width: 100%">
      <tr class="crypto-list-title">
        <td class="crypto-list-search-td">
          <p class="serach-bar" style="background-image: url('/assets/images/icon-search.png')">
            <span class="light-title">Search</span>
          </p>
        </td>
        <td><strong>Date Filed</strong></td>
        <td><strong>Date of Decision</strong></td>
        <td><strong>Filed By</strong></td>
        <td><strong>Claim Status</strong></td>
      </tr>
      <tr class="crypto-list-item" *ngFor="let request of filteredRequests">
        <td class="crypto-list-td-first">
          <span class="icon">
            <img src="/assets/images/a{{ request.assetIndex * 1 + 1 }}.png">
          </span>
          <strong>{{request.assetName}}</strong>
        </td>
        <td><span class="number">{{formatTime(request.time)}}</span></td>
        <td><span class="number">-</span></td>
        <td><span class="number">{{getAbbr(request.requester)}}</span></td>
        <td><span class="number">{{request.status}}</span></td>
      </tr>
    </table>
  </div>
</div>

<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>
