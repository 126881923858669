var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { guarantorDepositTransaction, guarantorReduceDepositTransaction } from '../transactions.descriptions';
import { environment } from '../../environments/environment';
var GuarantorDepositComponent = /** @class */ (function () {
    function GuarantorDepositComponent(contractService, apiService, transactionsService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.transactionsService = transactionsService;
        this.onClose = new EventEmitter();
        this.onRefresh = new EventEmitter();
        this.amount = "";
        this.assetSymbol = "";
        this.assetBalance = "";
        this.reserveBalance = "";
        this.myCurrentBalance = "";
        this.myFutureBalance = "";
        this.deltaBalance = "";
        this.needApproval = true;
        this.loading = false;
    }
    GuarantorDepositComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.loadAsset();
        this.loading = false;
    };
    GuarantorDepositComponent.prototype.loadAsset = function () {
        return __awaiter(this, void 0, void 0, function () {
            var all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) return [3 /*break*/, 2];
                        all = [(function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contractService.balanceOf(this.asset.token, this.contractService.address, this.asset.decimals)];
                                        case 1:
                                            _a.assetBalance = _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(), (function () { return __awaiter(_this, void 0, void 0, function () {
                                var allowance;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.contractService.getAllowance(this.asset.token, this.contractService.address, environment.guarantorAddress, this.asset.decimals)];
                                        case 1:
                                            allowance = _a.sent();
                                            this.needApproval = parseFloat(allowance) < 1e6;
                                            return [2 /*return*/];
                                    }
                                });
                            }); })()];
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        this.deltaBalance = (+Math.max(0, parseFloat(this.asset.myFutureBalance) - parseFloat(this.asset.myBalance)).toFixed(2)).toString();
                        this.amount = this.deltaBalance;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    GuarantorDepositComponent.prototype.updateAsset = function () {
        return __awaiter(this, void 0, void 0, function () {
            var obj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.contractService.address) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contractService.getGuarantorUserBalance(this.contractService.address, this.asset.index, this.asset.decimals)];
                    case 1:
                        obj = _a.sent();
                        this.asset.myFutureBalance = parseInt(obj.futureBalance);
                        this.deltaBalance = (+Math.max(0, parseFloat(this.asset.myBalance) - parseFloat(this.asset.myBalance)).toFixed(2)).toString();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    GuarantorDepositComponent.prototype.max = function () {
        this.amount = this.assetBalance;
    };
    GuarantorDepositComponent.prototype.getNumber = function (x) {
        return parseFloat(x);
    };
    GuarantorDepositComponent.prototype.approve = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contractService.approveWithoutBiconomy(this.asset.token, environment.guarantorAddress)];
                    case 2:
                        _a.sent();
                        this.needApproval = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    GuarantorDepositComponent.prototype.deposit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!parseFloat(this.amount) && parseFloat(this.amount) !== 0) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!(+this.amount > +this.deltaBalance)) return [3 /*break*/, 3];
                        this.transactionsService.updateProcessingTransaction(guarantorDepositTransaction.type, true);
                        return [4 /*yield*/, this.contractService.guarantorDeposit(this.asset.index, +(parseFloat(this.amount) - parseFloat(this.deltaBalance)).toFixed(2), this.asset.decimals)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        this.transactionsService.updateProcessingTransaction(guarantorReduceDepositTransaction.type, true);
                        return [4 /*yield*/, this.contractService.guarantorReduceDeposit(this.asset.index, +(parseFloat(this.deltaBalance) - parseFloat(this.amount)).toFixed(2), this.asset.decimals)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [4 /*yield*/, this.updateAsset()];
                    case 6:
                        _a.sent();
                        this.amount = "";
                        return [3 /*break*/, 8];
                    case 7:
                        e_2 = _a.sent();
                        return [3 /*break*/, 8];
                    case 8:
                        this.transactionsService.updateProcessingTransaction(guarantorDepositTransaction.type, false);
                        this.transactionsService.updateProcessingTransaction(guarantorReduceDepositTransaction.type, false);
                        this.onRefresh.emit();
                        return [2 /*return*/];
                }
            });
        });
    };
    GuarantorDepositComponent.prototype.willDisableButton = function () {
        var buttonDisabled = this.loading ||
            isNaN(+this.amount) ||
            this.getNumber(this.amount) > this.getNumber(this.assetBalance) + this.getNumber(this.asset.myBalance) ||
            this.transactionsService.processingTransactions[guarantorDepositTransaction.type] ||
            this.transactionsService.processingTransactions[guarantorReduceDepositTransaction.type];
        return buttonDisabled;
    };
    GuarantorDepositComponent.prototype.close = function () {
        this.onClose.emit();
    };
    return GuarantorDepositComponent;
}());
export { GuarantorDepositComponent };
