<div class="popup-bg" (click)="close()">
</div>
<div class="popup-window" id="protocol-popup-window">

  <div class="popup-title title" style="position: relative;">
    <span class="icon"><img [src]="'/assets/images/a' + (protocolInfoObj.index + 1) + '.png'" style="width: 24px;height: 24px;"></span>
    <span>    {{ protocolInfoObj.title }}
    </span>
        <a class="icon fr mobile-fixed-closeBtn" href="javascript:void(0)" (click)="close()" >x</a></div>
  <div class="deposit-row protocol-container">
    <div class="protocol-item">
      <h1>Project Information</h1>
      <ul>
        <li>
          <span>Protocol Type</span>
          <span>{{protocolInfoObj.protocolType}}</span>
        </li>
        <li>
          <span>Chains</span>
          <span>{{protocolInfoObj.chains}}</span>
        </li>
        <li>
          <span>Bug Bounty Programs</span>
          <span *ngIf="protocolInfoObj.bugbounty">
            <img src="/assets/images/protolink.svg" style="margin-right: 10px">
            <a [href]="protocolInfoObj.bugbounty" target="_blank">Program Link</a>
          </span>
        </li>
        <li *ngIf="protocolInfoObj.auditsfirmsList[0]">
          <span>Date of Audits & Firms</span>
          <span>
            <img src="/assets/images/protolink.svg" style="margin-right: 10px">
            <a target="_blank" [href]="protocolInfoObj.auditsfirmsList[0].link"> {{protocolInfoObj.auditsfirmsList[0].text}}</a>
          </span>
        </li>
        <li *ngFor="let firms of protocolInfoObj.auditsfirmsList | slice:1; let i = index">
          <span></span>
          <span>
            <img src="/assets/images/protolink.svg" style="margin-right: 10px">
            <a [href]="firms.link"> {{firms.text}} </a>
          </span>
        </li>
        <li>
          <span>Website</span>
          <span>
            <img src="/assets/images/protolink.svg" style="margin-right: 10px">
            <a [href]="protocolInfoObj.website" target="_blank">{{protocolInfoObj.website}}</a>
            </span>
        </li>
      </ul>
    </div>
    <div class="divide-line protocol-item"></div>
    <div class="protocol-item">
      <h1>Coverage Status</h1>
      <ul>
        <li>
          <span>Covered TVL</span>
          <span>{{formatBalance(protocolInfoObj.currentSubscription)}}</span>
        </li>
        <li>
          <span>Premium Pricing</span>
          <span>{{protocolInfoObj.premiumPricing}}</span>
        </li>
        <li>
          <span>Guarantor Collateral</span>
          <span>{{protocolInfoObj.guarantorCollateralExpression}}</span>
        </li>
        <li>
          <span>Guarantor / Covered TVL</span>
          <span>{{protocolInfoObj.guarantorCoverTvl}}</span>
        </li>
        <li>
          <span>Tidal Collateral</span>
          <span>{{protocolInfoObj.tidalCollateralExpression}}</span>
        </li>
        <li>
          <span>Features Covered</span>
          <span>{{protocolInfoObj.features}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
