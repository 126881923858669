<div class="popup-bg">
  <div class="popup-window" style="text-align: center; background-color: #dfffff; max-width: 380px; height: 580px;">
    <div class="popup-title title" style="font-size: 18px; padding-left: 32px; background-color: white; border-top-left-radius: 16px; border-top-right-radius: 16px;">
      Withdraw {{asset.symbol}} as Guarantor
      <a class="icon fr" href="javascript:void(0)" (click)="close()">x</a>
    </div>
    <div style="padding-left: 48px; padding-right: 48px; margin-top: 0px; padding-top: 8px; background-color: #dfffff;">
      <div style="display: inline-block;">
      <div style="float: left; margin-right: 6px;"><img src="../../assets/images/info.png" style="width: 20px; height: 20px; margin-top: 16px;" /></div>

      <p style="color: #4c6db9; overflow: hidden; text-align: left;">
        New withdraw amount will be queued and locked in the next epoch | week. If there is no valid claim, amount will be effective after the next epoch | week.
      </p>
      </div>
    <div style="width: 100%; display: inline-block;">
      <p style="color: #002f9b; margin-top: 4px; float: left; margin-bottom: 0px;">Total Withdraw Amount:</p>
    </div>
    <div style="width: 100%; display: inline-block; margin-top: 0px;">
      <p style=" margin-top: 0px; color: #002f9b; float: left;">{{ getNumber(amount)? getNumber(nextWeekWithdrawAmount).toFixed(2) + ' + ' + getNumber(amount).toFixed(2): getNumber(nextWeekWithdrawAmount).toFixed(2) }} {{asset.symbol}}</p>
    </div>
    <div style="width: 100%; display: inline-block; margin-top: -8px;">
      <p style="color: #002f9b; margin-top: 16px; float: left; margin-bottom: 0px;">Locked Withdraw Amount:</p>
    </div>
    <div style="width: 100%; display: inline-block; margin-top: 1px;">
      <p style=" margin-top: 0px; color: #002f9b; float: left;">{{ getNumber(nextWeekWithdrawAmount).toFixed(2) }} {{asset.symbol}}</p>
    </div>
    <div style="width: 100%; display: inline-block;">
      <p style="color: #002f9b; margin-top: 8px; float: left">New Withdraw Amount:</p>
    </div>
    <div class="deposit-input">
        <input type="number" placeholder="" [(ngModel)]="amount" value="{{amount}}" min="0" oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? this.value : null" />
        <a href="javascript: void(0)" (click)="max()">Max</a>
    </div>
    <button class="pbutton" [ngStyle]="{'background-color': willDisableButton() ? 'grey': '#002f9b'}"
      (click)="withdraw()"
      [disabled]="willDisableButton()" >{{ getNumber(amount) > getNumber(asset.myBalance)? 'Insufficient Fund' : 'Update' }} <span class="icon-spinner" *ngIf="loading"></span></button>
    <div style="width: 100%; display: inline-block; margin-top: 16px;" >
      <p class="title" style="float: left; font-size: 13px;">My Reserve:</p>
      <p class="number" style="float: right; font-size: 13px;">{{ asset.myBalance }} {{asset.symbol}}</p>
    </div>
  </div>
</div>
</div>
