import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-protocol-queuedupdate',
  templateUrl: './protocol-queuedupdate.component.html',
  styleUrls: ['./protocol-queuedupdate.component.css']
})
export class ProtocolQueuedupdateComponent implements OnInit {
  @Input() showAssets: any[] = [];
  @Input() currentProtoObj: {} = {};
  @Input() currentIndexes: any[] = [];
  @Input() nextWeekIndexes: any[] = [];
  @Input() nextNextWeekIndexes: any[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  isShowPendingUpdate: boolean = true;

  constructor() { }

  ngOnInit() {
    this.setPendingUpdate();
  }
  close() {
    this.onClose.emit();
  }
  formatRate(value) {
    if (isNaN(value)) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  arraysEqual(a, b) {
    a = a.sort();
    b = b.sort();
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  setPendingUpdate () {
    if (this.arraysEqual(this.currentIndexes, this.nextWeekIndexes) && this.arraysEqual(this.nextWeekIndexes, this.nextNextWeekIndexes)) {
        this.isShowPendingUpdate = false;
      }
  }
}
