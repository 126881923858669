import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';
import { environment } from '../../environments/environment';
import { sellerBasketUpdatingTransaction } from '../transactions.descriptions';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.css']
})
export class SellComponent implements OnInit {

  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  category = 0;

  allAssets: any[] = [];

  myAPRs: any = {};
  showAssets: any[] = [];
  hasNextWeek = false;
  nextWeekIndexes: any[] = [];
  recordClickWeekIndexes: any[] = [];
  recordClickNextWeekIndexes: any[] = [];
  hasNextNextWeek = false;
  nextNextWeekIndexes: any[] = [];
  tidalMined = 0;

  currentIndexes: any[] = [];
  pendingIndexes: any[] = [];
  hasPending = false;
  isEditMode: boolean = false;
  myCurrentBalance: string = "";
  myFutureBalance: string = "";
  nextWeekWithdrawAmount: number = 0;
  nextNextWeekWithdrawAmount: number = 0;
  deltaBalance: string = "0";

  willShowDeposit: boolean = false;
  willShowWithdraw: boolean = false;
  willShowBasket: boolean = false;
  willShowReminderIcon: boolean = false;
  willShowNextWeekNoneText: boolean = true;
  willShowNextNextWeekNoneText: boolean = true;

  basketChanged = false;
  isChangingBasket = false;
  isShowProtocolQueue: boolean = false;
  alertTitle: string = "";
  alertBody: string = "";
  willShowAlertMessage: boolean = false;
  allCategories: any = [];
  durationDays: number = 0;
  durationHours: number = 0;
  newEpochStartsDays: number = 0;
  newEpochStartsHours: number = 0;
  pendingDepositAmount: number = 0;
  pendingWithdrawReserve: number = environment.pendingWithdrawReserve;
  loading: boolean= false;
  operationBtnClickSum: number = 0;
  isShowFloatPopup: boolean = false;
  currentProtoObj = {
    stakeList: [],
    unstakeList: [],
    prevStakeList: [],
    prevUnStakeList: []
  }
  protocolInfoObj: {
    guarantorCoverTvl: string;
    guarantorCollateralExpression: string;
    tidalCollateralExpression: string;
    chains: any;
    key: string,
    tokenSymbol: string,
    name: string,
    guarantorCollateral: number,
    tidalCollateral: number,
    dateofMainnet: string,
    premiumPricing: string,
    features: string,
    auditsfirmsList: any[]
  }
  isUserNewRegiter: boolean;
  isSetNewStakeProtocol: boolean = false;
  willShowProtocolPopup: boolean;
  poolBalance: number;
  isUpdateProtoComplete: boolean;

  constructor(private contractService: ContractService, private apiService: ApiService,
    public transactionsService: TransactionsService,
    ) { }

  ngOnInit() {
    this.goToCategory(0);
    this.loadPendingDeposit();
  }

  async load() {
    this.loading = true;
    await this.contractService.init();

    const all = [(async () => {
      const data1 = await this.apiService.getAllAssets();
      this.allAssets = data1.map(asset => {
        return {
          index: asset.index,
          name: asset.name,
          symbol: asset.symbol,
          token: asset.token,
          category: asset.category,
          apr: asset.apr,
          premiumRate: asset.premiumRate,
          sellerTidalApr: asset.sellerTidalApr,
          moreApr: asset.moreApr || 0,
          moreAprSymbol: asset.moreAprSymbol || '',
          guarantorBalance: this.formatTokenBalance(asset.guarantorBalance),
          guarantorValue: this.formatBalance(asset.guarantorValue),
          guarantorValueForProto: asset.guarantorValue,
          sellerBalance: this.formatBalance(asset.sellerBalance),
          sellerBalanceNumber: asset.sellerBalance,
          currentSubscription: asset.currentSubscription,
          assetUtilization: this.formatRate(asset.assetUtilization),
          oversold: asset.currentSubscription > asset.sellerBalance
        };
      });
    })(), (async () => {
      if (this.contractService.address) {
        await this.loadSellerInfo();
      }
    })(), (async () => {
      if (this.contractService.address) {
        this.myCurrentBalance = await this.contractService.getSellerCurrentBalance(
            this.contractService.address, this.category);
      }
    })(), (async () => {
      if (this.contractService.address) {
        this.myFutureBalance = await this.contractService.getSellerFutureBalance(
            this.contractService.address, this.category);
      }
    })(), (async () => {
      if (this.contractService.address) {
        const data = await this.contractService.getNextWeekBasket(
          this.contractService.address, this.category);
        this.hasNextWeek = data.has;
        this.nextWeekIndexes = data.indexes.map(index => parseInt(index));
      }
    })(), (async () => {
      if (this.contractService.address) {
        const data = await this.contractService.getNextNextWeekBasket(this.contractService.address, this.category);
        this.hasNextNextWeek = data.has;
        this.nextNextWeekIndexes = data.indexes.map(index => parseInt(index));
      }
    })(), (async () => {
      if (this.contractService.address) {
        const data = await this.contractService.getPendingBasket(
            this.contractService.address, this.category);
        this.hasPending = data.has;
        this.pendingIndexes = data.indexes.map(index => parseInt(index));
      }
    })(), (async () => {
      if (this.contractService.address) {
        this.nextWeekWithdrawAmount = await this.contractService.getSellerNextWeekWithdraw(
          this.contractService.address, this.category);
      }
    })(), (async () => {
      if (this.contractService.address) {
        this.nextNextWeekWithdrawAmount = await this.contractService.getSellerNextNextWeekWithdraw(
            this.contractService.address, this.category);
      }
    })(), (async () => {
      if (!this.contractService.address) {
        return;
      }

      Promise.all([
        this.contractService.getNow(),
        this.contractService.getCurrentWeek(),
      ]).then((values)=> {
        let secs = (values[1] + 1) * 3600 * 24 * 7 - values[0];
        let days = secs / (3600 * 24) - 4;
        this.durationDays = Math.floor(days);  // 4 is the offset.
        this.durationHours = Math.floor((days - this.durationDays) * 24);
      });
    })(), (async () => {
      const categories = await this.apiService.getAllCategories();
      this.allCategories = categories.map(category => {
        return {
          reserve: this.formatTokenBalance(category.reserve),
          apr: this.formatRate(category.apr),
          payout: this.formatBalance(category.payout)
        };
      });
    })()];

    await Promise.all(all);

    if (this.contractService.address) {
      this.deltaBalance = (+Math.max(0, parseFloat(this.myFutureBalance) - parseFloat(this.myCurrentBalance)).toFixed(2)).toString();
    }

    this.showAssets = this.allAssets.filter(asset => asset.category * 1 == this.category);

    if (this.contractService.address) {
      this.currentIndexes = [];

      const all1 = this.showAssets.map(async (asset, i) => {
        if (await this.contractService.sellerUserBasket(
            this.contractService.address, asset.index)) {
          this.currentIndexes.push(asset.index);
        }
      });

      await Promise.all(all1);
      this.myAPRs = this.getMyAPRs();

      if (!this.hasNextWeek) {
        this.nextWeekIndexes = [...this.currentIndexes];
      }

      if (!this.hasNextNextWeek) {
        this.nextNextWeekIndexes = [...this.currentIndexes];
      }

      if (!this.hasPending) {
        this.pendingIndexes = [...this.currentIndexes];
      }

      this.setCurrentProtoList();
    }

    this.loading = false;
    this.willShowNextWeekNoneText = this.willShowNoneText(this.nextWeekIndexes, this.showAssets);
    this.willShowNextNextWeekNoneText = this.willShowNoneText(this.nextNextWeekIndexes, this.showAssets);
    this.willShowReminderIcon = (this.willShowNextWeekNoneText && this.willShowNextNextWeekNoneText && this.willShowNoneText(this.currentIndexes, this.showAssets));
    this.setNewStakeProtocol();
  }

  async loadPendingDeposit() {
    const data = await this.apiService.getPendingDepositAmount();
    this.pendingDepositAmount = data.pendingBalance / (10 ** environment.usdcDecimals);
  }

  setNewStakeProtocol () {
    if (this.currentIndexes.length === this.nextNextWeekIndexes.length && this.currentIndexes.length === this.nextWeekIndexes.length &&
        this.currentIndexes.length === 0) {
      this.isSetNewStakeProtocol = true;
    }

  }

  setCurrentProtoList () {
    this.showAssets.forEach(asset => {
      if (this.pendingIndexes.indexOf(asset.index) >= 0) {
        this.currentProtoObj.stakeList.push(asset);
      } else {
        this.currentProtoObj.unstakeList.push(asset);
      }
    })
  }

  willShowNoneText(indexes, assets) {
    for (const asset of assets) {
      if (indexes.indexOf(asset.index) >= 0) {
        return false;
      }
    }
    return true;
  }

  async changeBasket() {
    this.isChangingBasket = true;

    try {
      await this.contractService.sellerChangeBasket(this.category, this.currentIndexes);
      if (parseFloat(this.myCurrentBalance)) {
        this.showAlert('Basket change saved', 'Please wait 1 to 2 weeks for the change to take effective');
      } else {
        this.showAlert('Basket change saved', 'Now it\'s effective');
      }
    } catch (e) {
    }

    this.isChangingBasket = false;
    this.refresh();
  }

  refresh() {
    this.load();
  }

  async loadSellerInfo() {
    const sellerInfo = await this.contractService.getUserSellerInfo(this.contractService.address);
    this.tidalMined = +(+sellerInfo[2] / (10 ** environment.tidalDecimals)).toFixed(2);
  }

  formatBalance(value) {
    const result = '$' + (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatTokenBalance(value) {
    if (!value || value.toString() == '0') return '0';
    const result = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatRate(value) {
    if (isNaN(value)) {
      return 'N/A';
    }

    return (value / 10000).toFixed(2) + '%';
  }

  formatSymbol(value) {
    if (!value) return '';
    return value.toUpperCase();
  }

  goToCategory(category) {
    this.category = category;
    this.load();
  }

  goToPortfolio() {
    this.onGoTo.emit({key: 'portfolio'});
  }

  showDeposit() {
    if (!this.contractService.address) {
      this.showAlert('Please connect to MetaMask', '');
      return;
    }

    this.willShowDeposit = true;
  }

  closeDeposit() {
    this.willShowDeposit = false;
  }

  showWithdraw() {
    if (!this.contractService.address) {
      this.showAlert('Please connect to MetaMask', '');
      return;
    }

    this.willShowWithdraw = true;
  }

  closeWithdraw() {
    this.willShowWithdraw = false;
  }

  showBasket() {
    if (!this.contractService.address) {
      this.showAlert('Please connect to MetaMask', '');
      return;
    }

    this.willShowBasket = true;
  }

  showLockedDesc(type: string) {
    if (type === 'next') {
      this.showAlert('Queued Update(Locked)', 'You have a pending queued update to become effective in the next week.');
    } else if (type === 'nextNext') {
      this.showAlert('New Update', 'New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.');
    } else if (type === 'current') {
      return;
      // this.showAlert("New Update", "New basket update will be queued and locked in the next epoch | week. If there is no valid claim, the basket update will be effective after the next epoch | week.");
    } else if (type === 'myPremium') {
      this.showAlert('My APR', `USDC APR: ${this.formatRate(this.myAPRs.premiumApr)}
      \xa0\xa0\xa0\xa0\xa0\xa0\xa0 \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0
      TIDAL Reward APR: ${this.formatRate(this.myAPRs.tidalApr)}`);
    } else if (type === 'maxPremium') {
      this.showAlert('Max APR', `USDC APR: ${this.formatRate(this.myAPRs.maxPremiumApr)}
      \xa0\xa0\xa0\xa0\xa0\xa0\xa0 \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0
      TIDAL Reward APR: ${this.formatRate(this.myAPRs.maxTidalApr)}`);
    } else if (type === 'estimatedApr') {
      this.showAlert('Estimated APR', 'Estimated APR is next week\'s estimated APR, calculated based on the current pending deposits.');
    }
    return;
  }

  showPendingActions() {
    this.showAlert('Pending Status', `Pending Deposit:
      ${this.formatTokenBalance(this.deltaBalance)} USDC
      \xa0\xa0\xa0\xa0\xa0\xa0\xa0 \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0
      Pending Withdraw:
      ${this.formatTokenBalance(this.nextWeekWithdrawAmount + this.nextNextWeekWithdrawAmount)} USDC`);
  }

  closeBasket() {
    this.willShowBasket = false;
  }

  showAlert(title, body) {
    this.alertTitle = title;
    this.alertBody = body;
    this.willShowAlertMessage = true;
  }

  toggleShowFloatPopup ($event, status) {
    const floatItem = $event.target.parentNode.parentNode.querySelector('.float-item');
    this.adjustFloatItemPos(floatItem, status);
  }

  isDetailCanClick (asset) {
    const protoAsset = environment.protocolList.find(proto => proto.key == asset.name);
    return protoAsset;
  }

  async showProtocolsInfoPopup (asset) {
    if (!this.isDetailCanClick(asset)) {
      return;
    }

    this.willShowProtocolPopup = true;
    this.protocolInfoObj = asset;
    this.protocolInfoObj = Object.assign(asset, {
      avatar: '/assets/images/a' + (asset.index + 1) + '.png',
      title: asset.name
    }, this.isDetailCanClick(asset));

    const tidalUSDprice = await this.getTokenPrice('tidal-finance');
    // tslint:disable-next-line:radix
    this.protocolInfoObj.chains = this.protocolInfoObj.chains.join(',');
    this.protocolInfoObj.guarantorCoverTvl = `${this.formatRate(+asset.guarantorValueForProto / asset.currentSubscription)}`;
    this.protocolInfoObj.guarantorCollateralExpression =  `${+asset.guarantorValueForProto.toFixed(2)}  /  ${asset.guarantorBalance} (${asset.symbol})`;
    this.protocolInfoObj.tidalCollateralExpression =  this.getTidalCollateral(tidalUSDprice);
    this.protocolInfoObj.premiumPricing = this.formatRate(asset.premiumRate) + ' Weekly';
  }

  async getTokenPrice(token) {
    const url = `https://api.coingecko.com/api/v3/simple/price?ids=${token}&vs_currencies=usd`;
    const data = await this.apiService.get(url, 'token');
    return data && data[token] && data[token]['usd'] ? data[token]['usd'] : 0;
  }

  closeAlert() {
    this.willShowAlertMessage = false;
  }

  closeProtoStatus () {
    this.isShowProtocolQueue = false;
  }

  closeProtocolPopup() {
    this.willShowProtocolPopup = false;
  }

  getNumber(x) {
    return parseFloat(x);
  }

  getEstimatedApr () {
    if (this.allCategories.length === 0) return 0;
    let currentReserve =  parseFloat(this.allCategories[0].reserve.split(',').join(''));
    let estimatedBalanceForSeller = currentReserve +
        this.pendingDepositAmount - this.pendingWithdrawReserve;
    return this.getMyAPRs().maxApr / estimatedBalanceForSeller * currentReserve;
  }

  toggleEditStakeProto () {
    this.isEditMode = !this.isEditMode;
    this.operationBtnClickSum ++;
    if (this.operationBtnClickSum === 1) {
      this.recordClickNextWeekIndexes = this.nextNextWeekIndexes.concat();
    }
  }

  toggleProtoStatus (asset, operation) {
    if (operation === 'stake') {
      this.nextNextWeekIndexes.push(asset.index);
    } else {
      this.nextNextWeekIndexes.forEach((item, index) => {
        if (item === asset.index) this.nextNextWeekIndexes.splice(index, 1);
      })
    }
  }

  resetProtoStatusList () {
    if (this.operationBtnClickSum > 0) {
      this.operationBtnClickSum = 0;
      this.nextNextWeekIndexes = this.recordClickNextWeekIndexes;
    }
    this.isEditMode = false;
  }

  getTidalCollateral (tidalUSDprice) {
    return this.poolBalance < 1000000 ? `${this.formatBalance((tidalUSDprice * this.poolBalance))} USD \ ${this.poolBalance} TIDAL` : `100,000 USD in TIDAL`;
  }

  getMyAPRs() {
    let myApr = 0;
    let premiumApr = 0;
    let tidalApr = 0;
    let maxApr = 0;
    let maxPremiumApr = 0;
    let maxTidalApr = 0;
    for (let i = 0; i < this.currentIndexes.length; i++) {
      const premiumAprNumber = this.allAssets[this.currentIndexes[i]].apr;
      const tidalAprNumber = this.allAssets[this.currentIndexes[i]].sellerTidalApr;
      premiumApr += premiumAprNumber;
      tidalApr += tidalAprNumber;
    }

    myApr = premiumApr + tidalApr;
    if (parseFloat(this.myCurrentBalance) == 0) {
      myApr = 0;
    }

    for (let i = 0; i < this.showAssets.length; i++) {
      const premiumAprNumber = this.allAssets[i].apr;
      const tidalAprNumber = this.allAssets[i].sellerTidalApr;
      maxPremiumApr += premiumAprNumber;
      maxTidalApr += tidalAprNumber;
    }

    maxApr = maxPremiumApr + maxTidalApr;

    return {
      premiumApr: premiumApr,
      tidalApr: tidalApr,
      myApr: myApr,
      maxApr: maxApr,
      maxPremiumApr: maxPremiumApr,
      maxTidalApr: maxTidalApr
    };
  }

  async updateProtoStatus() {
    this.transactionsService.updateProcessingTransaction(sellerBasketUpdatingTransaction.type, true);
    try {
      await this.contractService.sellerChangeBasket(this.category, this.nextNextWeekIndexes);

      if (parseFloat(this.myCurrentBalance)) {
        this.showAlert("Change saved", "");
      } else {
        this.showAlert("Change saved", "");
      }
      this.isUpdateProtoComplete = true;
    } catch(e) {
    }

    this.currentProtoObj.stakeList = [];
    this.currentProtoObj.unstakeList = [];
    this.transactionsService.updateProcessingTransaction(sellerBasketUpdatingTransaction.type, false);
    this.isEditMode = false;
    await this.load();
  }

  isComfirm() {
    return this.transactionsService.processingTransactions[sellerBasketUpdatingTransaction.type];
  }

  adjustFloatItemPos (elem, status) {
    function getPosition(element) {
      var xPosition = 0;
      var yPosition = 0;

      while(element) {
          xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
          yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
          element = element.offsetParent;
      }

      return { x: xPosition, y: yPosition };
    }

    if ((window.innerWidth - +getPosition(elem).x) < 256) {
      elem.style.right = '0%';
      elem.style.top = '30%';
    }

    elem.style.visibility = status;
  }
}
