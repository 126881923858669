var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TransactionsService } from './transactions.service';
import { NotificationsService } from './notification-message/notifications.service';
import * as transactionsDescriptions from './transactions.descriptions';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./transactions.service";
import * as i2 from "./notification-message/notifications.service";
var ContractService = /** @class */ (function () {
    function ContractService(transactionsService, notifications) {
        this.transactionsService = transactionsService;
        this.notifications = notifications;
        this.address = '';
        this.usdcBalance = '';
    }
    ContractService.prototype.getFixedTwoStr = function (value) {
        return (Math.max(0, value - 0.005)).toFixed(2).toString();
    };
    ContractService.prototype._wait = function (duration) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    resolve();
                    return [2 /*return*/];
                });
            }); }, duration);
        });
    };
    ContractService.prototype.isConnected = function () {
        return window['ethereum'] && window['ethereum'].isConnected();
    };
    ContractService.prototype._waitForBiconomyReady = function (biconomy) {
        return new Promise(function (resolve, reject) {
            biconomy.onEvent(biconomy.READY, function () {
                // Initialize your dapp here like getting user accounts etc
                resolve();
            }).onEvent(biconomy.ERROR, function (error, message) {
                // Handle error while initializing mexa
                reject(error);
            });
        });
    };
    ContractService.prototype._willUseBiconomy = function () {
        return 0;
        // return parseInt(localStorage.getItem('willUseBiconomy_')) || 0;
    };
    ContractService.prototype._getWeb3 = function () {
        return this.web3m;
        // return this._willUseBiconomy() ? this.web3b : this.web3m;
    };
    ContractService.prototype.enable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, e_1, provider;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, window['ethereum'].send('eth_requestAccounts')];
                    case 1:
                        res = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        return [2 /*return*/, ""];
                    case 3:
                        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                            var Biconomy, biconomy, e_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        Biconomy = window['Biconomy'];
                                        biconomy = new Biconomy(window['ethereum'], { apiKey: environment.biconomyAPIKey, debug: true });
                                        this.web3b = new window['Web3'](biconomy);
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, this._waitForBiconomyReady(biconomy)];
                                    case 2:
                                        _a.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_2 = _a.sent();
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }, 0);
                        provider = environment.web3EndPoint ?
                            new window['Web3'].providers.HttpProvider(environment.web3EndPoint) :
                            window['ethereum'];
                        this.web3 = new window['Web3'](provider);
                        this.web3m = new window['Web3'](window['ethereum']);
                        this.address = res && res.result ? res.result[0] : "";
                        return [2 /*return*/, this.address];
                }
            });
        });
    };
    ContractService.prototype.balanceOf = function (tokenAddress, address, decimal) {
        return __awaiter(this, void 0, void 0, function () {
            var token, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = new this.web3.eth.Contract(environment.erc20Abi, tokenAddress);
                        return [4 /*yield*/, token.methods.balanceOf(address).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, decimal)))];
                }
            });
        });
    };
    ContractService.prototype.getAllowance = function (tokenAddress, address, spender, decimal) {
        return __awaiter(this, void 0, void 0, function () {
            var token, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = new this.web3.eth.Contract(environment.erc20Abi, tokenAddress);
                        return [4 /*yield*/, token.methods.allowance(address, spender).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, decimal)))];
                }
            });
        });
    };
    ContractService.prototype.loadUSDCBalance = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.balanceOf(environment.usdcAddress, address, environment.usdcDecimals)];
                    case 1:
                        _a.usdcBalance = _b.sent();
                        return [2 /*return*/, this.usdcBalance];
                }
            });
        });
    };
    ContractService.prototype.getPredepositBalance = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new this.web3.eth.Contract(environment.buyerAbi, environment.buyerAddress);
                        return [4 /*yield*/, buyer.methods.getBalance(address).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, environment.usdcDecimals)))];
                }
            });
        });
    };
    ContractService.prototype.getTotalFuturePremium = function (address) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new this.web3.eth.Contract(environment.buyerAbi, environment.buyerAddress);
                        return [4 /*yield*/, buyer.methods.getTotalFuturePremium(address).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, environment.usdcDecimals)))];
                }
            });
        });
    };
    ContractService.prototype.getCurrentSubscription = function (assetIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new this.web3.eth.Contract(environment.buyerAbi, environment.buyerAddress);
                        return [4 /*yield*/, buyer.methods.currentSubscription(assetIndex).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, environment.usdcDecimals)))];
                }
            });
        });
    };
    ContractService.prototype.getFutureSubscription = function (assetIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new this.web3.eth.Contract(environment.buyerAbi, environment.buyerAddress);
                        return [4 /*yield*/, buyer.methods.futureSubscription(assetIndex).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, environment.usdcDecimals)))];
                }
            });
        });
    };
    ContractService.prototype.getAllSellerBalance = function (category) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.categoryBalance(category).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, environment.usdcDecimals)))];
                }
            });
        });
    };
    ContractService.prototype.getSellerCurrentBalance = function (address, category) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.userBalance(address, category).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value[0]) / (Math.pow(10, environment.usdcDecimals)))];
                }
            });
        });
    };
    ContractService.prototype.getSellerFutureBalance = function (address, category) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.userBalance(address, category).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value[1]) / (Math.pow(10, environment.usdcDecimals)))];
                }
            });
        });
    };
    ContractService.prototype.isSellerCurrentBasket = function (address, assetIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var seller;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.userBasket(address, assetIndex).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getAllGuarantorBalance = function (assetIndex, decimal) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new this.web3.eth.Contract(environment.guarantorAbi, environment.guarantorAddress);
                        return [4 /*yield*/, guarantor.methods.assetBalance(assetIndex).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, this.getFixedTwoStr((+value) / (Math.pow(10, decimal)))];
                }
            });
        });
    };
    ContractService.prototype.getGuarantorUserBalance = function (address, assetIndex, decimal) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new this.web3.eth.Contract(environment.guarantorAbi, environment.guarantorAddress);
                        return [4 /*yield*/, guarantor.methods.userBalance(address, assetIndex).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, {
                                currentBalance: this.getFixedTwoStr((+value[0]) / (Math.pow(10, decimal))),
                                futureBalance: this.getFixedTwoStr((+value[1]) / (Math.pow(10, decimal)))
                            }];
                }
            });
        });
    };
    ContractService.prototype._send = function (sendHandler, transaction) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            sendHandler
                .once('transactionHash', function (transactionHash) {
                _this.transactionsService.addTransaction({
                    action: 'START',
                    type: transaction.type,
                    transactionHash: transactionHash
                });
            })
                .once('confirmation', function (confirmationNumber, receipt) {
                _this.transactionsService.updateTransaction({
                    action: 'SUCCESS',
                    transactionHash: receipt.transactionHash,
                    type: transaction.type
                });
                _this.notifications.showNotification({
                    title: transaction.type,
                    body: "Success. Thanks for " + transaction.type,
                    transactionHash: receipt.transactionHash,
                    type: 'success'
                });
                resolve(receipt);
            })
                .once('error', function (error) {
                _this.transactionsService.updateTransaction({
                    action: 'ERROR',
                    transactionHash: error.receipt ? error.receipt.transactionHash : null,
                    type: transaction.type,
                });
                _this.notifications.showNotification({
                    title: transaction.type,
                    body: error.code === 4001 ? 'Transaction rejected' : 'Transaction fails',
                    transactionHash: error['receipt'] ? error['receipt']['transactionHash'] : null,
                    type: 'error'
                });
                reject(error);
            });
        });
    };
    ContractService.prototype._decToHex = function (x, decimal) {
        if (x == 0)
            return '0x0';
        var str = x;
        for (var index = 0; index < decimal; index++) {
            str += "0";
        }
        var pos = str.indexOf(".");
        if (pos != -1) {
            str = str.substr(0, pos) + str.substr(pos + 1, decimal);
        }
        var dec = str.toString().split(''), sum = [], hex = [], i, s;
        while (dec.length) {
            s = 1 * parseInt(dec.shift());
            for (i = 0; s || i < sum.length; i++) {
                s += (sum[i] || 0) * 10;
                sum[i] = s % 16;
                s = (s - sum[i]) / 16;
            }
        }
        while (sum.length) {
            hex.push(sum.pop().toString(16));
        }
        return '0x' + hex.join('');
    };
    ContractService.prototype.buyerDeposit = function (amount) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new (this._getWeb3().eth.Contract)(environment.buyerAbi, environment.buyerAddress);
                        res = buyer.methods.deposit(this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.buyerDepositTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.buyerWithdraw = function (amount) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new (this._getWeb3().eth.Contract)(environment.buyerAbi, environment.buyerAddress);
                        res = buyer.methods.withdraw(this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.buyerWithdrawTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.buyerSubscribe = function (assetIndex, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new (this._getWeb3().eth.Contract)(environment.buyerAbi, environment.buyerAddress);
                        res = buyer.methods.subscribe(assetIndex, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.buyerSubscribeTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.buyerUnsubscribe = function (assetIndex, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new (this._getWeb3().eth.Contract)(environment.buyerAbi, environment.buyerAddress);
                        res = buyer.methods.unsubscribe(assetIndex, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.buyerUnsubscribeTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.sellerDeposit = function (category, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new (this._getWeb3().eth.Contract)(environment.sellerAbi, environment.sellerAddress);
                        res = seller.methods.deposit(category, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.sellerDepositTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.sellerReduceDeposit = function (category, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new (this._getWeb3().eth.Contract)(environment.sellerAbi, environment.sellerAddress);
                        res = seller.methods.reduceDeposit(category, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.sellerReduceDepositTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.sellerWithdraw = function (category, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new (this._getWeb3().eth.Contract)(environment.sellerAbi, environment.sellerAddress);
                        res = seller.methods.withdraw(category, this._decToHex(amount, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.sellerWithdrawTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.sellerUserBasket = function (who, assetIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.userBasket(who, assetIndex).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, +value];
                }
            });
        });
    };
    ContractService.prototype.sellerChangeBasket = function (category, basketIndexes) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, res;
            return __generator(this, function (_a) {
                seller = new (this._getWeb3().eth.Contract)(environment.sellerAbi, environment.sellerAddress);
                res = seller.methods.changeBasket(category, basketIndexes).send({ from: this.address });
                return [2 /*return*/, this._send(res, transactionsDescriptions.sellerBasketUpdatingTransaction)];
            });
        });
    };
    ContractService.prototype.guarantorDeposit = function (assetIndex, amount, decimals) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new (this._getWeb3().eth.Contract)(environment.guarantorAbi, environment.guarantorAddress);
                        res = guarantor.methods.deposit(assetIndex, this._decToHex(amount, decimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.guarantorDepositTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.guarantorReduceDeposit = function (assetIndex, amount, decimals) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new (this._getWeb3().eth.Contract)(environment.guarantorAbi, environment.guarantorAddress);
                        res = guarantor.methods.reduceDeposit(assetIndex, this._decToHex(amount, decimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.guarantorReduceDepositTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.guarantorWithdraw = function (assetIndex, amount, decimals) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new (this._getWeb3().eth.Contract)(environment.guarantorAbi, environment.guarantorAddress);
                        res = guarantor.methods.withdraw(assetIndex, this._decToHex(amount, decimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.guarantorWithdrawTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getNextWeekBasket = function (who, category) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, week, request, indexes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.getCurrentWeek().call()];
                    case 1:
                        week = +(_a.sent());
                        return [4 /*yield*/, seller.methods.basketRequestMap(who, week + 1, category).call()];
                    case 2:
                        request = _a.sent();
                        if (!(request.time > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, seller.methods.getPendingBasket(who, category, week + 1).call()];
                    case 3:
                        indexes = _a.sent();
                        return [2 /*return*/, { has: true, indexes: indexes }];
                    case 4: return [2 /*return*/, { has: false, indexes: [] }];
                }
            });
        });
    };
    ContractService.prototype.getNextNextWeekBasket = function (who, category) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, week, request, indexes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.getCurrentWeek().call()];
                    case 1:
                        week = +(_a.sent());
                        return [4 /*yield*/, seller.methods.basketRequestMap(who, week + 2, category).call()];
                    case 2:
                        request = _a.sent();
                        if (!(request.time > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, seller.methods.getPendingBasket(who, category, week + 2).call()];
                    case 3:
                        indexes = _a.sent();
                        return [2 /*return*/, { has: true, indexes: indexes }];
                    case 4: return [2 /*return*/, { has: false, indexes: [] }];
                }
            });
        });
    };
    ContractService.prototype.getPendingBasket = function (who, category) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getNextNextWeekBasket(who, category)];
                    case 1:
                        data = _a.sent();
                        if (data.has)
                            return [2 /*return*/, data];
                        return [4 /*yield*/, this.getNextWeekBasket(who, category)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getSellerNextWeekWithdraw = function (who, category) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, week, request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.getCurrentWeek().call()];
                    case 1:
                        week = +(_a.sent());
                        return [4 /*yield*/, seller.methods.withdrawRequestMap(who, week + 1, category).call()];
                    case 2:
                        request = _a.sent();
                        return [2 /*return*/, +(this.getFixedTwoStr((+request.amount) / (Math.pow(10, environment.usdcDecimals))))];
                }
            });
        });
    };
    ContractService.prototype.getSellerNextNextWeekWithdraw = function (who, category) {
        return __awaiter(this, void 0, void 0, function () {
            var seller, week, request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.getCurrentWeek().call()];
                    case 1:
                        week = +(_a.sent());
                        return [4 /*yield*/, seller.methods.withdrawRequestMap(who, week + 2, category).call()];
                    case 2:
                        request = _a.sent();
                        return [2 /*return*/, +(this.getFixedTwoStr((+request.amount) / (Math.pow(10, environment.usdcDecimals))))];
                }
            });
        });
    };
    ContractService.prototype.getGuarantorNextWeekWithdraw = function (who, assetIndex, decimal) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, week, request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new this.web3.eth.Contract(environment.guarantorAbi, environment.guarantorAddress);
                        return [4 /*yield*/, guarantor.methods.getCurrentWeek().call()];
                    case 1:
                        week = +(_a.sent());
                        return [4 /*yield*/, guarantor.methods.withdrawRequestMap(who, week + 1, assetIndex).call()];
                    case 2:
                        request = _a.sent();
                        return [2 /*return*/, +(this.getFixedTwoStr((+request.amount) / (Math.pow(10, decimal))))];
                }
            });
        });
    };
    ContractService.prototype.getGuarantorNextNextWeekWithdraw = function (who, assetIndex, decimal) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, week, request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new this.web3.eth.Contract(environment.guarantorAbi, environment.guarantorAddress);
                        return [4 /*yield*/, guarantor.methods.getCurrentWeek().call()];
                    case 1:
                        week = +(_a.sent());
                        return [4 /*yield*/, guarantor.methods.withdrawRequestMap(who, week + 2, assetIndex).call()];
                    case 2:
                        request = _a.sent();
                        return [2 /*return*/, +(this.getFixedTwoStr((+request.amount) / (Math.pow(10, decimal))))];
                }
            });
        });
    };
    ContractService.prototype.getUserSellerInfo = function (who) {
        return __awaiter(this, void 0, void 0, function () {
            var seller;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new this.web3.eth.Contract(environment.sellerAbi, environment.sellerAddress);
                        return [4 /*yield*/, seller.methods.userInfo(who).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getUserGuarantorInfo = function (who) {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new this.web3.eth.Contract(environment.guarantorAbi, environment.guarantorAddress);
                        return [4 /*yield*/, guarantor.methods.userInfo(who).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getUserBuyerInfo = function (who) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new this.web3.eth.Contract(environment.buyerAbi, environment.buyerAddress);
                        return [4 /*yield*/, buyer.methods.userInfoMap(who).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getCurrentWeek = function () {
        return __awaiter(this, void 0, void 0, function () {
            var buyer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new this.web3.eth.Contract(environment.buyerAbi, environment.buyerAddress);
                        return [4 /*yield*/, buyer.methods.getCurrentWeek().call()];
                    case 1: return [2 /*return*/, +(_a.sent())];
                }
            });
        });
    };
    ContractService.prototype.getNetworkName = function () {
        return __awaiter(this, void 0, void 0, function () {
            var id;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._wait(1000)];
                    case 1:
                        _a.sent();
                        if (!window['ethereum'])
                            return [2 /*return*/, 'unknown'];
                        id = +window['ethereum'].chainId;
                        switch (id) {
                            case 1:
                                return [2 /*return*/, 'main'];
                            case 2:
                                return [2 /*return*/, 'morden'];
                            case 3:
                                return [2 /*return*/, 'ropsten'];
                            case 4:
                                return [2 /*return*/, 'rinkeby'];
                            case 5:
                                return [2 /*return*/, 'goerli'];
                            case 42:
                                return [2 /*return*/, 'kovan'];
                            case 56:
                                return [2 /*return*/, 'bsc'];
                            case 137:
                                return [2 /*return*/, 'matic'];
                            case 80001:
                                return [2 /*return*/, 'matic-mumbai'];
                            default:
                                return [2 /*return*/, 'unknown'];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.buyerClaimBonus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var buyer, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new (this._getWeb3().eth.Contract)(environment.buyerAbi, environment.buyerAddress);
                        res = buyer.methods.claimBonus().send({ from: this.address });
                        console.log('Buyer Claim Bonus');
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.buyerClaimBonusTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.sellerClaimBonus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var seller, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new (this._getWeb3().eth.Contract)(environment.sellerAbi, environment.sellerAddress);
                        res = seller.methods.claimBonus().send({ from: this.address });
                        console.log('Seller Claim Bonus');
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.sellerClaimBonusTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.sellerClaimMoreBonus = function (pid_) {
        return __awaiter(this, void 0, void 0, function () {
            var moreBonusHelper, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        moreBonusHelper = new (this._getWeb3().eth.Contract)(environment.moreBonusHelperAbi, environment.moreBonusHelperAddress);
                        res = moreBonusHelper.methods.claim(pid_).send({ from: this.address });
                        console.log('Seller Claim More Bonus');
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.sellerClaimMoreBonusTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.guarantorClaimBonus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new (this._getWeb3().eth.Contract)(environment.guarantorAbi, environment.guarantorAddress);
                        res = guarantor.methods.claimBonus().send({ from: this.address });
                        console.log('Guarantor Claim Bonus');
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.guarantorClaimBonusTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.sellerClaimPremium = function () {
        return __awaiter(this, void 0, void 0, function () {
            var seller, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seller = new (this._getWeb3().eth.Contract)(environment.sellerAbi, environment.sellerAddress);
                        res = seller.methods.claimPremium().send({ from: this.address });
                        console.log('Seller Claim Premium');
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.sellerClaimPremiumTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.guarantorClaimPremium = function () {
        return __awaiter(this, void 0, void 0, function () {
            var guarantor, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        guarantor = new (this._getWeb3().eth.Contract)(environment.guarantorAbi, environment.guarantorAddress);
                        res = guarantor.methods.claimPremium().send({ from: this.address });
                        console.log('Guarantor Claim Premium');
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.guarantorClaimPremiumTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getStakingPendingReward = function () {
        return __awaiter(this, void 0, void 0, function () {
            var staking, value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new this.web3.eth.Contract(environment.stakingAbi, environment.stakingAddress);
                        return [4 /*yield*/, staking.methods.pendingReward(this.address).call()];
                    case 1:
                        value = _a.sent();
                        return [2 /*return*/, +(this.getFixedTwoStr((+value) / (Math.pow(10, environment.tidalDecimals))))];
                }
            });
        });
    };
    ContractService.prototype.stakingClaim = function () {
        return __awaiter(this, void 0, void 0, function () {
            var staking, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new (this._getWeb3().eth.Contract)(environment.stakingAbi, environment.stakingAddress);
                        res = staking.methods.claim().send({ from: this.address });
                        console.log('Staking Claim');
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.stakingClaimTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.stakingDeposit = function (amount) {
        return __awaiter(this, void 0, void 0, function () {
            var staking, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new (this._getWeb3().eth.Contract)(environment.stakingAbi, environment.stakingAddress);
                        res = staking.methods.deposit(this._decToHex(amount, environment.tidalDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.stakingDepositTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.stakingWithdraw = function (amount) {
        return __awaiter(this, void 0, void 0, function () {
            var staking, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new (this._getWeb3().eth.Contract)(environment.stakingAbi, environment.stakingAddress);
                        res = staking.methods.withdraw(this._decToHex(amount, environment.tidalDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.stakingWithdrawTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getOneStakingWithdrawRequest = function (who, index) {
        return __awaiter(this, void 0, void 0, function () {
            var staking;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new this.web3.eth.Contract(environment.stakingAbi, environment.stakingAddress);
                        return [4 /*yield*/, staking.methods.withdrawRequestMap(who, index).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getMyStakingWithdrawRequests = function (from, to) {
        return __awaiter(this, void 0, void 0, function () {
            var res, all, i, index;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        res = [];
                        all = [];
                        i = 0;
                        index = from;
                        _a.label = 1;
                    case 1:
                        if (!(index < to)) return [3 /*break*/, 4];
                        all.push((function (index, i) { return __awaiter(_this, void 0, void 0, function () {
                            var entry;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.getOneStakingWithdrawRequest(this.address, index)];
                                    case 1:
                                        entry = _a.sent();
                                        res[i] = entry;
                                        return [2 /*return*/];
                                }
                            });
                        }); })(index, i));
                        ++i;
                        if (!(all.length > 5)) return [3 /*break*/, 3];
                        return [4 /*yield*/, Promise.all(all)];
                    case 2:
                        _a.sent();
                        all = [];
                        _a.label = 3;
                    case 3:
                        ++index;
                        return [3 /*break*/, 1];
                    case 4:
                        if (!(all.length > 0)) return [3 /*break*/, 6];
                        return [4 /*yield*/, Promise.all(all)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/, res];
                }
            });
        });
    };
    ContractService.prototype.getStakingApr = function () {
        return __awaiter(this, void 0, void 0, function () {
            var stakingHelper, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        stakingHelper = new this.web3.eth.Contract(environment.stakingHelperAbi, environment.stakingHelperAddress);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, stakingHelper.methods.getStakingAPR().call()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        e_3 = _a.sent();
                        return [2 /*return*/, 0];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.switchToMatic = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, window['ethereum'].request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x89',
                                    chainName: 'Matic Network',
                                    nativeCurrency: {
                                        name: 'Matic',
                                        symbol: 'Matic',
                                        decimals: 18,
                                    },
                                    rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
                                    blockExplorerUrls: ['https://polygonscan.com']
                                }
                            ]
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.switchToMaticMumbai = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, window['ethereum'].request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x13881',
                                    chainName: 'Matic Mumbai',
                                    nativeCurrency: {
                                        name: 'Matic',
                                        symbol: 'Matic',
                                        decimals: 18,
                                    },
                                    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
                                    blockExplorerUrls: ['https://mumbai.polygonscan.com']
                                }
                            ]
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.getStakingPoolInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var staking;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new this.web3.eth.Contract(environment.stakingAbi, environment.stakingAddress);
                        return [4 /*yield*/, staking.methods.poolInfo().call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getStakingUserInfo = function (who_) {
        return __awaiter(this, void 0, void 0, function () {
            var staking;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new this.web3.eth.Contract(environment.stakingAbi, environment.stakingAddress);
                        return [4 /*yield*/, staking.methods.userInfo(who_).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getWithdrawRequestBackwards = function (who, offset, limit) {
        return __awaiter(this, void 0, void 0, function () {
            var staking;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new this.web3.eth.Contract(environment.stakingAbi, environment.stakingAddress);
                        return [4 /*yield*/, staking.methods.getWithdrawRequestBackwards(who, offset, limit).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getNow = function () {
        return __awaiter(this, void 0, void 0, function () {
            var staking;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staking = new this.web3.eth.Contract(environment.stakingAbi, environment.stakingAddress);
                        return [4 /*yield*/, staking.methods.getNow().call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.requestPayoutStart = function (assetIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var committee, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        committee = new (this._getWeb3().eth.Contract)(environment.committeeAbi, environment.committeeAddress);
                        res = committee.methods.requestPayoutStart(assetIndex).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, {})];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.buyerAssetIndexPlusOne = function (who) {
        return __awaiter(this, void 0, void 0, function () {
            var buyer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buyer = new this.web3.eth.Contract(environment.buyerAbi, environment.buyerAddress);
                        return [4 /*yield*/, buyer.methods.buyerAssetIndexPlusOne(who).call()];
                    case 1: return [2 /*return*/, +(_a.sent())];
                }
            });
        });
    };
    ContractService.prototype.moreBonusUserInfo = function (pid_, who_) {
        return __awaiter(this, void 0, void 0, function () {
            var moreBonusHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        moreBonusHelper = new this.web3.eth.Contract(environment.moreBonusHelperAbi, environment.moreBonusHelperAddress);
                        return [4 /*yield*/, moreBonusHelper.methods.userInfo(pid_, who_).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.retailHelperAssetInfo = function (assetIndex_) {
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new this.web3.eth.Contract(environment.retailHelperAbi, environment.retailHelperAddress);
                        return [4 /*yield*/, retailHelper.methods.assetInfoMap(assetIndex_).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.retailHelperPremiumRate = function (assetIndex_) {
        return __awaiter(this, void 0, void 0, function () {
            var address, retailHelper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        address = assetIndex_ == 14 || assetIndex_ == 16 ? "0x1dbDCdC6732aE52D7EF51aB5647Bb510Bb28deb8" : environment.retailHelperAddress;
                        retailHelper = new this.web3.eth.Contract(environment.retailHelperAbi, address);
                        return [4 /*yield*/, retailHelper.methods.getPremiumRate(assetIndex_, environment.retailHelperAddress).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.retailHelperChangeCapacityOffset = function (assetIndex_, capacityOffset_) {
        return __awaiter(this, void 0, void 0, function () {
            var retailHelper, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retailHelper = new (this._getWeb3().eth.Contract)(environment.retailHelperAbi, environment.retailHelperAddress);
                        res = retailHelper.methods.changeCapacityOffset(assetIndex_, this._decToHex(capacityOffset_, environment.usdcDecimals)).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, {})];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.governanceGetCurrentVotes = function (who_) {
        return __awaiter(this, void 0, void 0, function () {
            var tidal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tidal = new this.web3.eth.Contract(environment.tidalAbi, environment.tidalAddress);
                        return [4 /*yield*/, tidal.methods.getCurrentVotes(who_).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.governanceDelegate = function (who_) {
        return __awaiter(this, void 0, void 0, function () {
            var tidal, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tidal = new (this._getWeb3().eth.Contract)(environment.tidalAbi, environment.tidalAddress);
                        res = tidal.methods.delegate(who_).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, {})];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.governanceVote = function (proposalId_, willSupport_) {
        return __awaiter(this, void 0, void 0, function () {
            var governance, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        governance = new (this._getWeb3().eth.Contract)(environment.governanceAbi, environment.governanceAddress);
                        res = governance.methods.castVote(proposalId_, willSupport_).send({ from: this.address });
                        return [4 /*yield*/, this._send(res, {})];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.governanceGetProposal = function (proposalId_) {
        return __awaiter(this, void 0, void 0, function () {
            var governance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        governance = new this.web3.eth.Contract(environment.governanceAbi, environment.governanceAddress);
                        return [4 /*yield*/, governance.methods.proposals(proposalId_).call()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.approve = function (tokenAddress, spender, accountAddress) {
        if (accountAddress === void 0) { accountAddress = this.address; }
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this._willUseBiconomy()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.approveTokenWithBiconomy(tokenAddress, spender, accountAddress)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.approveWithoutBiconomy(tokenAddress, spender, accountAddress)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/, _a];
                }
            });
        });
    };
    ContractService.prototype.approveWithoutBiconomy = function (tokenAddress, spender, accountAddress) {
        if (accountAddress === void 0) { accountAddress = this.address; }
        return __awaiter(this, void 0, void 0, function () {
            var token, amount, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = new (this.web3m.eth.Contract)(environment.erc20Abi, tokenAddress);
                        if (tokenAddress.toLowerCase() == '0x34c1b299a74588d6abdc1b85a53345a48428a521') {
                            // HACK: For EZ, which is 96 bits.
                            amount = '0xffffffffffffffffffffffff';
                        }
                        else {
                            amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
                        }
                        res = token.methods.approve(spender, amount).send({ from: accountAddress });
                        return [4 /*yield*/, this._send(res, transactionsDescriptions.approveTransaction)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.approveTokenWithBiconomy = function (tokenAddress, spenderAddress, accountAddress) {
        if (accountAddress === void 0) { accountAddress = this.address; }
        return __awaiter(this, void 0, void 0, function () {
            var sendTransaction, getSignatureParameters, domainType, metaTransactionType, tokenName, domainData, contract, nonce, _a, functionSignature, message, dataToSign, sendAndWait;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        sendTransaction = function (accountAddress, functionData, r, s, v, contract) {
                            return contract.methods
                                .executeMetaTransaction(accountAddress, functionData, r, s, v)
                                .send({ from: accountAddress, gasLimit: 1000000 }, function (error, tHash) {
                                if (error) {
                                    console.log("Error while sending executeMetaTransaction tx: " + error);
                                    return false;
                                }
                            });
                        };
                        getSignatureParameters = function (signature, web3b) {
                            var r = signature.slice(0, 66);
                            var s = "0x".concat(signature.slice(66, 130));
                            var v = "0x".concat(signature.slice(130, 132));
                            var v2 = web3b.utils.hexToNumber(v);
                            if (![27, 28].includes(v2))
                                v2 += 27;
                            return {
                                r: r,
                                s: s,
                                v: v2,
                            };
                        };
                        domainType = [
                            {
                                name: "name",
                                type: "string",
                            },
                            {
                                name: "version",
                                type: "string",
                            },
                            {
                                name: "verifyingContract",
                                type: "address",
                            },
                            {
                                name: "salt",
                                type: "bytes32",
                            },
                        ];
                        metaTransactionType = [
                            { name: "nonce", type: "uint256" },
                            { name: "from", type: "address" },
                            { name: "functionSignature", type: "bytes" },
                        ];
                        tokenName = (environment.networkName != "matic-mumbai") ?
                            (tokenAddress == environment.usdcAddress ? "USD Coin (PoS)" : "Tidal Token") :
                            (tokenAddress == environment.usdcAddress ? "MockUSDC" : "Tidal Token");
                        domainData = {
                            name: tokenName,
                            version: "1",
                            verifyingContract: tokenAddress,
                            salt: "0x" + (environment.chainId).toString(16).padStart(64, "0"),
                        };
                        contract = new (this.web3b.eth.Contract)(tokenAddress == environment.usdcAddress ? environment.usdcAbi : environment.tidalAbi, tokenAddress);
                        if (!(environment.networkName != "matic-mumbai" && tokenAddress == environment.usdcAddress)) return [3 /*break*/, 2];
                        return [4 /*yield*/, contract.methods.nonces(accountAddress).call()];
                    case 1:
                        _a = (_b.sent());
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, contract.methods.getNonce(accountAddress).call()];
                    case 3:
                        _a = (_b.sent());
                        _b.label = 4;
                    case 4:
                        nonce = _a;
                        return [4 /*yield*/, contract.methods
                                .approve(spenderAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
                                .encodeABI()];
                    case 5:
                        functionSignature = _b.sent();
                        message = {
                            nonce: parseInt(nonce),
                            from: accountAddress,
                            functionSignature: functionSignature
                        };
                        dataToSign = JSON.stringify({
                            types: {
                                EIP712Domain: domainType,
                                MetaTransaction: metaTransactionType,
                            },
                            domain: domainData,
                            primaryType: "MetaTransaction",
                            message: message,
                        });
                        sendAndWait = function () {
                            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.web3b.currentProvider.send({
                                                jsonrpc: "2.0",
                                                id: 999999999999,
                                                method: "eth_signTypedData_v3",
                                                params: [accountAddress, dataToSign],
                                            }, function (error, response) { return __awaiter(_this, void 0, void 0, function () {
                                                var _a, r, s, v, res, _b;
                                                return __generator(this, function (_c) {
                                                    switch (_c.label) {
                                                        case 0:
                                                            console.info("User signature is " + response.result);
                                                            if (!(error || (response && response.error))) return [3 /*break*/, 1];
                                                            reject("Error while signing the signature");
                                                            return [3 /*break*/, 3];
                                                        case 1:
                                                            if (!(response && response.result)) return [3 /*break*/, 3];
                                                            _a = getSignatureParameters(response.result, this.web3b), r = _a.r, s = _a.s, v = _a.v;
                                                            res = sendTransaction(accountAddress, functionSignature, r, s, v, contract);
                                                            _b = resolve;
                                                            return [4 /*yield*/, this._send(res, transactionsDescriptions.approveTransaction)];
                                                        case 2:
                                                            _b.apply(void 0, [_c.sent()]);
                                                            _c.label = 3;
                                                        case 3: return [2 /*return*/];
                                                    }
                                                });
                                            }); })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); });
                        };
                        return [4 /*yield*/, sendAndWait()];
                    case 6: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    ContractService.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isUnlocked;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isUnlocked()];
                    case 1:
                        isUnlocked = _a.sent();
                        if (!isUnlocked) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.enable()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.chainChanged();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContractService.prototype.isUnlocked = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = window['ethereum'];
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, window['ethereum']['_metamask'].isUnlocked()];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2: return [2 /*return*/, _a];
                }
            });
        });
    };
    ContractService.prototype.chainChanged = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                window['ethereum'].on('chainChanged', function () {
                    window.location.reload();
                });
                return [2 /*return*/];
            });
        });
    };
    ContractService.ngInjectableDef = i0.defineInjectable({ factory: function ContractService_Factory() { return new ContractService(i0.inject(i1.TransactionsService), i0.inject(i2.NotificationsService)); }, token: ContractService, providedIn: "root" });
    return ContractService;
}());
export { ContractService };
