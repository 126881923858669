var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
var GuarantorComponent = /** @class */ (function () {
    function GuarantorComponent(contractService, apiService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.onGoTo = new EventEmitter();
        this.category = 0;
        this.showAssets = [];
        this.myAssets = [];
        this.willShowDeposit = false;
        this.willShowWithdraw = false;
        this.assetToShow = null;
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
        this.bonusPerAssetOfG = 0;
        this.tidalPrice = 0;
        this.duration = 0;
    }
    GuarantorComponent.prototype.ngOnInit = function () {
        this.load();
    };
    GuarantorComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, showAssets;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.getAllAssets()];
                    case 1:
                        data = _a.sent();
                        showAssets = data.filter(function (asset) { return asset.category == _this.category &&
                            asset.token != "0x0000000000000000000000000000000000000000"; }).map(function (asset) {
                            return {
                                index: asset.index,
                                name: asset.name,
                                symbol: asset.symbol,
                                decimals: asset.decimals,
                                token: asset.token,
                                category: asset.category,
                                guarantorValueForProto: asset.guarantorValue,
                                guarantorApr: (asset.guarantorApr),
                                guarantorTidalApr: (asset.guarantorTidalApr),
                                guarantorBalance: _this.formatTokenBalance(asset.guarantorBalance),
                                guarantorValue: _this.formatTokenBalance(asset.guarantorValue),
                                guarantorValueNumber: asset.guarantorValue,
                                loading: true,
                                premiumRate: asset.premiumRate,
                                currentSubscription: asset.currentSubscription,
                                myBalance: 0,
                                myFutureBalance: 0,
                                myPendingDeposit: 0,
                                myPendingWithdraw: 0
                            };
                        });
                        this.showAssets = showAssets;
                        if (!this.contractService.address) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.loadAssets(showAssets)];
                    case 2:
                        _a.sent();
                        this.showAssets = this.showAssets.sort(function (a, b) {
                            return b.myBalance - a.myBalance || b.myPendingDeposit - a.myPendingDeposit;
                        });
                        Promise.all([
                            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.contractService.getNow()];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); })(),
                            (function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.contractService.getCurrentWeek()];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            }); }); })(),
                        ]).then(function (values) {
                            var secs = (values[1] + 1) * 3600 * 24 * 7 - values[0];
                            _this.duration = Math.floor(secs / (3600 * 24) - 4); // 4 is the offset.
                        });
                        this.myAssets = showAssets.filter(function (asset) { return asset.myBalance > 0; });
                        return [2 /*return*/];
                }
            });
        });
    };
    GuarantorComponent.prototype.loadAssets = function (showAssets) {
        return __awaiter(this, void 0, void 0, function () {
            var all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        all = showAssets.map(function (asset, index) { return __awaiter(_this, void 0, void 0, function () {
                            var obj, nextWeekWithdraw, nextNextWeekWithdraw;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.contractService.getGuarantorUserBalance(this.contractService.address, asset.index, asset.decimals)];
                                    case 1:
                                        obj = _a.sent();
                                        showAssets[index].myBalance = +(+obj.currentBalance).toFixed(2);
                                        showAssets[index].myFutureBalance = +(+obj.futureBalance).toFixed(2);
                                        showAssets[index].myPendingDeposit = +((+obj.futureBalance) - (+obj.currentBalance)).toFixed(2);
                                        return [4 /*yield*/, this.contractService.getGuarantorNextWeekWithdraw(this.contractService.address, asset.index, asset.decimals)];
                                    case 2:
                                        nextWeekWithdraw = _a.sent();
                                        return [4 /*yield*/, this.contractService.getGuarantorNextNextWeekWithdraw(this.contractService.address, asset.index, asset.decimals)];
                                    case 3:
                                        nextNextWeekWithdraw = _a.sent();
                                        showAssets[index].myPendingWithdraw = nextWeekWithdraw + nextNextWeekWithdraw;
                                        showAssets[index].loading = false;
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(all)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GuarantorComponent.prototype.refresh = function () {
        this.load();
    };
    GuarantorComponent.prototype.formatBalance = function (value) {
        var result = '$' + (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    GuarantorComponent.prototype.formatTokenBalance = function (value) {
        var result = (+value).toFixed(0);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    GuarantorComponent.prototype.formatRate = function (value) {
        if (isNaN(value)) {
            return 'N/A';
        }
        return (value / 10000).toFixed(2) + '%';
    };
    GuarantorComponent.prototype.goToCategory = function (category) {
        this.category = category;
        this.load();
    };
    GuarantorComponent.prototype.goToPortfolio = function () {
        this.onGoTo.emit({ key: 'portfolio' });
    };
    GuarantorComponent.prototype.showDeposit = function (asset) {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.assetToShow = asset;
        this.willShowDeposit = true;
    };
    GuarantorComponent.prototype.closeDeposit = function () {
        this.willShowDeposit = false;
    };
    GuarantorComponent.prototype.closeProtocolPopup = function () {
        this.willShowProtocolPopup = false;
    };
    GuarantorComponent.prototype.isDetailCanClick = function (asset) {
        var protoAsset = environment.protocolList.find(function (proto) { return proto.key == asset.name; });
        return protoAsset;
    };
    GuarantorComponent.prototype.showProtocolsInfoPopup = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            var tidalUSDprice;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isDetailCanClick(asset)) {
                            return [2 /*return*/];
                        }
                        this.willShowProtocolPopup = true;
                        this.protocolInfoObj = asset;
                        this.protocolInfoObj = Object.assign(asset, {
                            avatar: '/assets/images/a' + (asset.index + 1) + '.png',
                            title: asset.name
                        }, this.isDetailCanClick(asset));
                        return [4 /*yield*/, this.getTokenPrice('tidal-finance')];
                    case 1:
                        tidalUSDprice = _a.sent();
                        // tslint:disable-next-line:radix
                        console.log('proto', asset.guarantorValueForProto);
                        this.protocolInfoObj.inceptionDate = parseInt(String(+(new Date().getTime() - new Date(this.protocolInfoObj.dateofMainnet).getTime()) / 1000 / 3600 / 24));
                        this.protocolInfoObj.chains = this.protocolInfoObj.chains.join(',');
                        this.protocolInfoObj.guarantorCoverTvl = "" + this.formatRate(+asset.guarantorValueForProto / asset.currentSubscription);
                        this.protocolInfoObj.guarantorCollateralExpression = +asset.guarantorValueForProto.toFixed(2) + "  /  " + asset.guarantorBalance + " (" + asset.symbol + ")";
                        this.protocolInfoObj.tidalCollateralExpression = this.getTidalCollateral(tidalUSDprice);
                        this.protocolInfoObj.premiumPricing = this.formatRate(asset.premiumRate) + ' Weekly';
                        return [2 /*return*/];
                }
            });
        });
    };
    GuarantorComponent.prototype.toggleShowFloatPopup = function ($event, status) {
        var floatItem = $event.target.querySelector('.float-item');
        this.adjustFloatItemPos(floatItem, status);
    };
    GuarantorComponent.prototype.showPendingActions = function (asset) {
        this.showAlert('Pending Status', "Pending Deposit:\n      " + this.formatTokenBalance(asset.myPendingDeposit) + " " + asset.symbol.toUpperCase() + "\n      \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\n      Pending Withdraw:\n      " + this.formatTokenBalance(asset.myPendingWithdraw) + " " + asset.symbol.toUpperCase());
    };
    GuarantorComponent.prototype.adjustFloatItemPos = function (elem, status) {
        elem.style.visibility = status;
    };
    GuarantorComponent.prototype.getTokenPrice = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            var url, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "https://api.coingecko.com/api/v3/simple/price?ids=" + token + "&vs_currencies=usd";
                        return [4 /*yield*/, this.apiService.get(url, 'token')];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data && data[token] && data[token]['usd'] ? data[token]['usd'] : 0];
                }
            });
        });
    };
    GuarantorComponent.prototype.getTidalCollateral = function (tidalUSDprice) {
        return this.poolBalance < 1000000 ? this.formatBalance((tidalUSDprice * this.poolBalance)) + " USD  " + this.poolBalance + " TIDAL" : "100,000 USD in TIDAL";
    };
    GuarantorComponent.prototype.showWithdraw = function (asset) {
        if (!this.contractService.address) {
            this.showAlert("Please connect to MetaMask", "");
            return;
        }
        this.assetToShow = asset;
        this.willShowWithdraw = true;
    };
    GuarantorComponent.prototype.closeWithdraw = function () {
        this.willShowWithdraw = false;
    };
    GuarantorComponent.prototype.showAlert = function (title, body) {
        this.alertTitle = title;
        this.alertBody = body;
        this.willShowAlertMessage = true;
    };
    GuarantorComponent.prototype.closeAlert = function () {
        this.willShowAlertMessage = false;
    };
    return GuarantorComponent;
}());
export { GuarantorComponent };
