<div class="content row top-info top-title">
  <div class="left column-6">
    <h2 class="title font-48-blue margin-top--20">Guarantor</h2>
    <p class="des font-16-blue">Back the asset that you want to be guarantor</p>
    <!-- <div class="box-wrap mobile-nav-risk">
      <a href="" class="button actived">Low Risk <i class="icon-down"><img src="/assets/images/icon-down-arrow.png"></i></a>
    </div> -->
  </div>
</div>
<!--<div class="row button-row risk-menu">
  <div class="box-wrap">
    <a href="javascript:void(0)" class="button" [ngClass]="{'actived': category==0, 'un-actived': category!=0}" (click)="goToCategory(0)">Low Risk</a>
    <a href="javascript:void(0)" class="button" [ngClass]="{'actived': category==1, 'un-actived': category!=1}" (click)="goToCategory(1)">Medium Risk</a>
    <a href="javascript:void(0)" class="button" [ngClass]="{'actived': category==2, 'un-actived': category!=2}" (click)="goToCategory(2)">High Risk</a>
  </div>
</div>-->

<div class="row liquid padding-left-32 padding-right-32">
  <div class="" style="padding: 20px;">
    <div class="column-12">
      <p class="title" style="
      font-size: 14px;line-height: 19px;color: #666666;">Assets Backed by Me</p>
      <div id="back-box">
        <div class="icon" *ngFor="let asset of myAssets"><img src="/assets/images/a{{ asset.index + 1 }}.png">
        <span style="font-weight: 500;font-size: 14px;color: #000000;">{{asset.name}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="crypto-list row padding-left-32 padding-right-32">
  <div class="crypto-list-box mobile-maxwidth-scroll" id="guarantorBox">
    <div class="crypto-list-item mobile-gap-20" *ngFor="let asset of showAssets">
      <div class="guarantor-icon">
        <span class="icon"><img [src]="'/assets/images/a' + (asset.index + 1) + '.png'"></span>
        <strong>{{ asset.name }}</strong>
      </div>
      <div class="guarantor-number guarantor-number-1" style="cursor: pointer;font-size: 24px;position: relative;"
      (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')">
        <span>APR</span>
        <span class="number" style="font-size:14px">{{ formatRate(asset.guarantorApr + asset.guarantorTidalApr) }}
        </span>
        <div class="float-item">
          <p>
            <span>Premium Earning (APR)</span>
            <span class="text-green text-right">{{ formatRate(asset.guarantorApr) }}</span>
          </p>
          <p>
            <span>Tidal Earning (APR)</span>
            <span class="text-green text-right">{{ formatRate(asset.guarantorTidalApr) }}</span>
          </p>
        </div>
      </div>
      <div class="guarantor-number guarantor-number-2">
        <span>Guarantor Reserve</span>
        <span class="number">${{ asset.guarantorValue }} / {{ asset.guarantorBalance }} ({{ asset.symbol }})</span></div>
      <div class="guarantor-number guarantor-number-3">
        <span>My Balance</span>
        <span class="number">      <span class="icon-spinner" *ngIf="asset.loading"></span>
        <ng-container *ngIf="!asset.loading">
          <div class="number">{{ asset.myBalance }} {{ asset.symbol }}
          </div>
        </ng-container></span>

      </div>
      <div class="guarantor-operation">
        <button class="proto-status" (click)="showPendingActions(asset)">
          <img src="/assets/images/guarantor-status.svg"
          style="cursor: pointer;">
          Status
      </button>
      <span (click)="showDeposit(asset)">
        <img src="/assets/images/deposit-guarantor.svg">
      </span>
        <span (click)="showWithdraw(asset)">
          <img src="/assets/images/withdraw-guarantor.svg">
        </span>
      </div>
    </div>
  </div>
</div>

<app-guarantor-deposit
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowDeposit"
  [asset]=assetToShow
  (onClose)="closeDeposit()"
  (onRefresh)="refresh()">
</app-guarantor-deposit>

<app-guarantor-withdraw
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowWithdraw"
  [asset]=assetToShow
  (onClose)="closeWithdraw()"
  (onRefresh)="refresh()">
</app-guarantor-withdraw>

<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>

<app-protocal-info-popup
style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
*ngIf="willShowProtocolPopup"
[protocolInfoObj]='protocolInfoObj'
(onClose)="closeProtocolPopup()"
>
</app-protocal-info-popup>
