import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractService } from '../contract.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  @Input() key: string;
  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  hasAsset = false;
  isProduction = false;

  constructor(private contractService: ContractService) { }

  ngOnInit() {
    this.isProduction = environment.networkName != 'matic-mumbai';
    this.load();
    this.setFixedSideMenu();
  }

  async load () {
    await this.contractService.init();
    let assetIndexPlusOne = 0;
    if (this.contractService.address) {
      assetIndexPlusOne = await this.contractService.buyerAssetIndexPlusOne(this.contractService.address);
    }

    this.hasAsset = assetIndexPlusOne > 0;
  }

  goTo(key) {
    this.key = key;
    this.onGoTo.emit({
      key: key
    });
  }

  setFixedSideMenu () {
    const oldHtmlWidth = document.documentElement.offsetWidth;
    const elFix = document.getElementById('fixed-sidebar');

    function htmlScroll() {
      const top = document.body.scrollTop || document.documentElement.scrollTop;
      if (elFix['data_top'] < top) {
          elFix.style.position = 'fixed';
          elFix.style.top = '0';
          elFix.style.left = elFix['data_left'];
      } else {
          elFix.style.position = 'static';
      }
    }

    function htmlPosition(obj) {
        let o = obj;
        let t = o.offsetTop;
        let l = o.offsetLeft;
        while (o = o.offsetParent) {
            t += o.offsetTop;
            l += o.offsetLeft;
        }
        obj.data_top = t;
        obj.data_left = l;
    }
    window.onresize = function () {
        const newHtmlWidth = document.documentElement.offsetWidth;
        if (oldHtmlWidth == newHtmlWidth) {
            return;
        }
        // @ts-ignore
        oldHtmlWidth = newHtmlWidth;
        elFix.style.position = 'static';
        htmlPosition(elFix);
        htmlScroll();
    };
    window.onscroll = htmlScroll;

    htmlPosition(elFix);
  }
}
