import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sellerDepositTransaction, sellerReduceDepositTransaction, approveTransaction } from '../transactions.descriptions';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-sell-deposit',
  templateUrl: './sell-deposit.component.html',
  styleUrls: ['./sell-deposit.component.css']
})
export class SellDepositComponent implements OnInit {

  @Input() category: number;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  amount: string = "";
  usdcBalance: string = "";
  reserveBalance: string = "";
  myCurrentBalance: string = "";
  myFutureBalance: string = "";
  deltaBalance: string = "";

  needApproval: boolean = true;
  loading: boolean = false;

  constructor(private contractService: ContractService,
              private apiService: ApiService,
              private transactionsService: TransactionsService
  ) { }

  ngOnInit() {
    this.loadCategory(this.category);
  }

  async loadCategory(category) {
    this.loading = true;
    if (this.contractService.address && this.contractService.usdcBalance) {
      this.usdcBalance = this.contractService.usdcBalance;

      const all = [(async () => {
        this.reserveBalance = await this.contractService.getAllSellerBalance(category);
      }) (), (async () => {
        this.myCurrentBalance = await this.contractService.getSellerCurrentBalance(
            this.contractService.address, category);
      }) (), (async () => {
        this.myFutureBalance = await this.contractService.getSellerFutureBalance(
            this.contractService.address, category);
      }) (), (async () => {
        const allowance = await this.contractService.getAllowance(environment.usdcAddress, this.contractService.address, environment.sellerAddress, environment.usdcDecimals);
        this.needApproval = parseFloat(allowance) < parseFloat(this.usdcBalance);
      }) ()];

      await Promise.all(all);
      this.deltaBalance = (+Math.max(0, parseFloat(this.myFutureBalance) - parseFloat(this.myCurrentBalance)).toFixed(2)).toString();
      this.amount = this.deltaBalance;
    }
    this.loading = false;
  }

  max() {
    this.amount = (parseFloat(this.usdcBalance) + parseFloat(this.deltaBalance)).toFixed(2).toString();
  }

  getNumber(x) {
    return this.formatTokenBalance(parseFloat(x));
  }

  getFloatNumber(x) {
    return parseFloat(x)
  }

  async approve() {
    this.transactionsService.updateProcessingTransaction(approveTransaction.type, true);
    try {
      await this.contractService.approve(environment.usdcAddress, environment.sellerAddress);
      this.needApproval = false;
    } catch(e) {
    }

    this.transactionsService.updateProcessingTransaction(approveTransaction.type, false);
  }

  async deposit() {
    if (!parseFloat(this.amount) && parseFloat(this.amount) !== 0) {
      return;
    }

    try {
      if (+this.amount > +this.deltaBalance) {
        this.transactionsService.updateProcessingTransaction(sellerDepositTransaction.type, true);
        await this.contractService.sellerDeposit(this.category, +(parseFloat(this.amount) - parseFloat(this.deltaBalance)).toFixed(2));
      } else {
        this.transactionsService.updateProcessingTransaction(sellerReduceDepositTransaction.type, true);
        await this.contractService.sellerReduceDeposit(this.category, +(parseFloat(this.deltaBalance) - parseFloat(this.amount)).toFixed(2));
      }

      await this.loadCategory(this.category);
      this.amount = "";
    } catch(e) {
    }

    this.transactionsService.updateProcessingTransaction(sellerDepositTransaction.type, false);
    this.transactionsService.updateProcessingTransaction(sellerReduceDepositTransaction.type, false);
    this.onRefresh.emit();
  }

  isTransactionsProcessing () {
    return this.transactionsService.processingTransactions[sellerDepositTransaction.type] ||
      this.transactionsService.processingTransactions[sellerReduceDepositTransaction.type] ||
      this.transactionsService.processingTransactions[approveTransaction.type];
  }

  willDisableButton() {
    const buttonDisabled = this.loading || (this.getFloatNumber(this.amount) !== 0 && !this.amount) ||
        (this.getFloatNumber(this.amount) !== 0 && !this.getFloatNumber(this.amount)) ||
        this.getFloatNumber(this.amount) > this.getFloatNumber(this.deltaBalance) + this.getFloatNumber(this.usdcBalance) || this.isTransactionsProcessing();

    return buttonDisabled;
  }

  close() {
    this.onClose.emit();
  }

  formatTokenBalance(value) {
    const result = (+value).toFixed(0);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
