var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
var BuyCoverComponent = /** @class */ (function () {
    function BuyCoverComponent(contractService, apiService) {
        this.contractService = contractService;
        this.apiService = apiService;
        this.onGoTo = new EventEmitter();
        this.category = 0;
        this.allAssets = [];
        this.showAssets = [];
        this.retailArray = environment.retailArray;
        this.isChangingBasket = false;
        this.isShowProtocolQueue = false;
        this.alertTitle = "";
        this.alertBody = "";
        this.willShowAlertMessage = false;
        this.loading = false;
    }
    BuyCoverComponent.prototype.ngOnInit = function () {
        this.load();
    };
    BuyCoverComponent.prototype.formatBalance = function (value, precision) {
        if (precision === void 0) { precision = 2; }
        var result = '$' + (+value).toFixed(precision);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    BuyCoverComponent.prototype.formatTableBalance = function (value, precision) {
        if (precision === void 0) { precision = 2; }
        return (+value).toFixed(precision);
    };
    BuyCoverComponent.prototype.formatTokenBalance = function (value, precision) {
        if (precision === void 0) { precision = 2; }
        var result = (+value).toFixed(precision);
        return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    BuyCoverComponent.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, all;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.contractService.init()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.apiService.getAllAssets()];
                    case 2:
                        data = _a.sent();
                        this.allAssets = [];
                        all = data.map(function (asset, index) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        _a = this.allAssets;
                                        _b = index;
                                        _c = {
                                            index: asset.index,
                                            name: asset.name,
                                            symbol: asset.symbol,
                                            token: asset.token,
                                            category: asset.category,
                                            apr: asset.apr
                                        };
                                        return [4 /*yield*/, this.contractService.retailHelperPremiumRate(asset.index)];
                                    case 1:
                                        _a[_b] = (_c.premiumRate = _d.sent(),
                                            _c.sellerBalance = this.formatBalance(asset.sellerBalance),
                                            _c.sellerBalanceNumber = asset.sellerBalance,
                                            _c.currentSubscription = asset.currentSubscription,
                                            _c.assetUtilization = this.formatRate(asset.assetUtilization),
                                            _c);
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(all)];
                    case 3:
                        _a.sent();
                        this.showAssets = this.allAssets.filter(function (asset) { return asset.category * 1 == _this.category; });
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    BuyCoverComponent.prototype.formatRate = function (value) {
        if (isNaN(value)) {
            return 'N/A';
        }
        return (value / 10000).toFixed(2) + '%';
    };
    BuyCoverComponent.prototype.formatSymbol = function (value) {
        if (!value)
            return '';
        return value.toUpperCase();
    };
    BuyCoverComponent.prototype.getProtocolLink = function (symbol) {
        var linkMap = {
            'FIS': 'https://stafi.tidal.finance',
            'YFI': 'https://tidal-retail-git-yfi-shipunyc.vercel.app',
            'QI': 'https://qi.tidal.finance',
            'RDL': 'https://tidal-retail-git-rdl-shipunyc.vercel.app',
            'ANC': 'https://tidal-retail-git-anc1-shipunyc.vercel.app',
            'UST': 'https://tidal-retail-git-ust-shipunyc.vercel.app',
            'LUSD': 'https://tidal-retail-git-lusd-shipunyc.vercel.app',
            'PERP': 'https://tidal-retail-git-perp-shipunyc.vercel.app',
            'SDL': 'https://tidal-retail-git-sdl-shipunyc.vercel.app',
            'FRAX': 'https://tidal-retail-git-frax-shipunyc.vercel.app',
            'GFI': 'https://tidal-retail-git-gfi-shipunyc.vercel.app',
            'MKR': 'https://tidal-retail-git-mkr-shipunyc.vercel.app',
            'COMP': 'https://tidal-retail-git-comp-shipunyc.vercel.app',
            'SNX': 'https://tidal-retail-git-snx-shipunyc.vercel.app',
            'GMX': 'https://tidal-retail-git-gmx-shipunyc.vercel.app',
            'LDO': 'https://tidal-retail-git-ldo-shipunyc.vercel.app'
        };
        return linkMap[symbol];
    };
    return BuyCoverComponent;
}());
export { BuyCoverComponent };
