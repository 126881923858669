import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sellerWithdrawTransaction } from '../transactions.descriptions';

import { ContractService } from '../contract.service';
import { ApiService } from '../api.service';
import { TransactionsService } from '../transactions.service';

@Component({
  selector: 'app-sell-withdraw',
  templateUrl: './sell-withdraw.component.html',
  styleUrls: ['./sell-withdraw.component.css']
})
export class SellWithdrawComponent implements OnInit {

  @Input() category: number;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  
  amount: string = "";
  usdcBalance: string = "";
  myCurrentBalance: string = "";

  nextWeekWithdrawAmount: number = 0;
  nextNextWeekWithdrawAmount: number = 0;

  loading: boolean = false;
  
  constructor(private contractService: ContractService,
              private apiService: ApiService,
              private transactionsService: TransactionsService,
              ) { }
  
  ngOnInit() {
    this.loading = true;
    this.loadCategory(this.category);
    this.loading = false;
  }
  
  async loadCategory(category) {
    if (this.contractService.address && this.contractService.usdcBalance) {
      this.usdcBalance = this.contractService.usdcBalance;

      const all = [(async () => {
        this.myCurrentBalance = await this.contractService.getSellerCurrentBalance(
            this.contractService.address, category);
      }) (), (async () => {
        this.nextWeekWithdrawAmount = await this.contractService.getSellerNextWeekWithdraw(
            this.contractService.address, category);
      }) (), (async () => {
        this.nextNextWeekWithdrawAmount = await this.contractService.getSellerNextNextWeekWithdraw(
            this.contractService.address, category);
      }) ()];

      await Promise.all(all);
      this.amount = (this.nextNextWeekWithdrawAmount).toString();
    }
  }
  
  max() {
    this.amount = (+(this.myCurrentBalance) - this.nextWeekWithdrawAmount).toFixed(2).toString();
  }

  getNumber(x) {
    return parseFloat(x);
  }
  
  async withdraw() {
    this.transactionsService.updateProcessingTransaction(sellerWithdrawTransaction.type, true);
    try {
      await this.contractService.sellerWithdraw(this.category, +this.amount);
      await this.loadCategory(this.category);
    } catch(e) {
    }

    this.transactionsService.updateProcessingTransaction(sellerWithdrawTransaction.type, false);
    this.onRefresh.emit();
  }

  isSpinnerShown() {
    return this.transactionsService.processingTransactions[sellerWithdrawTransaction.type];
  }

  willDisableButton() {
    const buttonDisabled = this.loading || (this.getNumber(this.amount) !== 0 && !this.amount) ||
      (this.getNumber(this.amount) !== 0 && !this.getNumber(this.amount)) ||
      this.getNumber(this.amount) > this.getNumber(this.myCurrentBalance) ||
      this.getNumber(this.amount) === this.getNumber(this.nextNextWeekWithdrawAmount) ||
      this.transactionsService.processingTransactions[sellerWithdrawTransaction.type];
    return buttonDisabled;
  }
  
  close() {
    this.onClose.emit();
  }
}
