import { Component, OnInit } from '@angular/core';

import { ContractService } from '../contract.service';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {

  delegating = false;
  casting = false;
  loading = false;

  proposalId = 3;

  myVotes = 0;
  proposal: any = {};

  constructor(private contractService: ContractService) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    if (!this.contractService.address) {
      return;
    }

    const all = [(async () => {
      this.myVotes = +((+(await this.contractService.governanceGetCurrentVotes(this.contractService.address))) / 1e18).toFixed(12);
    })(), (async () => {
      this.proposal = await this.contractService.governanceGetProposal(this.proposalId);
    })()];

    this.loading = true;
    await Promise.all(all);
    this.loading = false;
  }

  formatVotes(amount) {
    return +(amount / 1e18).toFixed(12);
  }

  async delegateToMe() {
    if (!this.contractService.address) {
      return;
    }

    this.delegating = true;
    try {
      await this.contractService.governanceDelegate(this.contractService.address);
      await this.load();
    } catch(e) {
    }

    this.delegating = false;
  }

  async delegateToDev() {
    if (!this.contractService.address) {
      return;
    } 
    
    this.delegating = true;
    try {
      await this.contractService.governanceDelegate("0x5c99f63937FA74205dea9b308e9A8e16F66d26CC");
      await this.load();
    } catch(e) {
    }

    this.delegating = false;
  }

  async castVote(willSupport) {
    if (!this.contractService.address) {
      return;
    }
    
    this.casting = true;
    try {
      await this.contractService.governanceVote(this.proposalId, willSupport ? 1 : 0);
      await this.load();
    } catch(e) {
    }

    this.casting = false;
  }
}
